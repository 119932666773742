import React, { Component } from "react";
import UserForm from "./UserForm";
import axios from "axios";
import { withRouter } from "react-router-dom";
import CustomizedSnackbars from "../snackbars/CustomizedSnackbars";
import firebase from 'firebase';


var storage = firebase.storage();
var storageRef = storage.ref();
export class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageofuser: "",
      username: "",
      fullname: "",
      designation: "",
      email: "",
      password: "",
      permission: "Marketing Team",
      submitted: false,
      btnDisabled: false,
      // userSubmit: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    this.setState({ submitted: false });
    const { name, value } = e.target;
    this.setState({ [name]: value });
    console.log(this.state);
  };
  handleSubmit(e) {
    e.preventDefault();
    if (this.state.password.length < 6) {
      this.setState({ submitted: true });
      // alert('Error Password Must be Greater then 6 words');
    } else {
      this.setState({
        btnDisabled: true
      });
      if (this.state.imageofuser !== '') {

        var uploadTask = storageRef.child(`userPhotos/${new Date().toJSON()}_${this.state.imageofuser.name}`).put(this.state.imageofuser);
        uploadTask.then((dataReturned) => {
          dataReturned.ref.getDownloadURL().then((url) => {
            const {
              username,
              fullname,
              designation,
              email,
              password,
              permission,
              btnDisabled
            } = this.state;
            const values = {
              username,
              fullname,
              designation,
              email,
              password,
              permission,
              btnDisabled,
              imgUrl: url
            };

            axios
              .post(
                "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/user",
                values
              )
              .then(results => {
                console.log(results);
                const emptyState = {
                  imageofuser: "",
                  username: "",
                  fullname: "",
                  designation: "",
                  email: "",
                  password: "",
                  permission: "Marketing Team",
                  btnDisabled: false
                };
                this.setState(emptyState);
                this.props.userAdded();
              })
              .catch(err => {
                console.log(err);
                this.setState({
                  btnDisabled: false
                });
              });
          });

        })
          .catch(err => console.log(err));
      }
      else {

        const {
          username,
          fullname,
          designation,
          email,
          password,
          permission,

        } = this.state;
        const values = {
          username,
          fullname,
          designation,
          email,
          password,
          permission,
        };

        axios
          .post(
            "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/user",
            values
          )
          .then(results => {
            console.log(results);
            const emptyState = {
              imageofuser: "",
              username: "",
              fullname: "",
              designation: "",
              email: "",
              password: "",
              permission: "Marketing Team"
            };
            this.setState(emptyState);
            this.props.userAdded();
          })
          .catch(err => {
            console.log(err);
            this.setState({
              btnDisabled: false
            });
          });

      }
    }
  }

  handleFile = (e) => {
    this.setState({
      imageofuser: e.target.files[0]
    })

    console.log(e.target.files[0]);
  }

  render() {
    const {
      imageofuser,
      username,
      fullname,
      designation,
      email,
      password,
      permission,
      btnDisabled
    } = this.state;
    const values = {
      imageofuser,
      username,
      fullname,
      designation,
      email,
      password,
      permission,
      btnDisabled
    };
    return (
      <div style={styles.fullwidth}>
        <div style={{ width: "100%" }}>
          <CustomizedSnackbars
            type="error"
            message="Password must be atleast six digit"
            show={this.state.submitted}
          />
          {/* <CustomizedSnackbars
            type="success"
            message="New User has been created"
            show={this.state.userSubmit}
          /> */}
          <React.Fragment>
            <UserForm
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              handleFile={this.handleFile}
              values={values}
              submit="Add User"
              elevate={1}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}
const styles = {
  fullwidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around"
  }
};

export default withRouter(CreateUser);
