import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
// import Grid from "@material-ui/core/Grid";
import { grey } from "@material-ui/core/colors";
// import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
// For Buttons
import Button from "@material-ui/core/Button";

// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";

const styles = {
  root: {
    margin: 0,
    padding: "16px"
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: grey
  },
  bigAvatar: {
    margin: 5,
    width: 70,
    height: 70
  },
  box: {
    display: "flex",
    alignItems: "center"
  },
  textFieldSelect: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 16,
    marginBottom: 8,
    width: 250
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8,
    width: 550
  },
  textField2: {
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8,
    width: 260
  },
  formControl: {
    display: "flex",
    margin: "8px"
  }
};

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default class CustomizedDialogs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // currencies = ["$ USD", "€ EUR", "฿ BTC", "¥ JPY", "৳ BDT"];
  render() {
    console.log(this.props);
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">ADD PAYMENT</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please provide the payment informations:
            </DialogContentText>
            <FormControl fullWidth>
              <TextField
                fullWidth
                required
                label="Payment Type"
                value={this.props.values.paymentType}
                type="text"
                onChange={this.props.handleChange}
                name="paymentType"
                style={styles.textField}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Payment Details"
                value={this.props.values.paymentDetails}
                type="text"
                onChange={this.props.handleChange}
                name="paymentDetails"
                style={styles.textField}
              />
            </FormControl>

            <FormControl>
              <TextField
                label="Currency"
                value={this.props.values.currency}
                type="text"
                name="currency"
                style={styles.textField2}
                InputProps={{
                  readOnly: true
                }}
              />
            </FormControl>

            <FormControl>
              <TextField
                fullWidth
                required
                label="Payment Amount"
                value={this.props.values.amount}
                type="number"
                onChange={this.props.handleChange}
                name="amount"
                style={styles.textField2}
              />
            </FormControl>
            <input type="file" name="upload" onChange={this.props.handleFile} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.props.handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
