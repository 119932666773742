import React, { Component } from "react";
import ForgotPassword from "./ForgotPassowrd";
import { resetEmail } from "../../controllers/login";

export class ForgotPassowrdComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  }
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmit = e => {
    e.preventDefault();
    const emailAddress = this.state.email;
    resetEmail(emailAddress)
      .then(() => {
        this.setState(() => {
          return {
            emailReset: true,
            errorCode: null,
            errorMessage: null,
            lastAction: "passwordReset"
          };
        });
      })
      .catch(error => {
        let errorCode = error.code;
        let errorMessage = error.message;
        this.setState(() => {
          return {
            errorCode: errorCode,
            errorMessage: errorMessage,
            lastAction: "passwordReset"
          };
        });
      })
      .then(() => console.log(this.state));
  };

  render() {
    const { email } = this.state;
    const values = { email };

    return (
      <>
        <div style={styles.fullwidth}>
          <ForgotPassword
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            forgotPassword={this.passwordReset}
            values={values}
          />
        </div>
      </>
    );
  }
}
const styles = {
  fullwidth: {
    width: "100%"
  }
};

export default ForgotPassowrdComponent;
