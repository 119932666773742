import React, { Component } from "react";
import UserTable from "../users/UserTable";
import CreateUser from "../users/CreateUser";
import "./user.css";

export class UserView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addUser: false
    };
  }

  userAdded = () => {
    this.setState({ addUser: true });
    console.log("Added");
  };

  userAddDone = () => {
    this.setState({ addUser: false });
    console.log("Done");
  };
  render() {
    return (
      <div style={styles.root}>
        <div style={styles.tableContainer}>
          <UserTable
            url="/#"
            addedData={this.state.addUser}
            done={this.userAddDone}
            getData={
              "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/user"
            }
          />
        </div>
        <div className="CreateContainer">
          <CreateUser userAdded={this.userAdded} />
        </div>
      </div>
    );
  }
}

const styles = {
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap"
  },
  tableContainer: {
    minWidth: "70%"
  }
};

export default UserView;
