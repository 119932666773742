import React, { Component } from "react";
import ProductForm from "./ProductForm";
import axios from "axios";
import { withRouter } from "react-router-dom";

export class CreateProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      principal: {},
      btnDisabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    console.log(`Name is ${name} 
    & value ${value.principalName}`);
  };
  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      btnDisabled: true
    });
    axios
      .post(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/product`,
        this.state
      )
      // axios
      //   .post(
      //     `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/product`,
      //     this.state
      //   )

      .then(results => {
        console.log(results);
        const emptyState = {
          productName: "",
          btnDisabled: false
        };
        this.setState(emptyState);
        this.props.result();
      })
      .catch(err => console.log(err));
  }

  render() {
    const { productName, principal } = this.state;
    const values = {
      productName,
      principal
    };
    return (
      <div style={styles.fullwidth}>
        <div style={{ width: "100%" }}>
          <React.Fragment>
            <ProductForm
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              values={values}
              submit="Add Product"
              elevate={1}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}
const styles = {
  fullwidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around"
  }
};

export default withRouter(CreateProduct);
