import React, { Component } from "react";
//paper
import Paper from "@material-ui/core/Paper";
//For Radio Buttons
import FormControl from "@material-ui/core/FormControl";
//input label
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
// selects
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// textfields
import TextField from "@material-ui/core/TextField";
//typography
import Typography from "@material-ui/core/Typography";
//box
import Box from "@material-ui/core/Box";
//Modal
// import Modals from "./ECRModal";
//table
import Itemtable from "./ViewServiceFormItemtable";
//axios import
import axios from "axios";
//css
import "./divStyle.css";
// For Buttons
import Button from "@material-ui/core/Button";
//routing
import { NavLink } from "react-router-dom";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
export class ServiceInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      client_list: [],
      branch_list: [],
      personnel_list: [],
      user_list: [],
      productName: "",
      productId: "",
      principleName: "",
      principleId: "",
      model: "",
      modelId: "",
      quantity: 1,
      currency: "",
      serviceCharge: "",
      comment: "",
      width: ""
    };

    // this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    axios
      .get(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client"
      )
      .then(clients => {
        this.setState({
          client_list: clients.data
        });
        console.log(clients.data);
      })
      .catch(err => {
        console.log(err);
      });
    axios
      .get(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/user"
      )
      .then(clients => {
        this.setState({
          user_list: clients.data
        });
        console.log(clients.data);
      })
      .catch(err => {
        console.log(err);
      });
  }
  componentWillReceiveProps(nextProps) {
    // if (nextProps.values.clientId !== this.props.values.clientId) {
    //   axios
    //     .get(
    //       `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${nextProps.values.clientId}/branch`
    //     )
    //     .then(branches => {
    //       this.setState({
    //         branch_list: branches.data
    //       });
    //       console.log(branches.data);
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    //   axios
    //     .get(
    //       `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${nextProps.values.clientId}/personnel`
    //     )
    //     .then(clients => {
    //       this.setState({
    //         personnel_list: clients.data
    //       });
    //       console.log(clients.data);
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // }
  }

  render() {
    const { values, handleChange, handleSubmit } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <ReactToPrint
          onBeforeGetContent={() =>
            this.setState({
              width: "100%"
            })
          }
          onAfterPrint={() => {
            this.setState({ width: "" });
          }}
          trigger={() => (
            <Button
              variant="contained"
              size="large"
              type="submit"
              name="submit"
              color="primary"
              style={{ color: "white", marginBottom: 8 }}
            >
              <PrintIcon />
            </Button>
          )}
          content={() => this.componentRef}
        />
        <Paper
          className="root"
          elevation={1}
          style={{
            width: this.state.width,
            padding: this.state.width === "100%" ? 8 : "0px"
          }}
          ref={el => (this.componentRef = el)}
        >
          <NavLink exact to="/services" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                margin: 8,
                display: this.state.width === "100%" ? "none" : ""
              }}
            >
              Go Back
            </Button>
          </NavLink>
          <form style={styles.container} onSubmit={handleSubmit}>
            <div style={styles.divStyle}>
              <Typography variant="p" style={styles.typographyvariant}>
                Client Information
              </Typography>
            </div>
            <div className="divstyle">
              <React.Fragment>
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: "8px" }}
                  >
                    Client Name
                  </InputLabel>
                  <Select
                    required
                    value={values.client}
                    onChange={handleChange}
                    name="client"
                    displayEmpty
                    style={styles.textFieldSelect}
                    inputProps={{ readOnly: true }}
                  >
                    <MenuItem value={values.client}>
                      {values.client.clientName}
                    </MenuItem>
                  </Select>
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: "8px" }}
                  >
                    Place Of Visit
                  </InputLabel>
                  <Select
                    required
                    value={values.facility}
                    onChange={handleChange}
                    name="facility"
                    displayEmpty
                    style={styles.textFieldSelect}
                    inputProps={{ readOnly: true }}
                  >
                    <MenuItem value={values.facility}>
                      {values.facility.branchName}
                    </MenuItem>
                  </Select>
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Address "
                    value={values.facilityAddress}
                    type="text"
                    onChange={handleChange}
                    name="facilityAddress"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Facility "
                    value={values.newFacility}
                    type="text"
                    onChange={handleChange}
                    name="newFacility"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Visit Date"
                    value={values.dateOfSubmission}
                    type="date"
                    onChange={handleChange}
                    name="dateOfSubmission"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: "8px" }}
                  >
                    Contact Name
                  </InputLabel>
                  <Select
                    required
                    value={values.contactName}
                    onChange={handleChange}
                    name="contactName"
                    displayEmpty
                    style={styles.textFieldSelect}
                    inputProps={{ readOnly: true }}
                  >
                    <MenuItem value={values.contactName}>
                      {values.contactName.personnelName}
                    </MenuItem>
                  </Select>
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Contact no"
                    value={values.contactNo}
                    type="text"
                    onChange={handleChange}
                    name="contactNo"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Email"
                    value={values.email}
                    type="email"
                    onChange={handleChange}
                    name="email"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Designation"
                    value={values.designation}
                    type="text"
                    onChange={handleChange}
                    name="designation"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </FormControl>
              </React.Fragment>
            </div>
            <div style={styles.divStyle}>
              <Typography variant="p" style={styles.typographyvariant}>
                Visit Details
              </Typography>
            </div>
            <div className="divstyle">
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Visit By"
                    value={values.visitedBy}
                    type="text"
                    disabled
                    name="nextVisitDate"
                    style={styles.textField}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: "8px" }}
                  >
                    Purpose Of Visit
                  </InputLabel>
                  <Select
                    required
                    value={values.purposeOfVisit}
                    onChange={handleChange}
                    name="purposeOfVisit"
                    displayEmpty
                    style={styles.textFieldSelect}
                    inputProps={{ readOnly: true }}
                  >
                    <MenuItem value={values.purposeOfVisit}>
                      {values.purposeOfVisit}
                    </MenuItem>
                  </Select>
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Next Visit Date"
                    value={values.nextVisitDate}
                    type="date"
                    onChange={handleChange}
                    name="nextVisitDate"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl
                  required
                  component="fieldset"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: "8px",
                    marginTop: "16px"
                  }}
                >
                  <FormLabel component="legend">Visit Status</FormLabel>
                  <RadioGroup
                    aria-label="position"
                    value={values.visitStatus}
                    name="visitStatus"
                    onChange={handleChange}
                    column
                  >
                    <div className="Radiogroup">
                      <FormControlLabel
                        style={{ marginTop: "16px" }}
                        value={"Single Visit"}
                        control={<Radio color="primary" />}
                        label="Single Visit"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        style={{ marginTop: "16px" }}
                        value={"Joint Visit"}
                        control={<Radio color="primary" />}
                        label="Joint Visit"
                        labelPlacement="end"
                      />
                      <Box
                        display={
                          values.visitStatus === "Joint Visit" ? "" : "none"
                        }
                      >
                        <FormControl>
                          <TextField
                            label="Joint Visit With"
                            value={values.jointVisitwith}
                            type="text"
                            onChange={handleChange}
                            name="jointVisitwith"
                            style={styles.textField}
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        </FormControl>
                      </Box>
                    </div>
                  </RadioGroup>
                </FormControl>
              </React.Fragment>
            </div>
            <div style={styles.divStyle}>
              <Typography variant="p" style={styles.typographyvariant}>
                product and principal Information
              </Typography>
            </div>
            <div className="divstyleNext">
              <React.Fragment>
                <Itemtable values={values.item} />
              </React.Fragment>
              <React.Fragment>
                <FormControl fullWidth style={{ marginTop: "16px" }}>
                  <TextField
                    label="Service Comment"
                    value={values.ursComment}
                    type="text"
                    onChange={handleChange}
                    name="ursComment"
                    fullWidth
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </FormControl>
              </React.Fragment>
            </div>
            <br />
          </form>
        </Paper>
      </div>
    );
  }
}
const styles = {
  button: {
    color: "white",
    marginBottom: "24px"
  },
  buttonvariant: {
    color: "white",
    margin: 8
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 300
  },
  textFieldSelect: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 16,
    marginBottom: 8,
    width: 300
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%"
  },
  formControl: {
    display: "flex",
    margin: "8px"
  },
  root: {
    // margin: "16px"
    // boxShadow:"none"
  },
  divStyle: {
    width: "100%",
    background: "rgba(224, 224, 224, 0.25)",
    color: "#757575",
    // opacity:"0.25",
    letterSpacing: "2px",
    height: "60px",
    display: "flex",
    alignItems: "center"
  },
  typographyvariant: {
    fontWeight: "700",
    fontSize: "12px",
    marginLeft: "20px",
    textTransform: "uppercase"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  }
};
export default ServiceInputs;
