import React, { Component } from "react";
import ProductTable from "./ProductTable";
import CreateProduct from "./CreateProduct";
import "../users/user.css";
export class PrincipalView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addProduct: false
    };
  }

  productAdded = () => {
    this.setState({ addProduct: true });
    console.log("Added");
  };

  productAddDone = () => {
    this.setState({ addProduct: false });
    console.log("Done");
  };
  render() {
    return (
      <div style={styles.root}>
        <div style={styles.tableContainer}>
          <ProductTable
            nameinbtn={"+ Add New Principle"}
            url="/#"
            addedData={this.state.addProduct}
            done={this.productAddDone}
            getData={
              "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/product"
            }
          />
        </div>
        <div className="CreateContainer">
          <CreateProduct result={this.productAdded} />
        </div>
      </div>
    );
  }
}

const styles = {
  root: {
    width: "100%",
    display: "flex",
    // justifyContent: "space-between",
    flexWrap: "wrap"
  },
  tableContainer: {
    minWidth: "70%"
  }
  // CreateContainer: {
  //   display: "flex",
  //   justifyContent: "flex-end",
  //   flexWrap: "wrap",
  //   marginTop: "24px"
  // }
};

export default PrincipalView;
