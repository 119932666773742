import React, { Component } from "react";
import ViewECRInputs from "./ViewECRFormDetailsInputs";
import EditECRInputs from "./EditECRFormDetailsInputs";
import PrimarySearchAppBar from "../../layouts/appbarComponent/Appbar";
import axios from "axios";
import { withRouter } from "react-router-dom";
import "./divStyle.css";
import ImgMediaCard from "./DeleteViewECRCard";

// import Button from "@material-ui/core/Button";
// import undefined from "firebase/empty-import";
// import { Redirect } from "react-router-dom";
//utility
// import utility from "../../layouts/utility/utility";

export class ViewECRFormDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.location.state,
      data: false,
      edit: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePush = this.handlePush.bind(this);
    // console.log(this.props.location.state);
    // console.log(this.props.admin)
    console.log(this.state);
  }
  handleChange(e) {
    if (e.target.name === "client") {
      this.setState({ client: e.target.value });
      this.setState({ clientId: e.target.value._id });
    } else if (e.target.name === "facility") {
      console.log(e.target.value);
      this.setState({ facility: e.target.value });
      this.setState({ branchId: e.target.value._id });
      this.setState({ facilityAddress: e.target.value.branchAddress });
    } else if (e.target.name === "contactName") {
      console.log(e.target.value);
      this.setState({ contactName: e.target.value });
      this.setState({ contactId: e.target.value._id });
      this.setState({ contactNo: e.target.value.personnelContactno });
      this.setState({ designation: e.target.value.personnelDesignation });
      this.setState({ email: e.target.value.personnelEmail });
    } else if (e.target.name === "principleName") {
      this.setState({ principleName: e.target.value });
      this.setState({ principleId: e.target.value._id });
    } else if (e.target.name === "productName") {
      this.setState({ productName: e.target.value });
      this.setState({ productId: e.target.value._id });
    } else if (e.target.name === "model") {
      this.setState({ model: e.target.value });
      this.setState({ modelId: e.target.value._id });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
    console.log(this.state);
    console.log(this.props);
  }
  handlePush = data => {
    const newItem = [...this.state.item, data];
    this.setState({ item: newItem });
    console.log(this.state);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      btnDisabled: true
    });
    this.setState(this.state);
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/ecr/${this.state._id}`,
        this.state
      )
      .then(results => {
        console.log(results);
        this.setState({ edit: false, btnDisabled: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          btnDisabled: false
        });
      });
  };
  componentWillMount() {
    console.log("Here");
    if (this.props.location.state === undefined) {
      this.data = true;
    }
  }
  handleEditClick = () => {
    this.setState({ edit: true });
  };

  handleBackClick = () => {
    this.setState({ edit: false });
  };

  handleRemove = () => {
    axios
      .delete(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/ecr/${this.props.location.state._id}`
      )
      .then(result => {
        this.props.history.push("/ecr");
      })
      .catch(err => console.log(err));
  };

  handleRemoveItem = index => {
    console.log("Here");
    if (index > -1) {
      let newItem = this.state.item;
      newItem.splice(index, 1);
      console.log(newItem);
      this.setState({ item: newItem });
    }
  };

  dateHandleChange = date => {
    this.setState({
      dateOfSubmission: `${date.getFullYear()}-${
        String(date.getMonth() + 1).length < 2
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`
      }-${
        String(date.getDate()).length < 2
          ? `0${date.getDate()}`
          : `${date.getDate()}`
      }`
    });
    // console.log(date);
    // console.log(
    //   `${date.getFullYear()}-${
    //     (String(date.getMonth()+1).length) < 2
    //       ? `0${date.getMonth() + 1}`
    //       : `${date.getMonth() + 1}`
    //   }-${date.getDate()}`
    // );
    // console.log(String(date.getMonth()+1).length);
  };

  nextdateHandleChange = date => {
    this.setState({
      nextVisitDate: `${date.getFullYear()}-${
        String(date.getMonth() + 1).length < 2
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`
      }-${
        String(date.getDate()).length < 2
          ? `0${date.getDate()}`
          : `${date.getDate()}`
      }`
    });
  };

  render() {
    console.log(this.state);
    const {
      _id,
      client,
      clientId,
      facility,
      date,
      facilityAddress,
      dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      visitedBy,
      purposeOfVisit,
      referrenceDocument,
      nextVisitDate,
      visitedWith,
      visitStatus,
      jointVisitwith,
      createdAt,
      item,
      ursComment,
      newFacility,
      btnDisabled,
      referenceNumber,
      id
    } = this.state;
    const values = {
      _id,
      client,
      clientId,
      facility,
      date,
      facilityAddress,
      dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      visitedBy,
      purposeOfVisit,
      referrenceDocument,
      nextVisitDate,
      visitedWith,
      visitStatus,
      jointVisitwith,
      createdAt,
      item,
      ursComment,
      newFacility,
      btnDisabled,
      referenceNumber,
      id
    };

    if (this.state.edit) {
      return (
        <div className="fullwidth">
          <React.Fragment>
            <PrimarySearchAppBar title="Edit Effective Call Report" />
          </React.Fragment>
          <React.Fragment>
            <div className="ViewECR">
              <EditECRInputs
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                handlePush={this.handlePush}
                values={values}
                handleRemoveItem={this.handleRemoveItem}
                handleBackClick={this.handleBackClick}
                dateHandleChange={this.dateHandleChange}
                nextdateHandleChange={this.nextdateHandleChange}
              />
            </div>
          </React.Fragment>
        </div>
      );
    }
    return (
      <div className="fullwidth">
        <React.Fragment>
          <PrimarySearchAppBar title="Effective Call Report View" />
        </React.Fragment>
        <React.Fragment>
          <div className="ViewECR">
            <ImgMediaCard
              values={this.state}
              handleEditClick={this.handleEditClick}
              handleRemove={this.handleRemove}
              admin={this.props.admin}
              procurement={this.props.procurement}
            />
            <ViewECRInputs values={values} />
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(ViewECRFormDetails);
