import React, { Component } from "react";
import PropTypes from "prop-types";
//withStyles
import { withStyles } from "@material-ui/core";
//pagination and footer
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
//table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//paper
import Paper from "@material-ui/core/Paper";
//icons
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/ArrowDropUp";
import KeyboardArrowLeft from "@material-ui/icons/ArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/ArrowRight";
import LastPageIcon from "@material-ui/icons/ArrowDropDown";

//searchbar
// import Searchbar from "../layouts/commonComponents/TableSearchbar";
//buttons
// import Button from "@material-ui/core/Button";
//loader
import Loader from "../layouts/commonComponents/Loader";
//modals
import Modals from "./UserEditModal";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import CustomizedSnackbars from '../snackbars/CustomizedSnackbars';
const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5
  }
});

class TablePaginationActions extends Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(TablePaginationActions);

const styles = theme => ({
  // root: {
  //   width: "100%",
  //   marginTop: theme.spacing.unit * 3
  // },
  // table: {
  //   minWidth: 500
  // },
  // tableWrapper: {
  //   overflowX: "auto"
  // },
  // textDecore: {
  //   textDecoration: "none"
  // }
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    // minWidth: 300
    // overflowX: "auto",
    minWidth: "300px"
  },
  tableWrapper: {
    overflow: "auto",
    minWidth: "300px"
  },
  textDecore: {
    textDecoration: "none"
  }
});

class CustomPaginationActionsTable extends Component {
  coloms = ['username','permission','email']
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      page: 0,
      rowsPerPage: 10,
      open: false,
      currentModalData: {},
      searched:false
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.addedData) {
      this.updateTable();
      this.props.done();
    }
  }

  componentDidMount() {
    axios.get(this.props.getData)
      .then(data => {
        this.setState({ rows: [...data.data] });
        console.log(this.state);
      })
      .catch(err => console.log(err));
  }
  //Refreshs Table

  updateTable = () => {
    this.setState({
      open: false
    });
    axios.get(this.props.getData)
      .then(data => {
        this.setState({ rows: [...data.data] });
        console.log(this.state);
      })
      .catch(err => console.log(err));
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  handlePageChange = event => {
    this.props.history.push(this.props.url);
  };
  handleClickOpen = rowData => {
    // console.log(rowData)
    this.setState({
      open: true,
      currentModalData: rowData
    });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleSearchClose = () =>{
    this.setState({ searched: false });
  }
  removeUser = rowData => {
    console.log(rowData._id);
  };
  handleremoveUser = rowData => {
    console.log(rowData);
  };

  handleSearch = (data) => {
    console.log(data);
    axios.get(`https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/search?q=${data}&field=username&coll=users`,)
    .then(res=>{
      console.log(res.data)
      if(res.data.length===0){
        console.log('Here')
        this.setState({searched:true})
      }  
      else{
      
      this.setState({rows:res.data})
      }
    
    })
    .catch(err => console.log(err));
  }

  handleClear = () => {
    axios.get(this.props.getData)
    .then(data => {
      this.setState({ rows: [...data.data],searched:false });
      console.log(this.state);
    })
    .catch(err => console.log(err));
  }

  render() {
    const { classes } = this.props;
    const { rows, rowsPerPage, page } = this.state;
    const { emptyRows } =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    console.log(this.coloms);

    if (this.state.rows.length) {
      return (
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <div
               /* style={{
                display: "flex",
                padding: "20px",
                justifyContent: "space-between"
              }}*/
            >
            <CustomizedSnackbars show={this.state.searched} type='error' message='No fields Found'  />

              {/* <Searchbar sales={false} handleSearch={this.handleSearch}  handleClear={this.handleClear}/> */}
              <Modals
                currentData={this.state.currentModalData}
                open={this.state.open}
                handleClose={this.handleClose}
                updateTable={this.updateTable}
              />
            </div>
            <div style={{ minWidth: "300px", overflowX: "auto" }}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        color: "#BDBDBD",
                        fontWeight: "normal",
                        fontSize: "12px"
                      }}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#BDBDBD",
                        fontWeight: "normal",
                        fontSize: "12px"
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: "#BDBDBD",
                        fontWeight: "normal",
                        fontSize: "12px"
                      }}
                    >
                      Permission Level
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow
                        hover
                        onClick={() => this.handleClickOpen(row)}
                        key={row._id}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            color: "#757575",
                            fontWeight: "500",
                            fontSize: "14px"
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <Avatar
                              style={{
                                width: 24,
                                height: 24,
                                marginRight: 8
                              }}
                              alt="imgUrl"
                              src={row.imgUrl}
                            />
                            {row.username}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#757575",
                            fontWeight: "500",
                            fontSize: "14px"
                          }}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#757575",
                            fontWeight: "500",
                            fontSize: "14px"
                          }}
                        >
                          {row.permission}
                        </TableCell>
                      </TableRow>
                    ))}
                  {{ emptyRows } > 0 && (
                    <TableRow style={{ height: 48 * { emptyRows } }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={6}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        native: true
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </div>
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <div
               /* style={{
                display: "flex",
                padding: "20px",
                justifyContent: "space-between"
              }}*/
            >
              {/* <Searchbar /> */}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ minWidth: "300px" }}>
                <Loader />
              </div>
            </div>
          </div>
        </Paper>
      );
    }
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(CustomPaginationActionsTable));
