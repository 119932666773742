import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { grey } from "@material-ui/core/colors";
import axios from "axios";
// For Buttons
import Button from "@material-ui/core/Button";
// selects
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
// import SimpleTabs from "./Tabs";
// textfields
// import TextField from "@material-ui/core/TextField";
const styles = {
  root: {
    margin: 0,
    padding: "16px"
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: grey
  },
  bigAvatar: {
    margin: 5,
    width: 70,
    height: 70
  },
  box: {
    display: "flex",
    alignItems: "center"
  },
  textFieldSelect: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 16,
    marginBottom: 8,
    width: 250
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8,
    width: 250
  }
};
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export default class CustomizedDialogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      principal_list: [],
      product_list: [],
      model_list: []
    };
  }

  handleRemove = () => {
    axios
      .delete(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${this.props.currentData._id}`
      )
      .then(result => {
        console.log(result);
        this.props.updateTable();
      })
      .catch(err => console.log(err));
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.values.principleId !== this.props.values.principleId) {
      console.log("here");
      axios
        .get(
          `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/principal/${nextProps.values.principleId}/product`
        )
        .then(branches => {
          this.setState({
            product_list: branches.data
          });
          console.log(branches.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
    if (nextProps.values.productId !== this.props.values.productId) {
      console.log("here");
      axios
        .get(
          `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/product/${nextProps.values.productId}/model`
        )
        .then(branches => {
          this.setState({
            model_list: branches.data
          });
          console.log(branches.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  componentWillMount() {
    axios
      .get(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/principal"
      )
      .then(data => {
        this.setState({ principal_list: [...data.data] });
        console.log(data.data);
      })
      .catch(err => console.log(err));
  }

  render() {
    const { values, handleChange, handleItemSubmit } = this.props;
    console.log(this.props);
    return (
      <div>
        <Dialog
          maxWidth="sm"
          onClose={this.props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.handleClose}
          >
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={9}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <p style={{ fontSize: "24px", fontWeight: "500" }}>
                  Add Product
                </p>
                <Grid style={styles.box}>
                  <IconButton
                    aria-label="delete"
                    style={{
                      margin: "8px"
                    }}
                    onClick={this.handleRemove}
                  ></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column"
              }}
            >
              <React.Fragment>
                <Select
                  value={values.principleName}
                  onChange={handleChange}
                  name="principleName"
                  displayEmpty
                  style={styles.textFieldSelect}
                >
                  <MenuItem value={""} disabled>
                    Principal Name
                  </MenuItem>
                  {this.state.principal_list.map(principal => (
                    <MenuItem value={principal}>
                      {principal.principalName}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  value={values.productName}
                  onChange={handleChange}
                  name="productName"
                  displayEmpty
                  style={styles.textFieldSelect}
                >
                  <MenuItem value={""} disabled>
                    Product Name
                  </MenuItem>
                  {this.state.product_list.map(product => (
                    <MenuItem value={product}>{product.productName}</MenuItem>
                  ))}
                </Select>
                <Select
                  value={values.model}
                  onChange={handleChange}
                  name="model"
                  displayEmpty
                  style={styles.textFieldSelect}
                >
                  <MenuItem value={""} disabled>
                    Model Name
                  </MenuItem>
                  {this.state.model_list.map(model => (
                    <MenuItem value={model}>{model.ModelName}</MenuItem>
                  ))}
                </Select>
                <FormControl>
                  <TextField
                    required
                    label="Quantity"
                    value={values.quantity}
                    type="number"
                    onChange={handleChange}
                    name="quantity"
                    style={styles.textField}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    label="Product Comment"
                    value={values.productComment}
                    type="text"
                    onChange={handleChange}
                    name="productComment"
                    style={styles.textField}
                    multiline
                    rowsMax="4"
                  />
                </FormControl>
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  name="submit"
                  color="secondary"
                  style={{ color: "white" }}
                  onClick={handleItemSubmit}
                  disabled={values.principleName === ""}
                >
                  Add
                </Button>
              </React.Fragment>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
