import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
//For Text Fields
import TextField from "@material-ui/core/TextField";
//Form Control
import FormControl from "@material-ui/core/FormControl";
//Select
import { Select } from "@material-ui/core";
//MenuItem
import MenuItem from "@material-ui/core/MenuItem";

//paper
import Paper from "@material-ui/core/Paper";
//For Buttons
import Button from "@material-ui/core/Button";
//for visibility toggle of password

class PrincipalEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   showPassword: false
    };
  }

  handleClickShowPassword = () => {
    // const newState = !this.state.showPassword;
    this.setState({
      //   showPassword: newState
    });
  };

  country_list = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua &amp; Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia &amp; Herzegovina",
    "Botswana",
    "Brazil",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Cote D Ivoire",
    "Croatia",
    "Cruise Ship",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "French West Indies",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kuwait",
    "Kyrgyz Republic",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Pierre &amp; Miquelon",
    "Samoa",
    "San Marino",
    "Satellite",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "St Kitts &amp; Nevis",
    "St Lucia",
    "St Vincent",
    "St. Lucia",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor L'Este",
    "Togo",
    "Tonga",
    "Trinidad &amp; Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks &amp; Caicos",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (US)",
    "Yemen",
    "Zambia",
    "Zimbabwe"
  ];

  render() {
    const { values, handleChange, handleSubmit } = this.props;
    return (
      <MuiThemeProvider>
        <React.Fragment>
          <Paper style={styles.root} elevation={this.props.elevate}>
            <form style={styles.container} onSubmit={handleSubmit}>
              <React.Fragment>
                <TextField
                  label="Principal Name"
                  value={values.principalName}
                  type="text"
                  onChange={handleChange}
                  margin="normal"
                  name="principalName"
                  style={styles.textField}
                />
                <TextField
                  label="Principle_Code"
                  value={values.principalCode}
                  type="text"
                  onChange={handleChange}
                  margin="normal"
                  name="principalCode"
                  style={styles.textField}
                />
                <TextField
                  label="Enlistment Year"
                  value={values.Enlistment}
                  type="text"
                  onChange={handleChange}
                  margin="normal"
                  name="Enlistment"
                  style={styles.textField}
                />

                <FormControl required style={styles.formControl}>
                  <Select
                    placeholder="Country"
                    value={values.country}
                    onChange={handleChange}
                    name="country"
                    style={styles.textFieldSelect}
                  >
                    <MenuItem value={""} disabled>
                      Country
                    </MenuItem>
                    {this.country_list.map(country => {
                      return <MenuItem value={country}>{country}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </React.Fragment>

              <React.Fragment>
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  name="submit"
                  style={styles.button}
                >
                  {this.props.submit}
                </Button>
              </React.Fragment>
            </form>
          </Paper>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

const styles = {
  button: {
    margin: 8,
    width: 350,
    background: "#8BC34A",
    color: "white"
  },
  buttonDisabled: {
    margin: 8,
    width: 350,
    background: "grey",
    color: "DimGray"
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 350
  },
  textFieldSelect: {
    width: 350
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    width: "100%"
  },
  formControl: {
    marginTop: "16px",
    marginBottom: " 8px",
    width: 350
  },
  root: {
    padding: "24px 16px",
    display: "flex",
    flexWrap: "wrap"
  }
};

export default PrincipalEditForm;
