import React, { Component } from "react";
import PrincipalForm from "./PrincipalForm";
import axios from "axios";
import { withRouter } from "react-router-dom";

export class CreatePrincipal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Enlistment: "",
      principalName: "",
      principalCode: "",
      country: "",
      products: 0,
      btnDisabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    console.log(this.state);
  };
  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      btnDisabled: true
    });
    axios
      .post(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/principal",
        this.state
      )
      .then(results => {
        console.log(results);
        const emptyState = {
          principalName: "",
          principalCode: "",
          country: "",
          products: 0,
           btnDisabled: false
        };
        this.setState(emptyState);
        this.props.result();
      })
      .catch(err => console.log(err));
  }

  render() {
    const { Enlistment, principalName, principalCode, country } = this.state;
    const values = {
      Enlistment,
      principalName,
      principalCode,
      country
    };
    return (
      <div style={styles.fullwidth}>
        <div style={{ width: "100%" }}>
          <React.Fragment>
            <PrincipalForm
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              values={values}
              submit="Add Principal"
              elevate={1}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}
const styles = {
  fullwidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around"
  }
};

export default withRouter(CreatePrincipal);
