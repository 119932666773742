import React, { Component } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Table from "../tables/statsTable";
//Loader
import CircularProgress from "@material-ui/core/CircularProgress";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3} style={{ padding: 0 }}>
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export class FullWidthTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      tabData: [],
    };
  }

  componentDidMount() {
    this.setState({ tabData: this.props.data });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({ tabData: nextProps.data });
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };
  render() {
    const classes = makeStyles((theme) => ({
      root: {
        backgroundColor: theme.palette.background.paper,
      },
    }));

    let appBar = (
      <AppBar position="static" color="default" elevation={0}>
        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          style={{ background: "#F2F4F5" }}
        >
          {this.props.tabTitles.map((title, i) => (
            <Tab
              label={title}
              {...a11yProps(i)}
              style={{
                backgroundColor: this.props.tabColors[i],
                color: "white",
              }}
            />
          ))}
        </Tabs>
      </AppBar>
    );
    let last = null;
    if (this.state.tabData.data) {
      last = (
        <SwipeableViews
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          {this.props.filterValues.map((value, i) => (
            <TabPanel value={this.state.value} index={i}>
              <Table
                feature={this.props.feature}
                isSales={this.props.isSales}
                data={{
                  data: this.state.tabData.data.filter((item) => {
                    return this.props.filterFunction({
                      feature: this.props.feature,
                      item: item,
                      value: value,
                    });
                  }),
                }}
              />
            </TabPanel>
          ))}
        </SwipeableViews>
      );
    } else {
      last = <CircularProgress width="500" />;
    }

    return (
      <div>
        <div className={classes.root}>
          {appBar}
          {last}
        </div>
      </div>
    );
  }
}

export default FullWidthTabs;
