import React, { Component } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

//Charts
import StatView from "./StatsView";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3} style={{ padding: 0 }}>
        {children}
      </Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}


export class FullWidthTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      OfferData: []
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  render() {
    const classes = makeStyles(theme => ({
      root: {
        backgroundColor: theme.palette.background.paper
        // width: 500
      }
    }));
    //  const theme = useTheme();

    return (
      <div>
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
              style={{ background: "#F2F4F5" }}
            >
              <Tab label="ECR" {...a11yProps(0)} />
              <Tab label="URS" {...a11yProps(1)} />
              <Tab label="OFFERS" {...a11yProps(2)} />
              <Tab label="SERVICES" {...a11yProps(3)} />
              <Tab label="BillS" {...a11yProps(4)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
          >
            <TabPanel value={this.state.value} index={0}>
              <StatView feature="ecr" />
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
              <StatView feature="urs" />
            </TabPanel>
            <TabPanel value={this.state.value} index={2}>
              <StatView feature="offers" />
            </TabPanel>
            <TabPanel value={this.state.value} index={3}>
              <StatView feature="services" />
            </TabPanel>
            <TabPanel value={this.state.value} index={4}>
              <StatView feature="bills" />
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    );
  }
}

export default FullWidthTabs;
