import React, { Component } from "react";
import PrimarySearchAppBar from "../../layouts/appbarComponent/Appbar";
import axios from "axios";
import { withRouter } from "react-router-dom";
import ViewOfferInputs from "./ViewOfferFormDetailsInputs";
import EditOfferInputs from "./EditOfferFormDetailsInput";
import "./Style.css";
// import ImgMediaCard from "./DeleteViewOfferCard";
import ViewOfferDetails from "../OfferRightColumn/ViewOfferRightSection";
import { Grid } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";

export class ViewURSFormDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.location.state,
      data: false,
      edit: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePush = this.handlePush.bind(this);
    this.updateTable = this.updateTable.bind(this);
    // console.log(this.state);
  }

  updateTable() {
    axios
      .get(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/offer/${this.state._id}`
      )
      .then(res => {
        this.setState(
          st => ({ ...st, ...res.data, edit: false }),
          () => console.log(this.state)
        );
        console.log(res.data);
      })
      .catch(err => console.log(err));
  }

  handleChange(e) {
    if (e.target.name === "client") {
      this.setState({ client: e.target.value });
      this.setState({ clientId: e.target.value._id });
    } else if (e.target.name === "facility") {
      console.log(e.target.value);
      this.setState({ facility: e.target.value });
      this.setState({ branchId: e.target.value._id });
      this.setState({ facilityAddress: e.target.value.branchAddress });
    } else if (e.target.name === "contactName") {
      console.log(e.target.value);
      this.setState({ contactName: e.target.value });
      this.setState({ contactId: e.target.value._id });
      this.setState({ contactNo: e.target.value.personnelContactno });
      this.setState({ designation: e.target.value.personnelDesignation });
      this.setState({ email: e.target.value.personnelEmail });
    } else if (e.target.name === "principleName") {
      this.setState({ principleName: e.target.value });
      this.setState({ principleId: e.target.value._id });
    } else if (e.target.name === "productName") {
      this.setState({ productName: e.target.value });
      this.setState({ productId: e.target.value._id });
    } else if (e.target.name === "model") {
      this.setState({ model: e.target.value });
      this.setState({ modelId: e.target.value._id });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
    console.log(this.state);
    // console.log(this.props);
  }
  handlePush = data => {
    const newItem = [...this.state.item, data];
    this.setState({ item: newItem });
    console.log(this.state);
  };
  handleSubmit = e => {
    e.preventDefault();
    const {
      client,
      clientId,
      facility,
      facilityAddress,
      newfacility,
      dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      visitedBy,
      item,
      ursComment,
      visitorDesignation,
      currency,
      currencyRate,
      principalCost,
      finalQuotate,
      ursID,
      id,
      btnDisabled,
      sentBy,
      referenceNumber
    } = this.state;
    const values = {
      client,
      clientId,
      facility,
      facilityAddress,
      newfacility,
      dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      visitedBy,
      item,
      ursComment,
      visitorDesignation,
      currency,
      currencyRate,
      principalCost,
      finalQuotate,
      ursID,
      id,
      btnDisabled,
      sentBy,
      referenceNumber
    };
    this.setState({
      btnDisabled: true
    });
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/offer/${this.state._id}`,
        values
      )
      .then(results => {
        console.log(results);
        this.setState({ edit: false, btnDisabled: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          btnDisabled: false
        });
      });
  };

  componentWillMount() {
    console.log("Here");
    if (this.props.location.state === undefined) {
      this.data = true;
    }
  }
  componentDidMount() {
    this.updateTable();
  }
  handleEditClick = () => {
    this.setState({ edit: true });
  };

  handleBackClick = () => {
    this.setState({ edit: false });
  };

  handleRemove = () => {
    axios
      .delete(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/offer/${this.props.location.state._id}`
      )
      .then(result => {
        this.props.history.push("/offers");
      })
      .catch(err => console.log(err));
  };

  handleRemoveItem = index => {
    console.log("Here");
    if (index > -1) {
      let newItem = this.state.item;
      newItem.splice(index, 1);
      console.log(newItem);
      this.setState({ item: newItem });
    }
  };

  render() {
    const {
      client,
      clientId,
      facility,
      facilityAddress,
      newfacility,
      dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      visitedBy,
      item,
      ursComment,
      visitorDesignation,
      currency,
      currencyRate,
      principalCost,
      finalQuotate,
      id,
      offerCreatedAt,
      btnDisabled,
      sentBy,
      referenceNumber
    } = this.state;

    const values = {
      client,
      clientId,
      facility,
      facilityAddress,
      newfacility,
      dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      visitedBy,
      item,
      ursComment,
      visitorDesignation,
      currency,
      currencyRate,
      principalCost,
      finalQuotate,
      id,
      offerCreatedAt,
      btnDisabled,
      sentBy,
      referenceNumber
    };
    console.log(values);
    if (this.state.edit) {
      return (
        <div className="fullwidth">
          <React.Fragment>
            <PrimarySearchAppBar title="Edit Offer Specification" />
          </React.Fragment>
          <React.Fragment>
            <div className="ViewURS">
              <EditOfferInputs
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                handlePush={this.handlePush}
                values={values}
                handleRemoveItem={this.handleRemoveItem}
                handleBackClick={this.handleBackClick}
              />
            </div>
          </React.Fragment>
        </div>
      );
    }
    return (
      <div className="fullwidth">
        <React.Fragment>
          <PrimarySearchAppBar title="View Offer" />
        </React.Fragment>
        <div>
          <ReactToPrint
            onBeforeGetContent={() =>
              this.setState({
                initwidth: "100%"
              })
            }
            onAfterPrint={() => {
              this.setState({ initwidth: "" });
            }}
            trigger={() => {
              console.log(this.state.initwidth);
              return (
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  name="submit"
                  color="primary"
                  style={{ color: "white", marginBottom: 8 }}
                >
                  <PrintIcon />
                </Button>
              );
            }}
            content={() => this.componentRef}
          />
        </div>

        <div
          style={{ display: "flex", flexWrap: "wrap" }}
          ref={el => (this.componentRef = el)}
        >
          <Grid item xs={12} sm={10} md={8}>
            <ViewOfferInputs values={values} />
          </Grid>
          <Grid item xs={12} sm={10} md={3} style={styles.container}>
            <ViewOfferDetails
              values={this.state}
              handleEditClick={this.handleEditClick}
              handleRemove={this.handleRemove}
              admin={this.props.admin}
              procurement={this.props.procurement}
              updateTable={this.updateTable}
            />
          </Grid>
        </div>
      </div>
    );
  }
}
const styles = {
  container: {
    background: "#375A6D",
    borderRadius: "5px",
    marginTop: "5px",
    height: "fit-content"
  }
};
export default withRouter(ViewURSFormDetails);
