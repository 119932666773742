import React, { Component } from "react";
import BranchForm from "./BranchForm";
import axios from "axios";
import { withRouter } from "react-router-dom";

export class CreateBranch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchName: "",
      branchAddress: "", 
     
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    console.log(this.state);
  };
  handleSubmit(e) {
    e.preventDefault();
    axios
      .post(
      `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${this.props.currentData._id}/branch`,
        this.state
      )
      .then(results => {
        console.log(results);
        const emptyState = {
            branchName: "",
            branchAddress: "", 
        };
        this.setState(emptyState);
        this.props.updateTable();
      })
      .catch(err => console.log(err));
  }

  render() {
    const { branchName, branchAddress } = this.state;
    const values = { branchName, branchAddress };
    return (
      <div style={styles.fullwidth}>
        <div>
          <React.Fragment>
            <BranchForm
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              values={values}
              submit="Add Branch"
              elevate={0}
              updatetable={this.props.updateTableBranch}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}
const styles = {
  fullwidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around"
  }
};

export default withRouter(CreateBranch);
