import React, { Component } from "react";
import ClientTable from "../clients/ClientTable";
import CreateClient from "../clients/CreateClient";
import "../users/user.css";

export class ClientView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addClient: false
    };
  }

  clientAdded = () => {
    this.setState({ addClient: true });
    // console.log("Added");
  };

  clientAddDone = () => {
    this.setState({ addClient: false });
    // console.log("Done");
  };
  render() {
    return (
      <div style={styles.root}>
        <div style={styles.tableContainer}>
          <ClientTable
            url="/#"
            addedData={this.state.addClient}
            done={this.clientAddDone}
            getData={
              "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client"
            }
          />
        </div>
        <div className="CreateContainer">
          <CreateClient result={this.clientAdded} />
        </div>
      </div>
    );
  }
}

const styles = {
  root: {
    width: "100%",
    display: "flex",
    // justifyContent: "space-between",
    flexWrap: "wrap"
  },
  tableContainer: {
    minWidth: "70%"
  }
  // CreateContainer: {
  //   display: "flex",
  //   justifyContent: "flex-end",
  //   flexWrap: "wrap",
  //   marginTop: "14px"
  // }
};

export default ClientView;
