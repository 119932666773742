import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Searchbar from "../layouts/commonComponents/SaleTableSearcbar";
import CustomizedSnackbars from "./../snackbars/CustomizedSnackbars";

//axios
import axios from "axios";


export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      searchData: [],
    };
  }


  handleClear = () => {
    this.props.onSearchClick();
    axios
      .get(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/` + this.props.feature
      )
      .then(res =>
        this.props.onSearchResult(res)
      )
      .catch(err => console.log(err));
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleSearch = (data, field) => {
    this.props.onSearchClick();
    let queryURL = "";
    if (field === "createdAtString" || field === "totalDue") {
        queryURL = `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/search?q=${data.searchValueDate}&q2=${data.searchValueDate2}&field=${field}&category=` + this.props.feature;
      } else {
        queryURL = `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/search?q=${data}&field=${field}&category=` + this.props.feature;
      }
    axios.get(queryURL)
      .then(res => {
        console.log(res);
        if (res.data.length !== 0) {
            this.props.onSearchResult(res);
        }
      })
      .catch(err => console.log(err));
  };


  render() {
    const classes = makeStyles(theme => ({
      root: {
        backgroundColor: theme.palette.background.paper
      }
    }));

    let searchStuff = (<div className="SearchConatiner">
      <CustomizedSnackbars
        show={this.state.searched}
        type="error"
        message="No fields Found"
      />

      <Searchbar
        sales={true}
        categories={this.props.categories}
        handleSearch={this.handleSearch}
        handleClear={this.handleClear}
      />

    </div>);

   

    return (
      <div>
        <div className={classes.root}>
          {searchStuff}
        </div>
      </div>
    );
  }
}

export default Search;
