import React, { Component } from "react";
import axios from "axios";
import PrimarySearchAppBar from "../../layouts/appbarComponent/Appbar";
import PendingIntForm from "./PendingIntForm";
//router
import { withRouter } from "react-router-dom";
//utility
import utility from "../../layouts/utility/utility";
import "./divStyle.css";

import firebase from "firebase";

var storage = firebase.storage();
var storageRef = storage.ref();

export class AddPendingIntBills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      client: "",
      clientId: "",
      facility: "",
      facilityAddress: "",
      branchId: "",
      newFacility: "",
      billIssueDate: utility,
      billRecieveDate: null,
      purchaseOrderNumber: "",
      poDate: null,
      currency: "",
      totalPayableAmount: "",
      totalAdvanceAmount: "0",
      uploadFile: "",
      referenceNumber: "",
      btnDisabled: false,
      item: [],
      currencyRate: "",
      senderLoader: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePush = this.handlePush.bind(this);
  }
  //component will recieve new props on any new change
  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== undefined) {
      this.setState({ visitedBy: nextProps.email });
    }
  }
  handleChange(e) {
    if (e.target.name === "client") {
      this.setState({ client: e.target.value });
      this.setState({ clientId: e.target.value._id });
    } else if (e.target.name === "facility") {
      console.log(e.target.value);
      this.setState({ facility: e.target.value });
      this.setState({ branchId: e.target.value._id });
      this.setState({ facilityAddress: e.target.value.branchAddress });
    } else if (e.target.name === "contactName") {
      console.log(e.target.value);
      this.setState({ contactName: e.target.value });
      this.setState({ contactId: e.target.value._id });
      this.setState({ contactNo: e.target.value.personnelContactno });
      this.setState({ designation: e.target.value.personnelDesignation });
      this.setState({ email: e.target.value.personnelEmail });
    } else if (e.target.name === "principleName") {
      this.setState({ principleName: e.target.value });
      this.setState({ principleId: e.target.value._id });
    } else if (e.target.name === "productName") {
      this.setState({ productName: e.target.value });
      this.setState({ productId: e.target.value._id });
    } else if (e.target.name === "model") {
      this.setState({ model: e.target.value });
      this.setState({ modelId: e.target.value._id });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    this.setState({ id: this.getID() });
    console.log(this.state);
    // console.log(this.props);
  }
  handlePush = (data) => {
    const newItem = [...this.state.item, data];
    this.setState({ item: newItem });
    console.log(this.state);
  };

  getID() {
    if (this.state.client !== "") {
      var idx = Math.round(+new Date() + Math.random() * 1000000);
      // console.log(this.state)
      // console.log(this.state.client)
      if (this.state.item.length > 0) {
        return (
          "PCON-IPB-" +
          this.state.client.clientCode +
          "-" +
          this.state.item[0].principleName.principalCode +
          "-" +
          (idx % 10000)
        );
      } else {
        return (
          "PCON-IPB-" + this.state.client.clientCode + "-NA-" + (idx % 10000)
        );
      }
    } else {
      return "";
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      btnDisabled: true,
    });
    // console.log(this.state);
    if (
      this.state.id !== "" &&
      parseFloat(this.state.totalPayableAmount) >=
        parseFloat(this.state.totalAdvanceAmount)
    ) {
      let values = {
        id: this.state.id,
        client: this.state.client,
        clientId: this.state.clientId,
        facility: this.state.facility,
        facilityAddress: this.state.facilityAddress,
        branchId: this.state.branchId,
        newFacility: this.state.newFacility,
        billIssueDate: this.state.billIssueDate,
        billRecieveDate: this.state.billRecieveDate,
        purchaseOrderNumber: this.state.purchaseOrderNumber,
        poDate: this.state.poDate,
        currency: this.state.currency,
        totalPayableAmount: parseFloat(this.state.totalPayableAmount),
        totalAdvanceAmount: parseFloat(this.state.totalAdvanceAmount),
        referenceNumber: this.state.referenceNumber,
        totalDue: this.state.totalPayableAmount - this.state.totalAdvanceAmount,
        item: this.state.item,
        currencyRate: this.state.currencyRate,
      };
      if (this.state.uploadFile !== "") {
        var downloadUrl = await storageRef
          .child(`bills/${new Date().toJSON()}_${this.state.uploadFile.name}`)
          .put(this.state.uploadFile);
        values.uploadFile = await downloadUrl.ref.getDownloadURL();
      }
      console.log(values);

      this.setState({
        // senderLoader: true,
      });
      console.log(values);
      axios
        .post(
          // "http://localhost:8080/ibills",
          "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/ibills",
          values
        )
        .then((results) => {
          console.log(results);
          this.props.history.push("/pendingbillsint");
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            btnDisabled: false,
          });
        });
    } else {
      alert("Total Due is larger then total payment");
    }
  };

  handleRemoveItem = (index) => {
    console.log("Here");
    if (index > -1) {
      let newItem = this.state.item;
      newItem.splice(index, 1);
      console.log(newItem);
      this.setState({ item: newItem });
    }
  };

  handleFile = (e) => {
    this.setState({
      uploadFile: e.target.files[0],
    });

    console.log(e.target.files[0]);
  };

  previousdateHandleChange = (date) => {
    this.setState({
      billIssueDate: `${date.getFullYear()}-${
        String(date.getMonth() + 1).length < 2
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`
      }-${
        String(date.getDate()).length < 2
          ? `0${date.getDate()}`
          : `${date.getDate()}`
      }`,
    });
  };

  nextdateHandleChange = (date) => {
    date instanceof Date
      ? this.setState({
          billRecieveDate: `${date.getFullYear()}-${
            String(date.getMonth() + 1).length < 2
              ? `0${date.getMonth() + 1}`
              : `${date.getMonth() + 1}`
          }-${
            String(date.getDate()).length < 2
              ? `0${date.getDate()}`
              : `${date.getDate()}`
          }`,
        })
      : this.setState({
          billRecieveDate: "",
        });
  };

  poDateHandleChange = (date) => {
    this.setState({
      poDate: `${date.getFullYear()}-${
        String(date.getMonth() + 1).length < 2
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`
      }-${
        String(date.getDate()).length < 2
          ? `0${date.getDate()}`
          : `${date.getDate()}`
      }`,
    });
  };

  render() {
    const {
      id,
      client,
      clientId,
      facility,
      facilityAddress,
      newFacility,
      branchId,
      billIssueDate,
      billRecieveDate,
      purchaseOrderNumber,
      poDate,
      currency,
      totalPayableAmount,
      totalAdvanceAmount,
      uploadFile,
      referenceNumber,
      btnDisable,
      item,
      currencyRate,
      senderLoader,
    } = this.state;
    const values = {
      id,
      client,
      clientId,
      facility,
      facilityAddress,
      newFacility,
      branchId,
      billIssueDate,
      billRecieveDate,
      purchaseOrderNumber,
      poDate,
      currency,
      totalPayableAmount,
      totalAdvanceAmount,
      uploadFile,
      referenceNumber,
      btnDisable,
      item,
      currencyRate,
      senderLoader,
    };
    return (
      <div className="fullwidth">
        <React.Fragment>
          <PrimarySearchAppBar title="Add New International Bill" />
        </React.Fragment>
        <React.Fragment>
          <PendingIntForm
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            handlePush={this.handlePush}
            values={values}
            handleRemoveItem={this.handleRemoveItem}
            handleFile={this.handleFile}
            previousdateHandleChange={this.previousdateHandleChange}
            nextdateHandleChange={this.nextdateHandleChange}
            poDateHandleChange={this.poDateHandleChange}
          />
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(AddPendingIntBills);
