import React, { Component } from "react";
import UserEditForm from "./UserEditForm";
import axios from "axios";
import { withRouter } from "react-router-dom";

export class EditUser extends Component {
  done = false;
  constructor(props) {
    super(props);
    this.state = {
      imageofuser: this.props.currentData.imageofuser,
      username: this.props.currentData.username,
      fullname: this.props.currentData.fullname,
      designation: this.props.currentData.designation,
      email: this.props.currentData.email,
      _id: this.props.currentData._id,
      permission: this.props.currentData.permission,
      btnDisabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    console.log(this.state._id);
  };
  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      btnDisabled: true
    });
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/user/${this.state._id}`,
        this.state
      )
      .then(result => {
        console.log(result);
        this.props.updateTable();
      })
      .catch(err => {
        console.log(err);
        this.setState({
          btnDisabled: false
        });
      });
  }

  render() {
    const {
      imageofuser,
      username,
      fullname,
      designation,
      email,
      password,
      permission,
      btnDisabled
    } = this.state;
    const values = {
      imageofuser,
      username,
      fullname,
      designation,
      email,
      password,
      permission,
      btnDisabled
    };

    return (
      <div style={styles.fullwidth}>
        <div>
          <React.Fragment>
            <UserEditForm
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              values={values}
              submit="UPDATE"
              elevate={0}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}
const styles = {
  fullwidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around"
  }
};

export default withRouter(EditUser);
