import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import PieChart from "../charts/pie";
import LineChart from "../charts/line";

//select
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
// import ECRStatsTable from './ECRStatsTable';
import Table from "../tables/statsTable";
//axios
import axios from "axios";
//Loader
import CircularProgress from "@material-ui/core/CircularProgress";
//Tabs
import Tabs from "./Tabs";
//Search
import Search from "../search/search";

export class StatView extends Component {
  constructor(props) {
    super(props);
    let list = [];
    for (var i = 2019; i <= new Date().getFullYear(); i++) {
      list.push(i.toString());
    }
    // console.log(new Date().getFullYear());
    this.state = {
      selectBy: "client",
      selectQuarter: "all",
      selectMonth: "all",
      selectyear: new Date().getFullYear().toString(),
      statsData: { data: [] },
      yearData: null,
      bulkData: null,
      dateData: null,
      canRender: false,
      subSelection: "all",
      people: [],
      years: list,
      isSales: this.props.sales ? true : false,
      dbURL:
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/getBulkData?feature=" +
        this.props.feature,
      // dbURL: 'http://localhost:8080/getBulkData?feature=' + this.props.feature
    };
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  setPeople(data, selectBy) {
    switch (selectBy) {
      case "client":
        return data.data
          .map((d) => d.client.clientName)
          .filter(this.onlyUnique)
          .sort();
      case "principal":
        return data.data
          .map((d) => d["item"][0]["principleName"]["principalName"])
          .filter(this.onlyUnique)
          .sort();
      case "marketing":
        let retData = [];
        switch (this.props.feature) {
          case "ecr":
            retData = data.data
              .map((d) => d.visitedBy)
              .filter(this.onlyUnique)
              .sort();
            return retData;
          case "urs":
            retData = data.data
              .map((d) => d.CollectedBy.email)
              .filter(this.onlyUnique)
              .sort();
            return retData;
          case "offers":
            retData = data.data
              .map((d) => d.sentBy.email)
              .filter(this.onlyUnique)
              .sort();
            return retData;
          case "services":
            retData = data.data
              .map((d) => d.visitedBy)
              .filter(this.onlyUnique)
              .sort();
            return retData;
          default:
            return retData;
        }
      default:
        return [];
    }
  }

  compareValues(key, order = "asc") {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  }

  getDataAndSetState() {
    this.setState({
      canRender: false,
    });
    // console.log(this.state.dbURL)
    axios
      .get(this.state.dbURL)
      .then((data) => {
        let yData = this.state.isSales
          ? {
              data: data.data.sort(
                this.compareValues("createdAtString", "desc")
              ),
            }
          : this.filterData({ data: data, year: this.state.selectyear });
        this.setState({
          bulkData: data,
          canRender: true,
          statsData: yData,
          yearData: yData,
          dateData: yData,
          people: this.state.isSales ? [] : this.setPeople(yData, "client"),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.getDataAndSetState();
  }

  isStringInteger(numStr) {
    return Number.isInteger(parseInt(numStr));
  }

  getYearCreatedAtString(createdAtString) {
    return createdAtString.substring(0, 4);
  }

  getQuarterCreatedAtString(createdAtString) {
    switch (Math.floor(createdAtString.substring(5, 7) / 4)) {
      case 0:
        return "1";
      case 1:
        return "2";
      case 2:
        return "3";
      case 3:
        return "4";
      default:
        return false;
    }
  }

  getMonthCreatedAtString(createdAtString) {
    return createdAtString.substring(5, 7);
  }

  matchYear(createdAtString, year) {
    if (
      this.isStringInteger(this.getYearCreatedAtString(createdAtString)) &&
      this.isStringInteger(year)
    ) {
      return (
        parseInt(this.getYearCreatedAtString(createdAtString)) ===
        parseInt(year)
      );
    }
    return false;
  }

  matchQuarter(createdAtString, quarter) {
    if (this.matchYear(createdAtString, this.state.selectyear)) {
      if (
        this.isStringInteger(this.getQuarterCreatedAtString(createdAtString)) &&
        this.isStringInteger(quarter)
      ) {
        console.log(
          parseInt(this.getQuarterCreatedAtString(createdAtString)),
          parseInt(quarter),
          parseInt(this.getQuarterCreatedAtString(createdAtString)) ===
            parseInt(quarter)
        );
        return (
          parseInt(this.getQuarterCreatedAtString(createdAtString)) ===
          parseInt(quarter)
        );
      }
    }
    return false;
  }

  matchMonth(createdAtString, month) {
    if (this.matchYear(createdAtString, this.state.selectyear)) {
      if (
        this.isStringInteger(this.getMonthCreatedAtString(createdAtString)) &&
        this.isStringInteger(month)
      ) {
        return (
          parseInt(this.getMonthCreatedAtString(createdAtString)) ===
          parseInt(month)
        );
      }
    }
    return false;
  }

  getSubSelection(data, subSelection) {
    switch (this.state.selectBy) {
      case "client":
        return data.data.filter(
          (record) => record["client"]["clientName"] === subSelection
        );
      case "principal":
        return data.data.filter(
          (record) =>
            record["item"][0]["principleName"]["principalName"] === subSelection
        );
      case "marketing":
        let retData = data;
        switch (this.props.feature) {
          case "ecr":
            retData = data.data.filter(
              (record) => record["visitedBy"] === subSelection
            );
            return retData;
          case "urs":
            retData = data.data.filter(
              (record) => record.CollectedBy.email === subSelection
            );
            return retData;
          case "offers":
            retData = data.data.filter(
              (record) => record.sentBy.email === subSelection
            );
            return retData;
          case "services":
            retData = data.data.filter(
              (record) => record.visitedBy === subSelection
            );
            return retData;
          default:
            return retData;
        }
      default:
        return data;
    }
  }

  filterData({
    data,
    year = false,
    quarter = false,
    month = false,
    subSelection = false,
  }) {
    if (!year && !quarter && !month && !subSelection) {
      return { data: data };
    } else {
      //Fitlering Erroneous Records
      data = {data: data.data.filter(record => record.createdAtString && record._id)};
      if (year) {
        return {
          data: data.data
            .filter((record) => this.matchYear(record.createdAtString, year))
            .sort(this.compareValues("createdAtString", "desc")),
        };
      } else if (quarter) {
        return {
          data: data.data
            .filter((record) =>
              this.matchQuarter(record.createdAtString, quarter)
            )
            .sort(this.compareValues("createdAtString", "desc")),
        };
      } else if (month) {
        return {
          data: data.data
            .filter((record) => this.matchMonth(record.createdAtString, month))
            .sort(this.compareValues("createdAtString", "desc")),
        };
      } else if (subSelection) {
        return {
          data: this.getSubSelection(data, subSelection).sort(
            this.compareValues("createdAtString", "desc")
          ),
        };
      } else {
        return { data: data };
      }
    }
  }

  didSelectAll(e) {
    return e.target.value === "all";
  }

  handleChangeMonth = (e) => {
    let currData = this.didSelectAll(e)
      ? this.state.yearData
      : this.filterData({ data: this.state.yearData, month: e.target.value });
    this.setState({
      selectQuarter: false,
      subSelection: "all",
      statsData: currData,
      dateData: currData,
      people: this.setPeople(currData, this.state.selectBy),
      [e.target.name]: e.target.value,
    });
  };

  handleChangeQuarter = (e) => {
    let currData = this.didSelectAll(e)
      ? this.state.yearData
      : this.filterData({ data: this.state.yearData, quarter: e.target.value });
    this.setState({
      selectMonth: false,
      subSelection: "all",
      statsData: currData,
      dateData: currData,
      people: this.setPeople(currData, this.state.selectBy),
      [e.target.name]: e.target.value,
    });
  };

  handleChangeYear = (e) => {
    let currData = this.filterData({
      data: this.state.bulkData,
      year: e.target.value,
    });
    this.setState({
      selectMonth: "all",
      selectQuarter: "all",
      subSelection: "all",
      statsData: currData,
      yearData: currData,
      dateData: currData,
      people: this.setPeople(currData, this.state.selectBy),
      [e.target.name]: e.target.value,
    });
  };

  handleChange = (e) => {
    this.setState({
      subSelection: "all",
      people: this.setPeople(this.state.dateData, e.target.value),
      statsData: this.state.dateData,
      [e.target.name]: e.target.value,
    });
  };

  handleChangeSubSelection = (e) => {
    this.setState({
      statsData: this.didSelectAll(e)
        ? this.state.dateData
        : this.filterData({
            data: this.state.dateData,
            subSelection: e.target.value,
          }),
      [e.target.name]: e.target.value,
    });
  };

  filterFunction({ feature, item, value = "" }) {
    switch (feature) {
      case "urs":
        return item.offerDone === value;
      case "offers":
        return item.condition === value;
      case "ibills":
      case "bills":
        switch (value) {
          case 0:
            return (
              item.totalDue !== item.totalPayableAmount && item.totalDue !== 0
            );
          case 1:
            return item.totalDue === 0;
          case 2:
            return item.totalDue === item.totalPayableAmount;
        }
    }
  }

  render() {
    // console.log(this.state.statsData)
    const marketingMenuItem = (
      <MenuItem value={"marketing"}>Marketing</MenuItem>
    );
    const selectByForm = (
      <FormControl>
        <InputLabel style={{ paddingLeft: 8 }}>Select By For chart</InputLabel>
        <Select
          name="selectBy"
          value={this.state.selectBy}
          onChange={this.handleChange}
          style={styles.textFieldSelect}
        >
          <MenuItem value={"client"}>Client</MenuItem>
          <MenuItem value={"principal"}>Principal</MenuItem>
          {this.props.feature === "bills" ? null : marketingMenuItem}
        </Select>
      </FormControl>
    );

    const selectEntityForm = (
      <FormControl>
        <InputLabel style={{ paddingLeft: 8 }}>Select Entity</InputLabel>
        <Select
          name="subSelection"
          value={this.state.subSelection}
          onChange={this.handleChangeSubSelection}
          style={styles.textFieldSelect}
        >
          <MenuItem value={"all"}>All</MenuItem>
          {this.state.people.map((person) => (
            <MenuItem value={person}>{person}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );

    const selectQuarterForm = (
      <FormControl>
        <InputLabel style={{ paddingLeft: 8 }}>Select Quarter</InputLabel>
        <Select
          name="selectQuarter"
          value={this.state.selectQuarter}
          onChange={this.handleChangeQuarter}
          style={styles.textFieldSelect}
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"1"}>Q1</MenuItem>
          <MenuItem value={"2"}>Q2</MenuItem>
          <MenuItem value={"3"}>Q3</MenuItem>
          <MenuItem value={"4"}>Q4</MenuItem>
        </Select>
      </FormControl>
    );

    const selectMonthForm = (
      <FormControl>
        <InputLabel style={{ paddingLeft: 8 }}>Select Month</InputLabel>
        <Select
          name="selectMonth"
          value={this.state.selectMonth}
          onChange={this.handleChangeMonth}
          style={styles.textFieldSelect}
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"1"}>January</MenuItem>
          <MenuItem value={"2"}>February</MenuItem>
          <MenuItem value={"3"}>March</MenuItem>
          <MenuItem value={"4"}>April</MenuItem>
          <MenuItem value={"5"}>May</MenuItem>
          <MenuItem value={"6"}>June</MenuItem>
          <MenuItem value={"7"}>July</MenuItem>
          <MenuItem value={"8"}>August</MenuItem>
          <MenuItem value={"9"}>September</MenuItem>
          <MenuItem value={"10"}>October</MenuItem>
          <MenuItem value={"11"}>November</MenuItem>
          <MenuItem value={"12"}>December</MenuItem>
        </Select>
      </FormControl>
    );

    const selectYearForm = (
      <Box>
        <FormControl>
          <InputLabel style={{ paddingLeft: 8 }}>
            Select year of choice
          </InputLabel>
          <Select
            name="selectyear"
            value={this.state.selectyear}
            onChange={this.handleChangeYear}
            style={styles.textFieldSelect}
          >
            {this.state.years.map((year) => (
              <MenuItem value={year}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );

    const forms = (
      <form
        onSubmit={this.handleSubmit}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {selectYearForm}
        {selectQuarterForm}
        {selectMonthForm}
        {selectByForm}
        {selectEntityForm}
      </form>
    );

    const pieChart = (
      <PieChart
        feature={this.props.feature}
        subchart={this.state.selectBy}
        year={this.state.selectyear}
        quarter={this.state.selectQuarter}
        month={this.state.selectMonth}
        data={this.state.statsData}
      />
    );

    const barChart = (
      <LineChart
        feature={this.props.feature}
        year={this.state.selectyear}
        quarter={this.state.selectQuarter}
        month={this.state.selectMonth}
        data={this.state.statsData}
        //bar={true}
      />
    );

    const charts = (
      <div style={styles.charts}>
        {pieChart}
        {barChart}
      </div>
    );

    const table = (
      <Table
        feature={this.props.feature}
        data={this.state.statsData}
        isSales={this.state.isSales}
      />
    );
    let showTabs = false;
    let filterValues = null;
    let tabTitles = null;
    let tabColors = null;
    let searchCategories = null;
    switch (this.props.feature) {
      case "ecr":
        searchCategories = [
          "visitedBy",
          "client.clientName",
          "createdAtString",
        ];
        break;
      case "urs":
        searchCategories = [
          "principleName",
          "client.clientName",
          "CollectedBy.email",
        ];
        filterValues = [false, true];
        tabTitles = ["Active", "Closed"];
        tabColors = ["#37596D", "#252327"];
        showTabs = true;
        break;
      case "offers":
        searchCategories = [
          "principleName",
          "client.clientName",
          "sentBy.email",
        ];
        filterValues = ["Active", "Closed", "Lost"];
        tabTitles = ["Active", "Closed", "Lost"];
        tabColors = ["#37596D", "#252327", "#9E1322"];
        showTabs = true;
        break;
      case "services":
        searchCategories = ["principleName", "client.clientName", "visitedBy"];
        break;
      case "bills":
        searchCategories = ["totalDue", "client.clientName", "createdAtString"];
        filterValues = [2, 0, 1];
        tabTitles = ["Pending", "Partially Paid", "Paid"];
        tabColors = ["#9E1322", "#252327", "#37596D"];
        showTabs = true;
        break;
      case "ibills":
        searchCategories = ["principleName", "client.clientName", "currency"];
        filterValues = [2, 0, 1];
        tabTitles = ["Pending", "Partially Paid", "Paid"];
        tabColors = ["#9E1322", "#252327", "#37596D"];
        showTabs = true;
        break;
    }

    const tabs = showTabs ? (
      <Tabs
        data={this.state.statsData}
        feature={this.props.feature}
        filterFunction={this.filterFunction}
        filterValues={filterValues}
        tabTitles={tabTitles}
        tabColors={tabColors}
        isSales={this.state.isSales}
      />
    ) : null;
    const search = (
      <Search
        feature={this.props.feature}
        categories={searchCategories}
        onSearchClick={() => {
          this.setState({ canRender: false });
        }}
        onSearchResult={(data) => {
          this.setState({ statsData: data, canRender: true });
        }}
      />
    );

    const output = this.state.canRender ? (
      <div>
        <Paper style={styles.root}>
          <div>{this.state.isSales ? search : forms}</div>
          {this.state.isSales ? null : charts}
          <div>
            {this.props.feature !== "ecr" && this.props.feature !== "services"
              ? tabs
              : table}
          </div>
        </Paper>
      </div>
    ) : (
      <div>
        <Paper style={styles.root}>
          <div>{this.state.isSales ? search : forms}</div>
          <div>
            <CircularProgress width="500" />
          </div>
        </Paper>
      </div>
    );
    return output;
  }
}

const styles = {
  root: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
  },
  charts: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    overflow: "auto",
  },
  inputs: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 300,
  },
  button: {
    color: "white",
    margin: 8,
  },
  formControl: {
    display: "flex",
    margin: "8px",
  },
  textFieldSelect: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 16,
    marginBottom: 8,
    width: 300,
  },
};

export default StatView;
