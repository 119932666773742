import React, { Component } from "react";
import Dashboard from "../dashboardlayout/Dashboard";

export class DashView extends Component {
  render() {
    return (
      <div className="allViewContainers">
        <React.Fragment>
          <Dashboard
            admin={this.props.admin}
            procurement={this.props.procurement}
          />
        </React.Fragment>
      </div>
    );
  }
}

export default DashView;
