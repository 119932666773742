import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
//for visibility toggle of password
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import backgroundImage from './LoginScreenBack.png';
import logo from './Logo.png';







class ApprovalLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false
        };
    }

    handleClickShowPassword = () => {
        const newState = !this.state.showPassword
        this.setState({
            showPassword: newState
        })
    };

    render() {
        const { values, handleChange, handleSubmit } = this.props;

        let buttonState = false;
        if (values.email === "" || values.password === "") {
            buttonState = false;
        }
        else {
            buttonState = true;
        }


        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <form style={styles.container} onSubmit={handleSubmit}>
                        <React.Fragment>
                            <img src={logo} alt="Logo" />
                        </React.Fragment>
                        <br />
                        <React.Fragment>
                            <TextField
                                required
                                label="Email"
                                value={values.email}
                                type="email"
                                onChange={handleChange}
                                margin="normal"
                                name="email"
                                variant="outlined"
                                style={styles.textField}
                            />
                            <TextField
                                type={this.state.showPassword ? 'text' : 'password'}
                                required
                                label="Password"
                                value={values.password}
                                onChange={handleChange}
                                margin="normal"
                                name="password"
                                variant="outlined"
                                style={styles.textField}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </React.Fragment>

                        <React.Fragment>
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                                name="submit"
                                style={styles.button}
                                color="primary"
                                disabled={!buttonState}
                            >
                                LOG IN
                            </Button>
                        </React.Fragment>
                    
                    </form>
                </React.Fragment>
            </MuiThemeProvider>
        )
    }
}

const styles = {
    button: {
        margin: 8,
        width: 350,
    },
    textField: {
        marginLeft: 8,
        marginRight: 8,
        width: 350,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
    },
    noDecorate: {
        textDecoration: 'none',
    }
}


export default ApprovalLogin;
