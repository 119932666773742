import React, { Component } from "react";
//paper
import Paper from "@material-ui/core/Paper";
//For Radio Buttons
import FormControl from "@material-ui/core/FormControl";
//input label
import InputLabel from "@material-ui/core/InputLabel";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormLabel from "@material-ui/core/FormLabel";
// selects
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// textfields
import TextField from "@material-ui/core/TextField";
//typography
import Typography from "@material-ui/core/Typography";
//box
// import Box from "@material-ui/core/Box";
//Modal
// import Modals from "./ECRModal";
//table
import Itemtable from "./ViewPendingIntFormItemtable";
//Bill table
import TransactionPendingIntTable from "./TransactionPendingIntTable";
//axios import
import axios from "axios";
//css
import "./divStyle.css";
// import IconButton from "@material-ui/core/IconButton";
// import PhotoCamera from "@material-ui/icons/PhotoCamera";
// For Buttons
import Button from "@material-ui/core/Button";
//routing
import { NavLink } from "react-router-dom";
// import ReactToPrint from "react-to-print";
// import PrintIcon from "@material-ui/icons/Print";
export class PendingIntFormInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      client_list: [],
      branch_list: [],
      personnel_list: [],
      user_list: [],
      productName: "",
      productId: "",
      principleName: "",
      principleId: "",
      model: "",
      modelId: "",
      quantity: 1,
      price: "",
      productComment: "",
      width: "",
    };

    // this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    axios
      .get(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client"
      )
      .then((clients) => {
        this.setState({
          client_list: clients.data,
        });
        console.log(clients.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/user"
      )
      .then((clients) => {
        this.setState({
          user_list: clients.data,
        });
        console.log(clients.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.values.clientId !== this.props.values.clientId) {
      axios
        .get(
          `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${nextProps.values.clientId}/branch`
        )
        .then((branches) => {
          this.setState({
            branch_list: branches.data,
          });
          console.log(branches.data);
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get(
          `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${nextProps.values.clientId}/personnel`
        )
        .then((clients) => {
          this.setState({
            personnel_list: clients.data,
          });
          console.log(clients.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    const { values, handleChange, handleSubmit } = this.props;
    return (
      <div style={{ width: "100%" }}>
        {/* <ReactToPrint
          onBeforeGetContent={() =>
            this.setState({
              width: "100%"
            })
          }
          onAfterPrint={() => {
            this.setState({ width: "" });
          }}
          trigger={() => (
            <Button
              variant="contained"
              size="large"
              type="submit"
              name="submit"
              color="primary"
              style={{ color: "white", marginBottom: 8 }}
            >
              <PrintIcon />
            </Button>
          )}
          content={() => this.componentRef}
        /> */}
        <Paper
          className="root"
          elevation={1}
          style={{
            width: this.state.width,
            padding: this.state.width === "100%" ? 8 : "0px",
          }}
          // ref={el => (this.componentRef = el)}
        >
          <NavLink
            exact
            to="/pendingbillsint"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                margin: 8,
                display: this.state.width === "100%" ? "none" : "",
              }}
            >
              Go Back
            </Button>
          </NavLink>
          <form style={styles.container} onSubmit={handleSubmit}>
            <div style={styles.divStyle}>
              <Typography variant="p" style={styles.typographyvariant}>
                Client Information
              </Typography>
            </div>
            <div className="divstyle">
              <React.Fragment>
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: "8px" }}
                  >
                    Client Name
                  </InputLabel>
                  <Select
                    required
                    value={values.client}
                    onChange={handleChange}
                    name="client"
                    displayEmpty
                    style={styles.textFieldSelect}
                    inputProps={{ readOnly: true }}
                  >
                    <MenuItem value={values.client}>
                      {values.client.clientName}
                    </MenuItem>
                  </Select>
                </FormControl>
              </React.Fragment>

              <React.Fragment>
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: "8px" }}
                  >
                    Location
                  </InputLabel>
                  <Select
                    required
                    value={values.facility}
                    onChange={handleChange}
                    name="facility"
                    displayEmpty
                    style={styles.textFieldSelect}
                    inputProps={{ readOnly: true }}
                  >
                    <MenuItem value={values.facility}>
                      {values.facility.branchName}
                    </MenuItem>
                  </Select>
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    label="Address "
                    value={values.facilityAddress}
                    type="text"
                    onChange={handleChange}
                    name="facilityAddress"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    label="Facility "
                    value={values.newFacility}
                    type="text"
                    onChange={handleChange}
                    name="newFacility"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>

              <React.Fragment>
                <FormControl>
                  <TextField
                    label="Bill Date"
                    value={values.billIssueDate}
                    type={!values.billIssueDate === "" ? "date" : "text"}
                    onChange={handleChange}
                    name="billIssueDate"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    label="Payment Date "
                    value={values.billRecieveDate}
                    type={!values.billRecieveDate === "" ? "date" : "text"}
                    onChange={handleChange}
                    name="billRecieveDate"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    label="Purchase Order Number"
                    value={values.purchaseOrderNumber}
                    type="text"
                    onChange={handleChange}
                    name="purchaseOrderNumber"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    label="PO Date "
                    value={values.poDate}
                    type={!values.poDate === "" ? "date" : "text"}
                    onChange={handleChange}
                    name="poDate"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    label="Referrence Number "
                    value={values.referenceNumber}
                    type="text"
                    onChange={handleChange}
                    name="referenceNumber"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
            </div>
            <div style={styles.divStyle}>
              <Typography variant="p" style={styles.typographyvariant}>
                PRODUCT DETAILS
              </Typography>
            </div>
            <div className="divstyleNext">
              <React.Fragment>
                <Itemtable values={values.item} />
              </React.Fragment>
            </div>

            <div style={styles.divStyle}>
              {/* typo label */}
              <Typography variant="p" style={styles.typographyvariant}>
                PAYMENT INFORMATION
              </Typography>
            </div>
            <div className="divstyle">
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="currency"
                    value={values.currency}
                    type="text"
                    name="currency"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    label="Total Payable Amount"
                    value={values.totalPayableAmount}
                    type="number"
                    onChange={handleChange}
                    name="totalPayableAmount"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    label="Total Advance Amount"
                    value={values.totalAdvanceAmount}
                    type="number"
                    onChange={handleChange}
                    name="totalAdvanceAmount"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    label="Total Due Left"
                    value={values.totalDue}
                    type="number"
                    onChange={handleChange}
                    name="totalDue"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    label="Currency Rate"
                    value={values.currencyRate}
                    type="number"
                    onChange={handleChange}
                    name="currencyRate"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
            </div>

            <div style={styles.divStyle}>
              <Typography variant="p" style={styles.typographyvariant}>
                PENDING PAYMENT INFORMATION TABLE
              </Typography>
            </div>

            <div className="divstyle" style={{ width: "95%" }}>
              <FormControl fullWidth style={{ marginTop: "16px" }}>
                <TransactionPendingIntTable values={values.progress} />
              </FormControl>
            </div>

            <br />
          </form>
        </Paper>
      </div>
    );
  }
}
const styles = {
  button: {
    color: "white",
    marginBottom: "24px",
  },
  buttonvariant: {
    color: "white",
    margin: 8,
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 300,
  },
  textFieldSelect: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 16,
    marginBottom: 8,
    width: 300,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  formControl: {
    display: "flex",
    margin: "8px",
  },
  root: {
    // margin: "16px"
    // boxShadow:"none"
  },
  divStyle: {
    width: "100%",
    background: "rgba(224, 224, 224, 0.25)",
    color: "#757575",
    // opacity:"0.25",
    letterSpacing: "2px",
    height: "60px",
    display: "flex",
    alignItems: "center",
  },
  typographyvariant: {
    fontWeight: "700",
    fontSize: "12px",
    marginLeft: "20px",
    textTransform: "uppercase",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
};
export default PendingIntFormInputs;
