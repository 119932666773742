import React, { Component } from "react";
//text
import TextField from "@material-ui/core/TextField";
//Select
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
//button
import Button from "@material-ui/core/Button";
//BOX
import Box from "@material-ui/core/Box";
//utility
import utility from "../../layouts/utility/utility";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
export class TableSearchbar extends Component {
  static defaultProps = {
    sales: false,
    handleSearch: () => {
      console.log("Search Not Intergrated");
    },
    handleClear: () => {
      console.log("Handle Clear Not Found");
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      searchValuePending: "",
      searchValuePending2: "",
      searchValueDate: utility,
      searchValueDate2: utility,
      searchBy: "",
      labelWidth: 0,
      inputLabel: null,
      currency: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }
  currencies = ["$ USD", "€ EUR", "฿ BTC", "¥ JPY"];

  handleChange(e) {
    console.log(this.props.categories);
    this.setState({
      [e.target.name]: e.target.value
    });
    console.log(this.state);
  }

  static defaultProps = {
    categories: []
  };

  getAlias = alias => {
    if (alias === "visitedBy") {
      return "Visited By";
    }
    if (alias === "client.clientName") {
      return "Client Name";
    }
    if (alias === "createdAtString") {
      return "Date";
    }
    if (alias === "principleName") {
      return "Principle Name";
    }
    if (alias === "totalDue") {
      return "Pending Amount";
    }
    if (alias === "CollectedBy.fullname") {
      return "Collected By Name";
    }
    if (alias === "CollectedBy.email") {
      return "Collected By";
    }
    if (alias === "sentBy.fullname") {
      return "Sent By";
    }
    if (alias === "sentBy.email") {
      return "Sent By Mail";
    }
    if (alias === "currency") {
      return "Currency";
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    if (
      this.state.searchBy === "visitedBy" ||
      this.state.searchBy === "client.clientName" ||
      this.state.searchBy === "principleName" ||
      this.state.searchBy === "CollectedBy.fullname" ||
      this.state.searchBy === "CollectedBy.email" ||
      this.state.searchBy === "sentBy.email" ||
      this.state.searchBy === "sentBy.fullname"
    ) {
      this.props.handleSearch(this.state.searchValue, this.state.searchBy);
    }
    if (this.state.searchBy === "createdAtString") {
      const dates = {
        searchValueDate: this.state.searchValueDate,
        searchValueDate2: this.state.searchValueDate2
      };
      this.props.handleSearch(dates, this.state.searchBy);
    }
    if (this.state.searchBy === "totalDue") {
      const dates = {
        searchValueDate: this.state.searchValuePending,
        searchValueDate2: this.state.searchValuePending2
      };
      this.props.handleSearch(dates, this.state.searchBy);
    }
    if (this.state.searchBy === "currency") {
      this.props.handleSearch(this.state.currency, this.state.searchBy);
    }
  };

  handleClear = () => {
    this.setState({ searchValue: "", searchBy: "" });
    this.props.handleClear();
  };

  previousdateHandleChange = date => {
    this.setState({
      searchValueDate: `${date.getFullYear()}-${
        String(date.getMonth() + 1).length < 2
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`
      }-${
        String(date.getDate()).length < 2
          ? `0${date.getDate()}`
          : `${date.getDate()}`
      }`
    });
  };

  nextdateHandleChange = date => {
    this.setState({
      searchValueDate2: `${date.getFullYear()}-${
        String(date.getMonth() + 1).length < 2
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`
      }-${
        String(date.getDate()).length < 2
          ? `0${date.getDate()}`
          : `${date.getDate()}`
      }`
    });
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div style={{ width: "100%" }}>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap"
            }}
            onSubmit={this.handleSubmit}
          >
            <React.Fragment>
              <div style={{ display: "flex" }}>
                <Box
                  style={{ width: "100%" }}
                  display={
                    this.state.searchBy === "createdAtString" ||
                    this.state.searchBy === "currency" ||
                    this.state.searchBy === "totalDue"
                      ? "none"
                      : "block"
                  }
                >
                  <FormControl fullWidth>
                    <TextField
                      label={
                        this.state.searchBy === "sentBy.fullname" ||
                        this.state.searchBy === "CollectedBy.fullname"
                          ? "Write full name of the user..."
                          : this.state.searchBy === "visitedBy" || this.state.searchBy === "CollectedBy.email"
                          ? "Search By Email"
                          : "Search Here..."
                      }
                      value={this.state.searchValue}
                      type="text"
                      onChange={this.handleChange}
                      name="searchValue"
                      variant="outlined"
                      style={styles.textField}
                      fullWidth
                    />
                  </FormControl>
                </Box>
                <Box
                  style={{ width: "100%" }}
                  display={
                    this.state.searchBy === "currency" ? "block" : "none"
                  }
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="currency">Currency</InputLabel>
                    <Select
                      labelId="currency"
                      id="currency"
                      name="currency"
                      value={this.state.currency}
                      onChange={this.handleChange}
                      style={styles.textFieldSelect}
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {this.currencies.map(currency => {
                        return <MenuItem value={currency}>{currency}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Box>
                {/* Date */}
                <Box
                  display={
                    this.state.searchBy === "createdAtString" ? "block" : "none"
                  }
                >
                  {/* <FormControl>
                    <TextField
                      value={this.state.searchValueDate}
                      type="date"
                      onChange={this.handleChange}
                      name="searchValueDate"
                      variant="outlined"
                      style={styles.textField}
                    />
                  </FormControl> */}

                  <React.Fragment>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      //margin="normal"
                      id="searchValueDate"
                      name="searchValueDate"
                      label="Start Date"
                      value={this.state.searchValueDate}
                      style={styles.textField}
                      onChange={date => this.previousdateHandleChange(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      required={true}
                    />
                  </React.Fragment>
                </Box>
                <Box
                  display={
                    this.state.searchBy === "createdAtString" ? "block" : "none"
                  }
                >
                  {/* <FormControl>
                    <TextField
                      value={this.state.searchValueDate2}
                      type="date"
                      onChange={this.handleChange}
                      name="searchValueDate2"
                      variant="outlined"
                      style={styles.textField}
                    />
                  </FormControl> */}

                  <React.Fragment>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      //margin="normal"
                      id="searchValueDate2"
                      name="searchValueDate2"
                      label="End Date"
                      value={this.state.searchValueDate2}
                      style={styles.textField}
                      onChange={date => this.nextdateHandleChange(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      required={true}
                    />
                  </React.Fragment>
                </Box>
                {/* //Pending Bill */}
                <Box
                  display={
                    this.state.searchBy === "totalDue" ? "block" : "none"
                  }
                >
                  <FormControl>
                    <TextField
                      label="Select Starting Value"
                      value={this.state.searchValuePending}
                      type="text"
                      onChange={this.handleChange}
                      name="searchValuePending"
                      variant="outlined"
                      style={styles.textField}
                    />
                  </FormControl>
                </Box>
                <Box
                  display={
                    this.state.searchBy === "totalDue" ? "block" : "none"
                  }
                >
                  <FormControl>
                    <TextField
                      label="Select Ending Value"
                      value={this.state.searchValuePending2}
                      type="text"
                      onChange={this.handleChange}
                      name="searchValuePending2"
                      variant="outlined"
                      style={styles.textField}
                    />
                  </FormControl>
                </Box>
              </div>
            </React.Fragment>
            <React.Fragment>
              <Box>
                <FormControl variant="outlined">
                  <InputLabel id="searchBy">Search By</InputLabel>
                  <Select
                    labelId="searchBy"
                    id="searchBy"
                    name="searchBy"
                    value={this.state.searchBy}
                    onChange={this.handleChange}
                    style={styles.textFieldSelect}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {this.props.categories.map(category => (
                      <MenuItem value={category}>
                        {this.getAlias(category)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </React.Fragment>
            <React.Fragment>
              <div style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size={"large"}
                  style={{ margin: 8 }}
                  type="submit"
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size={"large"}
                  style={{ margin: 8 }}
                  onClick={this.handleClear}
                >
                  Clear
                </Button>
              </div>
            </React.Fragment>
          </form>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

const styles = {
  formControl: {
    display: "flex",
    margin: "8px"
  },
  textFieldSelect: {
    margin: 8,
    minWidth: 270
  },
  textField: {
    margin: 8,
    minWidth: 270
  }
};

export default TableSearchbar;
