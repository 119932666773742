import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import FullWidthTabs from "./ReportTabs";
//nav
import {
  withRouter
} from "react-router-dom";
export class ReportVIEW extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    };
  }

  render() {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} style={styles.tableContainer}>
          <FullWidthTabs />
        </Grid>
      </Grid>
    );
  }
}

const styles = {
  tableContainer: {
    overflowX: "auto"
  },
  CreateContainer: {
    display: "flex",
    padding: "8px",
    flexWrap: "wrap"
  }
};

export default withRouter(ReportVIEW);
