import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
// import Grid from "@material-ui/core/Grid";
// import Avatar from "@material-ui/core/Avatar";
import { grey } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import EditUser from "./EditUser";
import axios from "axios";
import Button from "@material-ui/core/Button";

const styles = {
  root: {
    margin: 0,
    padding: "16px"
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: grey
  },
  bigAvatar: {
    margin: 5,
    width: 70,
    height: 70
  },
  box: {
    display: "flex",
    alignItems: "center"
  }
};

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default class CustomizedDialogs extends Component {
  handleRemove = () => {
    axios
      .delete(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/user/${this.props.currentData._id}`
      )
      .then(result => {
        console.log(result);
        this.props.updateTable();
      })
      .catch(err => console.log(err));
  };
  render() {
    // const { name } = this.props.currentData || "";
    console.log(this.props);
    return (
      <div>
        <Dialog
          maxWidth="sm"
          onClose={this.props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          style={{margin: 0}}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.handleClose}
          >
            {/* {this.props.currentData.username} */}
            Update User
          </DialogTitle>
          <DialogContent dividers>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <EditUser
                currentData={this.props.currentData}
                updateTable={this.props.updateTable}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              //color="secondary"
              style={{
                margin: "8px",
                background: "#ef5350",
                color: "white"
              }}
              startIcon={<DeleteIcon />}
              onClick={this.handleRemove}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
