import React, { Component } from "react";
import ReportVIEW from "../../reports/ReportView";
import PrimarySearchAppBar from "../appbarComponent/Appbar";

export class ReportViewModel extends Component {
  render() {
    return (
      <div className="allViewContainersNew">
        <div>
          <PrimarySearchAppBar title="Reporting" />
        </div>
        <React.Fragment>
          <ReportVIEW />
        </React.Fragment>
      </div>
    );
  }
}

export default ReportViewModel;
