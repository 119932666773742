import React, { Component } from "react";
import PropTypes from "prop-types";
//withStyles
import { withStyles } from "@material-ui/core";
//router
import { withRouter } from "react-router-dom";
//pagination and footer
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
//redirect
import { Redirect } from "react-router-dom";
//table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//paper
import Paper from "@material-ui/core/Paper";
//icons
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/ArrowDropUp";
import KeyboardArrowLeft from "@material-ui/icons/ArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/ArrowRight";
import LastPageIcon from "@material-ui/icons/ArrowDropDown";
//searchbar
import firebase from "firebase";
//print
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import Button from "@material-ui/core/Button";
//Status Icon
//circle
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5
  }
});
//pagination prototype
class TablePaginationActions extends Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
              <KeyboardArrowLeft />
            )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
              <KeyboardArrowRight />
            )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}
TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
};
const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(TablePaginationActions);
//styles table
const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: "300px"
  },
  tableWrapper: {
    overflow: "auto",
    minWidth: "300px"
  },
  textDecore: {
    textDecoration: "none"
  }
});

class CustomPaginationActionsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: null,
      page: 0,
      rowsPerPage: 10,
      currentModalData: ""
    };
  }

  componentDidMount() {
    if (this.props.data.data) {
      this.setState({ rows: [...this.props.data.data] });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      console.log()
      if (nextProps.data.data) {
        this.setState({ rows: [...nextProps.data.data] });
      }
    }
  }

  //change the page
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  //change Rows per page
  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value) });
  };
  //take to another page
  handlePageChange = event => {
    this.props.history.push(this.props.url);
  };
  //open modal
  handleClickOpen = rowData => {
    this.setState({
      open: true
    });
  };
  //close modal
  handleClose = () => {
    this.setState({ open: false });
  };

  //view row data
  handleViewRow = rowData => {
    this.setState({
      currentModalData: rowData,
      redirect: true
    });
  };

  formatNumber(num, currency) {
    if (currency === undefined) {
      currency = "";
    }
    // console.log(num, currency);
    if (Number.isInteger(num)) {
      return currency + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      let floatVal = typeof num === "string" ? parseFloat(num.replace(/[^0-9.]/g, "")) : parseFloat(num);
      if (!isNaN(floatVal)) {
        return (
          currency + floatVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      }
      return "";
    }
  }

  getTableCell(value) {
    return (<TableCell align="left">{value}</TableCell>);
  }

  getDate(row) {
    if (this.props.feature === "offers") {
      return new Intl.DateTimeFormat('en-GB').format(row.offerCreatedAt._seconds
        ? new firebase.firestore.Timestamp(
          row.offerCreatedAt._seconds,
          row.offerCreatedAt._nanoseconds
        )
          .toDate()
        : new firebase.firestore.Timestamp(
          row.offerCreatedAt.seconds,
          row.offerCreatedAt.nanoseconds
        )
          .toDate()
      );
    }
    return new Intl.DateTimeFormat('en-GB').format(row.createdAt._seconds
      ? new firebase.firestore.Timestamp(
        row.createdAt._seconds,
        row.createdAt._nanoseconds
      )
        .toDate()
      : new firebase.firestore.Timestamp(
        row.createdAt.seconds,
        row.createdAt.nanoseconds
      )
        .toDate()
    );
  }

  getPrincipal(row) {
    return row.item.length > 0
      ? row.item[0].principleName.principalName
      : "No Principal Found";
  }

  getProduct(row) {
    return row.item.length > 0
      ? row.item[0].productName.productName
      : "No product Found";
  }

  getClientName(row) {
    return row.client.clientName;
  }

  getVisitPurpose(row) {
    return row.purposeOfVisit;
  }

  getVisitedBy(row) {
    return row.visitedBy;
  }

  getID(row) {
    return row.id;
  }

  getSubmissionDeadline(row) {
    return `${row.SubmissionDeadline.substring(8,10)}/${row.SubmissionDeadline.substring(5,7)}/${row.SubmissionDeadline.substring(0,4)}`;
  }

  getCollectedBy(row) {
    return row.CollectedBy.email;
  }

  getURSStatus(row) {
    return row.offerDone
      ? "Offer Received"
      : row.sentPC
        ? "Sent to Principal"
        : row.approval
          ? "URS Approved"
          : row.newURSC
            ? "New URS Created"
            : "No Status Found";
  }

  getCurreny(row) {
    return row.currency;
  }

  getURSCreator(row) {
    return row.ursCreator
  }

  getServiceCharge(row) {
    return row.item.length > 0
      ? this.formatNumber(
        row.item[0].serviceCharge,
        row.item[0].currency.substring(0, 1)
      )
      : "No Service Charge Found";
  }

  getTotalAmount(row) {
    return this.formatNumber(
      row.totalPayableAmount,
      row.currency.substring(0, 1)
    )
  }

  getPendingAmount(row) {
    return this.formatNumber(
      row.totalDue,
      row.currency.substring(0, 1)
    )
  }

  getBillReceiveDate(row) {
    return row.billRecieveDate;
  }

  getOfferSentBy(row) {
    return row.sentBy.email;
  }

  getOfferValue(row) {
    return this.formatNumber(row.principalCost, row.currency.substring(0, 1))
  }

  getBillStatus(row) {
    if (row.totalDue === 0) {
      return "Paid"
    } else if (row.totalDue === row.totalPayableAmount) {
      return "Pending"
    } else {
      return "Partially Paid"
    }
  }

  getStatusCell(row) {
    return this.getTableCell(
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <FiberManualRecordRoundedIcon
          // color="primary"
          style={{
            fontSize: '12px',
            color: `${row.hasOwnProperty('totalDue') ? this.getColorBillsStatus(this.getBillStatus(row)) : this.getColor(row.progress[row.progress.length - 1].status)}`
          }}
        />
        {row.hasOwnProperty('totalDue') ? this.getBillStatus(row) : row.progress[row.progress.length - 1].status}
      </div>)
  }

  getColor(status) {
    if (status === 'Offer Acknowleged' || status === 'Comparision Sheet' || status === 'Offer Price Revision') {
      return '#FFC107';
    } else if (status === 'HO for Finalization' || status === 'Expected Closing Date' || status === 'PO/PI/LC') {
      return '#8BC34A';
    } else if (status === 'At Risk' || status === 'Lost') {
      return '#E57373';
    } else {
      return '#375A6D';
    }
  }

  getColorBillsStatus(status) {
    switch (status) {
      case "Paid":
        return '#8BC34A';
      case "Partially Paid":
        return '#FFC107';
      case "Pending":
        return '#E57373';
    }
  }

  render() {
    if (this.state.redirect && this.props.isSales) {
      let pathName = "";
      //ECR
      switch (this.props.feature) {
        case "ecr":
          pathName = "/viewecr";
          break;
        case "urs":
          pathName = "/viewurs";
          break;
        case "offers":
          pathName = "/viewoffers";
          break;
        case "services":
          pathName = "/viewservices";
          break;
        case "bills":
          pathName = "/viewpendingbills";
          break;
        case "ibills":
          pathName = "/viewpendingbillsint";
          break;
      }
      return (
        <Redirect
          to={{
            pathname: pathName,
            state: this.state.currentModalData
          }}
        />
      );
    }
    if (this.state.rows) {
      //Helper Variables
      const { classes } = this.props;
      const { rows, rowsPerPage, page } = this.state;
      const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

      console.log(rows, rowsPerPage, page, emptyRows);

      //Table Components
      let tableHead = null;
      let tableBody = null;

      //Table Cells
      //ECR
      let tc_visit_date = this.getTableCell("Visit Date");
      let tc_principal = this.getTableCell("Principal");
      let tc_product = this.getTableCell("Product");
      let tc_client = this.getTableCell("Client");
      let tc_purpose = this.getTableCell("Purpose");
      let tc_visited_by = this.getTableCell("Visited By");
      let tc_id = this.getTableCell("ID");

      //URS
      let tc_entry_date = this.getTableCell("Entry Date");
      let tc_submission_deadline = this.getTableCell("Submission Deadline");
      let tc_collected_by = this.getTableCell("Collected By");
      let tc_status = this.getTableCell("Status");
      let tc_currency = this.getTableCell("Currency");
      let tc_created_by = this.getTableCell("Created By");

      //SERVICES
      let tc_service_date = this.getTableCell("Service Date");
      let tc_provided_by = this.getTableCell("Provided By");
      let tc_service_charge = this.getTableCell("Service Charge");

      //BILLS
      let tc_bill_date = this.getTableCell("Bill Date");
      let tc_total_amount = this.getTableCell("Total Amount");
      let tc_pending_amount = this.getTableCell("Pending Amount");
      let tc_bill_receive_date = this.getTableCell("Bill Recieved Date");

      //OFFERS
      let tc_sent_by = this.getTableCell("Sent By");
      let tc_offer_value = this.getTableCell("Offer Value");

      //Create Table Header and Body

      //ECR
      switch (this.props.feature) {
        case "ecr":
          tableHead = (<TableHead>
            <TableRow>
              {tc_visit_date}
              {tc_principal}
              {tc_product}
              {tc_client}
              {tc_purpose}
              {tc_visited_by}
              {tc_id}
            </TableRow>
          </TableHead>);

          tableBody = (
            <TableBody>
              {rows
                .slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map(row => (
                  <TableRow hover key={row._id} onClick={() => this.handleViewRow(row)}>
                    {this.getTableCell(this.getDate(row))}
                    {this.getTableCell(this.getPrincipal(row))}
                    {this.getTableCell(this.getProduct(row))}
                    {this.getTableCell(this.getClientName(row))}
                    {this.getTableCell(this.getVisitPurpose(row))}
                    {this.getTableCell(this.getVisitedBy(row))}
                    {this.getTableCell(this.getID(row))}
                  </TableRow>
                ))}
              {{ emptyRows } > 0 && (
                <TableRow style={{ height: 48 * { emptyRows } }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>);
          break;
        case "urs":
          tableHead = (<TableHead>
            <TableRow>
              {tc_entry_date}
              {tc_submission_deadline}
              {tc_principal}
              {tc_product}
              {tc_client}
              {tc_collected_by}
              {tc_status}
              {tc_currency}
              {tc_created_by}
              {tc_id}
            </TableRow>
          </TableHead>);

          tableBody = (
            <TableBody>
              {rows
                .slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map(row => (
                  <TableRow hover key={row._id} onClick={() => this.handleViewRow(row)}>
                    {this.getTableCell(this.getDate(row))}
                    {this.getTableCell(this.getSubmissionDeadline(row))}
                    {this.getTableCell(this.getPrincipal(row))}
                    {this.getTableCell(this.getProduct(row))}
                    {this.getTableCell(this.getClientName(row))}
                    {this.getTableCell(this.getCollectedBy(row))}
                    {this.getTableCell(this.getURSStatus(row))}
                    {this.getTableCell(this.getCurreny(row))}
                    {this.getTableCell(this.getURSCreator(row))}
                    {this.getTableCell(this.getID(row))}
                  </TableRow>
                ))}
              {{ emptyRows } > 0 && (
                <TableRow style={{ height: 48 * { emptyRows } }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>);
          break;
        case "offers":
          tableHead = (<TableHead>
            <TableRow>
              {tc_entry_date}
              {tc_principal}
              {tc_product}
              {tc_client}
              {tc_sent_by}
              {tc_offer_value}
              {tc_status}
              {tc_id}
            </TableRow>
          </TableHead>);

          tableBody = (
            <TableBody>
              {rows
                .slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map(row => (
                  <TableRow hover key={row._id} onClick={() => this.handleViewRow(row)}>
                    {this.getTableCell(this.getDate(row))}
                    {this.getTableCell(this.getPrincipal(row))}
                    {this.getTableCell(this.getProduct(row))}
                    {this.getTableCell(this.getClientName(row))}
                    {this.getTableCell(this.getOfferSentBy(row))}
                    {this.getTableCell(this.getOfferValue(row))}
                    {this.getStatusCell(row)}
                    {this.getTableCell(this.getID(row))}
                  </TableRow>
                ))}
              {{ emptyRows } > 0 && (
                <TableRow style={{ height: 48 * { emptyRows } }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>);
          break;
        case "services":
          tableHead = (<TableHead>
            <TableRow>
              {tc_service_date}
              {tc_principal}
              {tc_client}
              {tc_product}
              {tc_purpose}
              {tc_provided_by}
              {tc_service_charge}
              {tc_id}
            </TableRow>
          </TableHead>);

          tableBody = (
            <TableBody>
              {rows
                .slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map(row => (
                  <TableRow hover key={row._id} onClick={() => this.handleViewRow(row)}>
                    {this.getTableCell(this.getDate(row))}
                    {this.getTableCell(this.getPrincipal(row))}
                    {this.getTableCell(this.getClientName(row))}
                    {this.getTableCell(this.getProduct(row))}
                    {this.getTableCell(this.getVisitPurpose(row))}
                    {this.getTableCell(this.getVisitedBy(row))}
                    {this.getTableCell(this.getServiceCharge(row))}
                    {this.getTableCell(this.getID(row))}
                  </TableRow>
                ))}
              {{ emptyRows } > 0 && (
                <TableRow style={{ height: 48 * { emptyRows } }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>);
          break;
        case "ibills":
        case "bills":
          tableHead = (<TableHead>
            <TableRow>
              {tc_bill_date}
              {tc_status}
              {tc_client}
              {tc_principal}
              {tc_total_amount}
              {tc_pending_amount}
              {tc_bill_receive_date}
              {tc_currency}
              {tc_id}
            </TableRow>
          </TableHead>);

          tableBody = (
            <TableBody>
              {rows
                .slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map(row => (
                  <TableRow hover key={row._id} onClick={() => this.handleViewRow(row)}>
                    {this.getTableCell(this.getDate(row))}
                    {this.getStatusCell(row)}
                    {this.getTableCell(this.getClientName(row))}
                    {this.getTableCell(this.getPrincipal(row))}
                    {this.getTableCell(this.getTotalAmount(row))}
                    {this.getTableCell(this.getPendingAmount(row))}
                    {this.getTableCell(this.getBillReceiveDate(row))}
                    {this.getTableCell(this.getCurreny(row))}
                    {this.getTableCell(this.getID(row))}
                  </TableRow>
                ))}
              {{ emptyRows } > 0 && (
                <TableRow style={{ height: 48 * { emptyRows } }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>);
          break;
      }

      let tableFooter = (<TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={7}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              native: true
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActionsWrapped}
          />
        </TableRow>
      </TableFooter>);

      let table = (<Table className={classes.table} ref={el => (this.componentRef = el)}>
        {tableHead}
        {tableBody}
        {tableFooter}
      </Table>);

      let printButton = (<div style={{ display: "flex", justifyContent: "flex-end" }}>
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              size="large"
              type="submit"
              name="submit"
              color="primary"
              style={{ color: "white", marginBottom: 8 }}
            >
              <PrintIcon />
            </Button>
          )}
          content={() => this.componentRef}
        />
      </div>);

      let paper = (<Paper className={classes.root} elevation={0}>
        <div className={classes.tableWrapper}>
          <div style={{ minWidth: "300px", overflowX: "auto" }}>
            {printButton}
            {table}
          </div>
        </div>
      </Paper>);

      if (tableHead && tableBody) {
        return paper
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(CustomPaginationActionsTable));
