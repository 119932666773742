import React, { Component } from "react";
import URSForm from "./URSForm";
import PrimarySearchAppBar from "../../layouts/appbarComponent/Appbar";
import axios from "axios";
import { withRouter } from "react-router-dom";

//utility
import utility from "../../layouts/utility/utility";
import "./styles.css";

export class AddURS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      client: "",
      clientId: "",
      facility: "",
      facilityAddress: "",
      branchId: "",
      //dateOfSubmission: utility,
      SubmissionDeadline: utility,
      contactName: "",
      contactId: "",
      contactNo: "",
      email: "",
      designation: "",
      ursCreator: this.props.email,
      creatorDesig: this.props.designation,
      item: [],
      ursComment: "",
      visitorDesignation: "",
      newFacility: "",
      btnDisabled: false,
      CollectedBy: "",
      referenceNumber: "",
      currency: "",
      currencyRate: "",
      senderLoader: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePush = this.handlePush.bind(this);
    // this.getID = this.getID.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== undefined) {
      this.setState({ visitedBy: nextProps.email });
    }
  }
  handleChange(e) {
    if (e.target.name === "client") {
      this.setState({ client: e.target.value });
      this.setState({ clientId: e.target.value._id });
    } else if (e.target.name === "facility") {
      console.log(e.target.value);
      this.setState({ facility: e.target.value });
      this.setState({ branchId: e.target.value._id });
      this.setState({ facilityAddress: e.target.value.branchAddress });
    } else if (e.target.name === "contactName") {
      console.log(e.target.value);
      this.setState({ contactName: e.target.value });
      this.setState({ contactId: e.target.value._id });
      this.setState({ contactNo: e.target.value.personnelContactno });
      this.setState({ designation: e.target.value.personnelDesignation });
      this.setState({ email: e.target.value.personnelEmail });
    } else if (e.target.name === "principleName") {
      this.setState({ principleName: e.target.value });
      this.setState({ principleId: e.target.value._id });
    } else if (e.target.name === "productName") {
      this.setState({ productName: e.target.value });
      this.setState({ productId: e.target.value._id });
    } else if (e.target.name === "model") {
      this.setState({ model: e.target.value });
      this.setState({ modelId: e.target.value._id });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
    this.setState({ id: this.getID() });
    console.log(this.state);
    // console.log(this.props);
  }
  handlePush = data => {
    const newItem = [...this.state.item, data];
    this.setState({ item: newItem });
    console.log(this.state);
  };

  getID() {
    if (this.state.client !== "") {
      var idx = Math.round(+new Date() + Math.random() * 1000000);
      // console.log(this.state)
      // console.log(this.state.client)
      if (this.state.item.length > 0) {
        return (
          "PCON-URS-" +
          this.state.client.clientCode +
          "-" +
          this.state.item[0].principleName.principalCode +
          "-" +
          (idx % 10000)
        );
      } else {
        return (
          "PCON-URS-" + this.state.client.clientCode + "-NA-" + (idx % 10000)
        );
      }
    } else {
      return "";
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      btnDisabled: true
    });
    if (this.state.id !== "") {
      this.setState({
        senderLoader: true
      });
      axios
        .post(
          "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/urs",
          this.state
        )
        .then(results => {
          this.props.history.push("/urs");
        })
        .catch(err => {
          console.log(err);
          this.setState({
            btnDisabled: false
          });
        });
    }
  };

  handleRemoveItem = index => {
    console.log("Here");
    if (index > -1) {
      let newItem = this.state.item;
      newItem.splice(index, 1);
      console.log(newItem);
      this.setState({ item: newItem });
    }
  };

  nextdateHandleChange = date => {
    this.setState({
      SubmissionDeadline: `${date.getFullYear()}-${
        String(date.getMonth() + 1).length < 2
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`
      }-${
        String(date.getDate()).length < 2
          ? `0${date.getDate()}`
          : `${date.getDate()}`
      }`
    });
  };

  render() {
    const {
      client,
      clientId,
      facility,
      facilityAddress,
      branchId,
      //dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      ursCreator,
      item,
      ursComment,
      creatorDesig,
      SubmissionDeadline,
      newFacility,
      btnDisabled,
      CollectedBy,
      referenceNumber,
      currency,
      currencyRate,
      senderLoader
    } = this.state;
    const values = {
      client,
      clientId,
      facility,
      facilityAddress,
      branchId,
      //dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      ursCreator,
      item,
      ursComment,
      creatorDesig,
      SubmissionDeadline,
      newFacility,
      btnDisabled,
      CollectedBy,
      referenceNumber,
      currency,
      currencyRate,
      senderLoader
    };
    return (
      <div className="fullwidth">
        <React.Fragment>
          <PrimarySearchAppBar title="Add New User Requirement Specification" />
        </React.Fragment>
        <React.Fragment>
          <URSForm
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            handlePush={this.handlePush}
            values={values}
            handleRemoveItem={this.handleRemoveItem}
            nextdateHandleChange={this.nextdateHandleChange}
          />
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(AddURS);
