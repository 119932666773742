import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
//signin and signout
import { signOut } from "../../../controllers/login";
import logo from "./logo.png"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  grow: {
    flexShrink: 1
  }
}));

const handleLogout = () => {
  signOut()
    .then(function () {
      console.log("Signed out");
      window.location.reload();
      // Sign-out successful.
    })
    .catch(function (error) {
      console.log("Signed out with Error");
      // An error happened.
    });
};

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{ background: "#FAFAFA", color: "black" }}
        elevation={0}
      >
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {props.title}
          </Typography>
          <div className={classes.grow}>
            <img src={logo} alt="CloudSight" style={{ height: "50px", marginTop: "11px" }} />
          </div>
          <Tooltip title="Logout">
            <IconButton
              color="logout"
              onClick={() => {
                handleLogout();
              }}
            >
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}
