const dateNow = new Date();
const year = dateNow.getFullYear();
const month = (dateNow.getUTCMonth() + 1).toString().length < 2 ? `0${dateNow.getUTCMonth()+1}`: (dateNow.getUTCMonth()+1).toString();
const date =  dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}`: dateNow.getUTCDate().toString();
const defaultDate = `${year}-${month}-${date}`

export default defaultDate;



















