import React, { Component } from "react";
import SalesView from "../../reports/StatsView"
import PrimarySearchAppBar from "../appbarComponent/Appbar";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
// tooltip
import Tooltip from "@material-ui/core/Tooltip";
//nav
import { NavLink} from "react-router-dom";

export class newViewModel extends Component {
    render() {
        let title = "";
        let buttonTooltip = "Add ";
        let buttonText = "";
        let buttonRoute = "/addECR";
        switch (this.props.feature) {
            case "ecr":
                title = "Effective Call Report";
                buttonTooltip += "ECR";
                buttonText = "ECR";
                buttonRoute = "/addECR";
                break;
            case "urs":
                title = "User Requirement Specification";
                buttonTooltip += "URS";
                buttonText = "URS";
                buttonRoute = "/addURS";
                break;
            case "offers":
                title = "Offers";
                buttonTooltip += "Offer";
                buttonText = "Offer";
                buttonRoute = "/addOffer";
                break;
            case "services":
                title = "Service Reports";
                buttonTooltip += "Service Report";
                buttonText = "Service";
                buttonRoute = "/addService";
                break;
            case "bills":
                title = "Pending Bills";
                buttonTooltip += "Pending Bill";
                buttonText = "Bill";
                buttonRoute = "/addPendingbills";
                break;
            case "ibills":
                buttonTooltip += "Pending Bill (International)";
                title = "International Pending Bills";
                buttonText = "Bill";
                buttonRoute = "/addPendingbillsint";
                break;
            default:
                title = "";
                break;
        }
        return (
            <div className="allViewContainersNew">
                <div>
                    <PrimarySearchAppBar title={title} />
                </div>
                <React.Fragment>
                    <SalesView feature={this.props.feature} sales={true} />
                </React.Fragment>
                <NavLink
                    className="linker"
                    to={{
                        pathname: buttonRoute,
                        state: {
                            submitted: false
                        }
                    }}
                >
                    <Tooltip title={buttonTooltip} aria-label="add">
                        <Fab
                            variant="extended"
                            aria-label="like"
                            style={{
                                color: "white",
                                background: "#375A6D",
                                marginTop: "20px"
                            }}
                        >
                            <AddIcon />
                            {buttonText}
                        </Fab>
                    </Tooltip>
                </NavLink>
            </div>
        );
    }
}

export default newViewModel;
