import React, { Component } from "react";
// import Avatar from "@material-ui/core/Avatar";
import InputBase from "@material-ui/core/InputBase";
import GetAppIcon from "@material-ui/icons/GetApp";
// import Divider from "@material-ui/core/Divider";
export class BaseComponent extends Component {
  render() {
    console.log(this.props.data);
    return (
      <div style={styles.logs}>
        <div style={{ width: "90%" }}>
          <InputBase
            readOnly
            value={this.props.data.paymentDetails}
            name="LogName"
          />
          <p style={{ color: "#9E9E9E", margin: 0, fontSize: "12px" }}>
            {this.props.data.amount || this.props.data.currency !== undefined
              ? `Paid: ${this.props.data.currency}${this.props.data.amount}`
              : ""}
            {this.props.data.totalDue !== undefined
              ? ` | Pending: ${this.props.data.totalDue} | `
              : ""}
            {this.props.data.upload !== undefined ? (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                href={this.props.data.upload}
                //target="_blank"
                style={{
                  textDecoration: "none",
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                Bill Document <GetAppIcon />
              </a>
            ) : (
              ""
            )}
            {/* {this.props.data.confirmation ||
            this.props.data.wonComment !== undefined
              ? `Confirmation: ${this.props.data.confirmation}  Commet:${this.props.data.wonComment}`
              : ""}
            {this.props.data.newDate !== undefined
              ? `New Date: ${this.props.data.newDate}`
              : ""}
            {this.props.data.reason ||
            this.props.data.competitorName ||
            this.props.data.competitorPrice ||
            this.props.data.comment !== undefined
              ? `Lost for ${this.props.data.reason} to ${this.props.data.competitorName} at price ${this.props.data.competitorPrice}`
              : ""} */}
          </p>
          {/* Comment: ${this.props.data.comment} */}
        </div>
        <InputBase
          style={{ color: "#BDBDBD" }}
          readOnly
          value={this.props.data.date}
          name="logdate"
        />
      </div>
    );
  }
}

const styles = {
  avatar: {
    width: "30px",
    height: "30px",
    marginRight: "8px"
  },
  logs: {
    marginTop: 8,
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    borderBottom: "2px solid #f7f7f7"
  }
};

export default BaseComponent;
