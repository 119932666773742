import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
//For Text Fields
import TextField from "@material-ui/core/TextField";
//paper
import Paper from "@material-ui/core/Paper";
//For Buttons
import Button from "@material-ui/core/Button";
//typography
import Typography from "@material-ui/core/Typography";

class PersonnelForm extends Component {
  render() {
    const { values, handleChange, handleSubmit } = this.props;
    return (
      <MuiThemeProvider>
        <React.Fragment>
          <Paper style={styles.root} elevation={this.props.elevate}>
            <form style={styles.container} onSubmit={handleSubmit}>
              <React.Fragment>
                <Typography variant="h5">Add New Personnel</Typography>
              </React.Fragment>
              <React.Fragment>
                <TextField
                  label="Personnel Name"
                  value={values.personnelName}
                  type="text"
                  onChange={handleChange}
                  margin="normal"
                  name="personnelName"
                  style={styles.textField}
                  required
                />
                <TextField
                  label="Personnel Designation"
                  value={values.personnelDesignation}
                  type="text"
                  onChange={handleChange}
                  margin="normal"
                  name="personnelDesignation"
                  style={styles.textField}
                  required
                />
                <TextField
                  label="Personnel Email"
                  value={values.personnelEmail}
                  type="email"
                  onChange={handleChange}
                  margin="normal"
                  name="personnelEmail"
                  style={styles.textField}
                  required
                />
                <TextField
                  label="Personnel Contact"
                  value={values.personnelContactno}
                  type="number"
                  onChange={handleChange}
                  margin="normal"
                  name="personnelContactno"
                  style={styles.textField}
                  required
                />
              </React.Fragment>
              <React.Fragment>
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  name="submit"
                  style={styles.button}
                >
                  {this.props.submit}
                </Button>
              </React.Fragment>
            </form>
          </Paper>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

const styles = {
  button: {
    margin: 8,
    minWidth: 270,
    background: "#8BC34A",
    color: "white"
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    minWidth: 270
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    width: "100%"
  },
  formControl: {
    display: "flex",
    flexDirection: "column",
    minWidth: 270
  },
  root: {
    padding: "24px 16px",
    display: "flex",
    flexWrap: "wrap",
    marginTop: "-68px"
  }
};

export default PersonnelForm;
