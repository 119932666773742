import React, { Component } from "react";
import { NavLink } from "react-router-dom";
//line divider
import Divider from "@material-ui/core/Divider";
//card
import Cards from "../dashboardCards/Cards";
//icons
// import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
// import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
// import RestoreOutlinedIcon from "@material-ui/icons/RestoreOutlined";
// import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
// import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
//Appbar
import PrimarySearchAppBar from "../appbarComponent/Appbar";
import ecr from "../../imgs/ECR.svg"
import urs from "../../imgs/URS.svg"
import offers from "../../imgs/Offers.svg"
import services from "../../imgs/Services.svg"
import pendingbills from "../../imgs/Bills_Taka.svg"
import pendingbillsI from "../../imgs/Bill_International.svg"
//Accordian
// import Accordian from "../infoblock/Accordian";
// import Grid from "@material-ui/core/Grid";
// import { width } from "@material-ui/system";
export class Dashboard extends Component {
  render() {
    return (
      <div style={styles.container}>
        <React.Fragment>
          <PrimarySearchAppBar title="ELO - CloudSight" />
        </React.Fragment>
        <div style={styles.innerComponent}>
          <div style={styles.dashboardCards}>
            <div style={styles.titleTextAlinement}>
              <p>Click on any module to execute your preferred action.</p>
              <Divider />
            </div>
            <div className="Cardrows">
              {this.props.admin || this.props.procurement ? (
                <React.Fragment>
                  <NavLink to="ecr" style={styles.linkStyle}>
                    <Cards name={ecr} desc="ECR" />
                  </NavLink>
                  <NavLink to="urs" style={styles.linkStyle}>
                    <Cards name={urs} desc="URS" />
                  </NavLink>
                  <NavLink to="offers" style={styles.linkStyle}>
                    <Cards name={offers} desc="Offers" />
                  </NavLink>
                  <NavLink to="services" style={styles.linkStyle}>
                    <Cards name={services} desc="Services" />
                  </NavLink>
                  <NavLink to="pendingbills" style={styles.linkStyle}>
                    <Cards
                      name={pendingbills}
                      desc="Pending Bills Local"
                    />
                  </NavLink>

                  {this.props.admin ? (
                    <NavLink to="pendingbillsint" style={styles.linkStyle}>
                      <Cards
                        name={pendingbillsI}
                        desc="Pending Bills International"
                      />
                    </NavLink>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <NavLink to="ecr" style={styles.linkStyle}>
                    <Cards name={ecr} desc="ECR" />
                  </NavLink>
                  <NavLink to="offers" style={styles.linkStyle}>
                    <Cards name={offers} desc="Offers" />
                  </NavLink>
                  <NavLink to="services" style={styles.linkStyle}>
                    <Cards name={services} desc="services" />
                  </NavLink>
                  {/* <NavLink to="pendingbills" style={styles.linkStyle}>
                    <Cards
                      name={<RestoreOutlinedIcon />}
                      desc="Pending Bills"
                    />
                  </NavLink> */}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const styles = {
  linkStyle: {
    textDecoration: "none",
    color: "black",
  },
  containerComponent: {
    display: "flex",
    flexDirection: "column",
  },
  dashboardCards: {
    fontSize: "16px",
    width: "100%", //
  },
  infoSegment: {
    display: "flex",
    flexDirection: "column",
  },
  titleTextAlinement: {
    paddingLeft: "24px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%", //
  },
  innerComponent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
};

export default Dashboard;
