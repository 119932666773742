import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import Avatar from "@material-ui/core/Avatar";
import { grey } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import EditPrincipal from "./EditPrincipal";
import axios from "axios";
import Button from "@material-ui/core/Button";
const styles = {
  root: {
    margin: 0,
    padding: "16px"
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: grey
  },
  bigAvatar: {
    margin: 5,
    width: 70,
    height: 70
  },
  box: {
    display: "flex",
    alignItems: "center"
  }
};

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export default class CustomizedDialogs extends Component {
  handleRemove = () => {
    axios
      .delete(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/principal/${this.props.currentData._id}`
      )
      .then(result => {
        console.log(result);
        this.props.updateTable();
      })
      .catch(err => console.log(err));
  };
  render() {
  //  const { name } = this.props.currentData || "";
    console.log(this.props);
    return (
      <div>
        <Dialog
          maxWidth="sm"
          onClose={this.props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.handleClose}
          >
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={9}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid style={styles.box}>
                  {/* <Avatar
                    alt={name}
                    src={
                      "http://www.gettystewart.com/wp-content/uploads/2014/08/carrots-wet-board-sq.jpg"
                    }
                    style={styles.bigAvatar}
                  />
                  <IconButton
                    aria-label="delete"
                    style={{
                      margin: "8px"
                    }}
                    onClick={this.handleRemove}
                  >
                    <DeleteIcon />
                  </IconButton> */}
                  <p style={{ fontSize: "24px", fontWeight: "500" }}>
                    {this.props.currentData.principalName}
                  </p>
                  <Button
                    //color="secondary"
                    style={{
                      margin: "8px",
                      background: "#ef5350",
                      color: "white"
                    }}
                    startIcon={<DeleteIcon />}
                    onClick={this.handleRemove}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <EditPrincipal
                currentData={this.props.currentData}
                updateTable={this.props.updateTable}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
