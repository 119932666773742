import React, { Component } from "react";
import ClientForm from "./ClientForm";
import axios from "axios";
import { withRouter } from "react-router-dom";

export class CreateClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientName: "",
      clientCode: "",
      clientCountry: "",
      btnDisabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    // console.log(this.state);
  };
  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      btnDisabled: true
    });
    axios
      .post(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client",
        this.state
      )
      .then(results => {
        // console.log(results);
        const emptyState = {
          clientName: "",
          clientCode: "",
          clientCountry: "",
          btnDisabled: false
        };
        this.setState(emptyState);
        this.props.result();
      })
      .catch(err => {
        // console.log(err);
        this.setState({
          btnDisabled: false
        });
      });
  }

  render() {
    const { clientName, clientCode, clientCountry, btnDisabled } = this.state;
    const values = { clientName, clientCode, clientCountry, btnDisabled };
    return (
      <div style={styles.fullwidth}>
        <div style={{ width: "100%" }}>
          <React.Fragment>
            <ClientForm
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              values={values}
              submit="Add Client"
              elevate={1}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}
const styles = {
  fullwidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around"
  }
};

export default withRouter(CreateClient);
