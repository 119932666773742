import React, { Component } from 'react'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import logo from './Logo.png';
import { Link } from 'react-router-dom';

export class ForgotPassowrd extends Component {




    render() {
        const { values, handleChange, handleSubmit} = this.props;

        let buttonState = false;
        if (values.email === "") {
            buttonState = false;
        }
        else {
            buttonState = true;
        }


        return (
            <MuiThemeProvider>
                <React.Fragment>
                    <form style={styles.container} onSubmit={handleSubmit}>
                        <React.Fragment>
                            <img src={logo} alt="Logo" />
                        </React.Fragment>
                        <br />
                        <React.Fragment>
                            <TextField
                                required
                                label="Email"
                                value={values.email}
                                type="email"
                                onChange={handleChange}
                                margin="normal"
                                name="email"
                                variant="outlined"
                                style={styles.textField}
                            />
                         
                        </React.Fragment>

                        <React.Fragment>
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                                name="submit"
                                style={styles.button}
                                color="primary"
                                disabled={!buttonState}
                            >
                              Send Verification Code
                            </Button>
                            <React.Fragment>
                                <Link to="/" variant="body2" style={styles.noDecorate}>
                                    <Button
                                        variant="text"
                                        size="large"
                                        type="button"
                                        name="backToLogin"
                                        style={styles.button}
                                        color="secondary"
                                    >
                                        Back to Login
                            </Button>
                                </Link>
                            </React.Fragment>
                        </React.Fragment>
            

                    </form>
                </React.Fragment>
            </MuiThemeProvider>
        )
    }
}

const styles = {
    button: {
        margin: 8,
        width: 350,
    },
    textField: {
        marginLeft: 8,
        marginRight: 8,
        width: 350,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage: "url(/images/loginScreenBack.png)",
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
    },
    noDecorate: {
        textDecoration: 'none'
    }
}

export default ForgotPassowrd
