import React, { Component } from "react";
import ModelTable from "./ModelTable";
// import CreateModel from "../clients/CreateModel";

export class ModelView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModel: false
    };
  }

  modelAdded = () => {
    this.setState({ addModel: true });
    console.log("Added");
  };

  modelAddDone = () => {
    this.setState({ addModel: false });
    console.log("Done");
  };
  render() {
    return (
      <div style={styles.root}>
        <div style={styles.tableContainer}>
          <ModelTable
            url="/#"
            addedData={this.state.addModel}
            done={this.modelAddDone}
            result={this.modelAdded}
            currentData={this.props.currentData}
            getData={
              `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/product/${this.props.currentData._id}/model`
            }
          />
          {/* <CreateModel result={this.ModelAdded} /> */}
        </div>
      </div>
    );
  }
}

const styles = {
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  tableContainer: {
    minWidth: "100%"
  }
};

export default ModelView;
