import React, { Component } from "react";
import OfferForm from "./OfferForm";
import PrimarySearchAppBar from "../../layouts/appbarComponent/Appbar";
import axios from "axios";
import { withRouter } from "react-router-dom";
import "./Style.css";
//ReferrenceURSOffer
//import ReferrenceURSOffer from "../OfferRightColumn/ReferrenceURSOffer";
import CreateNewOfferRight from "../OfferRightColumn/CreateNewOfferRight";
import { Grid } from "@material-ui/core";
export class AddOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: "",
      clientId: "",
      contactName: "",
      contactId: "",
      contactNo: "",
      email: "",
      designation: "",
      // visitedBy: this.props.email,
      item: [],
      ursComment: "",
      currency: "",
      currencyRate: "",
      principalCost: "",
      // clientOffer: "",
      finalQuotate: "",
      facilityAddress: "",
      branchAddress: "",
      facility: "",
      newfacility: "",
      ursID: "",
      id: "",
      btnDisabled: false,
      sentBy: this.props.email,
      referenceNumber: "",
      senderLoader: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePush = this.handlePush.bind(this);
    this.updateForm = this.updateForm.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== undefined) {
      this.setState({ sentBy: nextProps.email });
    }
  }
  handleChange(e) {
    if (e.target.name === "client") {
      this.setState({ client: e.target.value });
      this.setState({ clientId: e.target.value._id });
    } else if (e.target.name === "facility") {
      console.log(e.target.value);
      this.setState({ facility: e.target.value });
      this.setState({ branchId: e.target.value._id });
      this.setState({ facilityAddress: e.target.value.branchAddress });
    } else if (e.target.name === "contactName") {
      console.log(e.target.value);
      this.setState({ contactName: e.target.value });
      this.setState({ contactId: e.target.value._id });
      this.setState({ contactNo: e.target.value.personnelContactno });
      this.setState({ designation: e.target.value.personnelDesignation });
      this.setState({ email: e.target.value.personnelEmail });
    } else if (e.target.name === "principleName") {
      this.setState({ principleName: e.target.value });
      this.setState({ principleId: e.target.value._id });
    } else if (e.target.name === "productName") {
      this.setState({ productName: e.target.value });
      this.setState({ productId: e.target.value._id });
    } else if (e.target.name === "model") {
      this.setState({ model: e.target.value });
      this.setState({ modelId: e.target.value._id });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
    this.setState({ id: this.getID() });
    console.log(this.state);
    // console.log(this.props);
  }
  handlePush = data => {
    const newItem = [...this.state.item, data];
    this.setState({ item: newItem });
    console.log(this.state);
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      client,
      clientId,
      facility,
      facilityAddress,
      newfacility,
      dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      visitedBy,
      item,
      ursComment,
      visitorDesignation,
      currency,
      currencyRate,
      principalCost,
      finalQuotate,
      ursID,
      id,
      btnDisabled,
      referenceNumber
    } = this.state;
    const values = {
      client,
      clientId,
      facility,
      facilityAddress,
      newfacility,
      dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      visitedBy,
      item,
      ursComment,
      visitorDesignation,
      currency,
      currencyRate,
      principalCost,
      finalQuotate,
      ursID,
      id,
      btnDisabled,
      sentBy: { email: this.state.sentBy },
      referenceNumber
    };
    this.setState({
      btnDisabled: true
    });
    this.setState({
      senderLoader: true
    });
    axios
      .post(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/offer",
        values
      )
      .then(results => {
        this.props.history.push("/offers");
      })
      .catch(err => {
        console.log(err);
        this.setState({
          btnDisabled: false
        });
      });
  };
  handleRemoveItem = index => {
    console.log("Here");
    if (index > -1) {
      let newItem = this.state.item;
      newItem.splice(index, 1);
      console.log(newItem);
      this.setState({ item: newItem });
    }
  };

  updateForm() {
    axios
      .get(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/urs/${this.state.ursID}`
      )
      .then(res =>
        this.setState({ ...res.data }, () =>
          this.setState({ id: this.getID() })
        )
      )
      .catch(err => console.log(err));
  }

  handleSelectURS = id => {
    this.setState({ ursID: id }, () => {
      this.updateForm();
    });
  };

  getID() {
    if (this.state.client !== "") {
      var idx = Math.round(+new Date() + Math.random() * 1000000);
      // console.log(this.state)
      // console.log(this.state.client)
      if (this.state.item.length > 0) {
        return (
          "PCON-OFF-" +
          this.state.client.clientCode +
          "-" +
          this.state.item[0].principleName.principalCode +
          "-" +
          (idx % 10000)
        );
      } else {
        return (
          "PCON-OFF-" + this.state.client.clientCode + "-NA-" + (idx % 10000)
        );
      }
    } else {
      return "";
    }
  }

  render() {
    const {
      client,
      clientId,
      facility,
      facilityAddress,
      newfacility,
      dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      visitedBy,
      item,
      ursComment,
      visitorDesignation,
      currency,
      currencyRate,
      principalCost,
      finalQuotate,
      btnDisabled,
      sentBy,
      referenceNumber,
      senderLoader
    } = this.state;
    const values = {
      client,
      clientId,
      facility,
      facilityAddress,
      newfacility,
      dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      visitedBy,
      item,
      ursComment,
      visitorDesignation,
      currency,
      currencyRate,
      principalCost,
      finalQuotate,
      btnDisabled,
      sentBy,
      referenceNumber,
      senderLoader
    };
    return (
      <div className="fullwidth">
        <React.Fragment>
          <PrimarySearchAppBar title="Create New Offer" />
        </React.Fragment>
        <div className="containerr">
          <Grid item xs={12} sm={10} md={8}>
            <OfferForm
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              handlePush={this.handlePush}
              values={values}
              handleRemoveItem={this.handleRemoveItem}
            />
          </Grid>

          {/* <ReferrenceURSOffer /> */}
          <Grid item xs={12} sm={10} md={3}>
            <CreateNewOfferRight handleSelectURS={this.handleSelectURS} />
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(AddOffer);
