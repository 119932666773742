import React, { Component } from "react";
import Newlogin from "./Newlogin";
// import { signIn } from "../../controllers/login";
import { user } from "../../controllers/utilities";
import CustomizedSnackbars from "../snackbars/CustomizedSnackbars";
import { withRouter, Redirect } from "react-router-dom";

export class NewloginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      lastAction: "",
      result: false,
      submit: false
    };
  }

  checkUser = () => {
    if (user.currentUser != null) {
      const { email } = user.currentUser;
      console.log(email);
    } else {
      console.log("noUser to show");
    }
  };

  componentWillReceiveProps(nextProps){
    this.setState({
      submit:nextProps.submit,
      result:nextProps.result
    })
  }

  // logout = () => {
  //     signOut().then(() => {
  //         this.setState(() => {
  //             return {
  //                 errorCode: null,
  //                 errorMessage: null,
  //                 loggedIn: false,
  //                 lastAction: 'logOut'
  //             }
  //         })
  //     })
  //         .catch((error) => {
  //             let errorCode = error.code;
  //             let errorMessage = error.message;
  //             this.setState(() => {
  //                 return {
  //                     errorCode: errorCode,
  //                     errorMessage: errorMessage,
  //                     lastAction: 'logOut'
  //                 }
  //             })
  //         }).then(() => console.log(this.state, user.currentUser))
  // }

  //handle changes of inputs
  handleChange = e => {
    this.setState({ submit: false });
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  //handle Submit of form
  handleSubmit = e => {
    e.preventDefault();
    // signIn(this.state.email, this.state.password)
    //   .then(() => {
    //     if (user.currentUser != null) {
    //       let {
    //         name,
    //         email,
    //         phoneNumber,
    //         photoURL,
    //         refreshToken,
    //         uid
    //       } = user.currentUser;
    //       this.setState(() => {
    //         return {
    //           name,
    //           email,
    //           loggedIn: true,
    //           phoneNumber,
    //           photoURL,
    //           refreshToken,
    //           uid,
    //           errorCode: null,
    //           errorMessage: null,
    //           lastAction: "loginSubmit"
    //         };
    //       });
    //       this.setState({ result: true, submit: true });
    //       console.log('User Found')
    //     }
    //   })
    //   .catch(error => {
    //     let errorCode = error.code;
    //     let errorMessage = error.message;
    //     this.setState(() => {
    //       return {
    //         errorCode: errorCode,
    //         errorMessage: errorMessage,
    //         lastAction: "loginSubmit"
    //       };
    //     });
    //     this.setState({ result: false, submit: true });
    //   })
    //   .then(() => console.log(this.state));

    this.props.loginChecker(this.state.email,this.state.password);
    this.props.history.push('/sales')
  };

  render() {
    const { email, password } = this.state;
    const values = { email, password };
    this.checkUser();

    return (
      <>
        <div style={styles.fullwidth}>
          {this.state.result ? (
            <div>
            <CustomizedSnackbars
              type="success"
              message="Logged In"
              show={this.state.submit}
            />
            <Redirect to="/sales" />
           </div>
          ) : (
            <CustomizedSnackbars
              type="error"
              message="Log in Falied"
              show={this.state.submit}
            />
          )}

          <Newlogin
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            forgotPassword={this.passwordReset}
            values={values}
          />
        </div>
      </>
    );
  }
}
const styles = {
    fullwidth:{
        width:'100%',
    }
};

export default withRouter(NewloginComponent);
