import React, { Component } from "react";
import ClientView from "../../clients/ClientView";
import PrimarySearchAppBar from "../appbarComponent/Appbar";

export class ClientViewModel extends Component {
  render() {
    return (
      <div className="allViewContainersNew">
        <div>
          <PrimarySearchAppBar title="Add New Client" />
        </div>
        <React.Fragment>
          <ClientView />
        </React.Fragment>
      </div>
    );
  }
}


export default ClientViewModel;
