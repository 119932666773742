import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
//For Text Fields
import TextField from "@material-ui/core/TextField";
// //Form Control
// import FormControl from "@material-ui/core/FormControl";
// //Select
// import { Select } from "@material-ui/core";
// //MenuItem
// import MenuItem from "@material-ui/core/MenuItem";
//paper
import Paper from "@material-ui/core/Paper";
//For Buttons
import Button from "@material-ui/core/Button";
// import axios from "axios";
//for visibility toggle of password

class ProductEditForm extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state={
  //     productName:this.props.currentData.productName,

  //   }
  //   console.log(this.props.currentData._id)
  // }
  // componentWillReceiveProps(nextProps){
  //   this.setState({productName:nextProps.currentData.productName})

  // }


  // handleSubmit = (e) =>{
  //   e.preventDefault();
  //   axios.put( `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/product/${this.props.currentData._id}`,this.state)
  //   .then(result => {
  //     console.log(result);
  //     this.props.updateTable();
  //   })
  //   .catch(err => console.log(err));

  // }
  // handleChange= (e) =>{

  //   this.setState({[e.target.name]:e.target.value})

  // }

  

  render() {
    return (
      <MuiThemeProvider>
        <React.Fragment>
          <Paper style={styles.root} elevation={this.props.elevate}>
            <form style={styles.container} onSubmit={this.props.handleSubmit}>
              <React.Fragment>
                <TextField
                  label="product Name"
                  value={this.props.values.productName}
                  type="text"
                  onChange={this.props.handleChange}
                  margin="normal"
                  name="productName"
                  style={styles.textField}
                />
              </React.Fragment>

              <React.Fragment>
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  name="submit"
                  style={styles.button}
                >
                  Submit
                </Button>
              </React.Fragment>
            </form>
          </Paper>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

const styles = {
  button: {
    margin: 8,
    minWidth: 270,
    background: "#8BC34A",
    color: "white"
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    minWidth: 270
  },
  textFieldSelect: {
    minWidth: 270
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    width: "100%"
  },
  formControl: {
    marginTop: "16px",
    marginBottom: " 8px",
    minWidth: 270
  },
  root: {
    padding: "24px 16px",
    display: "flex",
    flexWrap: "wrap"
  }
};

export default ProductEditForm;
