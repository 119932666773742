import React, { Component } from "react";
//paper
import Paper from "@material-ui/core/Paper";
//For Radio Buttons
import FormControl from "@material-ui/core/FormControl";
//input label
import InputLabel from "@material-ui/core/InputLabel";
// selects
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// textfields
import TextField from "@material-ui/core/TextField";
// For Buttons
import Button from "@material-ui/core/Button";
//typography
import Typography from "@material-ui/core/Typography";
//Modal
import URSModal from "./URSModal";
//table
import URSitemtable from "./URSitemtable";
//axios import
import axios from "axios";
//css
import "./styles.css";
import Loader from "../../layouts/commonComponents/Loader";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
export class URSForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      client_list: [],
      branch_list: [],
      personnel_list: [],
      user_list: [],
      productName: "",
      productId: "",
      principleName: "",
      principleId: "",
      model: "",
      modelId: "",
      quantity: 1,
      productComment: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  currencies = ["$ USD", "€ EUR", "฿ BTC", "¥ JPY", "৳ BDT"];

  componentWillMount() {
    axios
      .get(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client"
      )
      .then((clients) => {
        this.setState({
          client_list: clients.data,
        });
        console.log(clients.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/user"
      )
      .then((clients) => {
        this.setState({
          user_list: clients.data,
        });
        console.log(clients.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.values.clientId !== this.props.values.clientId) {
      axios
        .get(
          `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${nextProps.values.clientId}/branch`
        )
        .then((branches) => {
          this.setState({
            branch_list: branches.data,
          });
          console.log(branches.data);
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get(
          `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${nextProps.values.clientId}/personnel`
        )
        .then((clients) => {
          this.setState({
            personnel_list: clients.data,
          });
          console.log(clients.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  handleChange(e) {
    if (e.target.name === "principleName") {
      this.setState({ principleName: e.target.value });
      this.setState({ principleId: e.target.value._id });
    } else if (e.target.name === "productName") {
      this.setState({ productName: e.target.value });
      this.setState({ productId: e.target.value._id });
    } else if (e.target.name === "model") {
      this.setState({ model: e.target.value });
      this.setState({ modelId: e.target.value._id });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    console.log(this.state);
  }
  handleClickOpen = (rowData) => {
    this.setState({
      open: true,
      currentModalData: rowData,
    });
  };
  handleClose = () => {
    this.setState({
      productName: "",
      productId: "",
      principleName: "",
      principleId: "",
      model: "",
      modelId: "",
      quantity: 1,
      productComment: "",
      open: false,
    });
  };

  handleItemSubmit = (e) => {
    e.preventDefault();
    console.log("here");
    const obj = {
      productName:
        this.state.productName === ""
          ? { productName: "" }
          : this.state.productName,
      productId: this.state.productId,
      principleName:
        this.state.principleName === ""
          ? { principleName: "" }
          : this.state.principleName,
      principleId: this.state.principleId,
      model: this.state.model === "" ? { ModelName: "" } : this.state.model,
      modelId: this.state.modelId,
      quantity: this.state.quantity,
      productComment: this.state.productComment,
    };
    this.props.handlePush(obj);
    this.setState({
      productName: "",
      productId: "",
      principleName: "",
      principleId: "",
      model: "",
      modelId: "",
      quantity: 1,
      productComment: "",
      open: false,
    });
  };

  render() {
    const {
      values,
      handleChange,
      handleSubmit,
      nextdateHandleChange,
    } = this.props;

    const {
      productName,
      productId,
      principleName,
      principleId,
      model,
      modelId,
      quantity,
      productComment,
    } = this.state;
    const value = {
      productName,
      productId,
      principleName,
      principleId,
      model,
      modelId,
      quantity,
      productComment,
    };

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
          {values.senderLoader === true ? (
            <div className="senderLoader">
              <Loader />
            </div>
          ) : (
            <Paper className="root" elevation={1}>
              <form style={styles.container} onSubmit={handleSubmit}>
                <div style={styles.divStyle}>
                  <Typography variant="p" style={styles.typographyvariant}>
                    Client Information
                  </Typography>
                </div>
                <div className="divstyle">
                  <React.Fragment>
                    <FormControl required>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ marginLeft: "8px" }}
                      >
                        Client Name
                      </InputLabel>
                      <Select
                        required
                        value={values.client}
                        onChange={handleChange}
                        name="client"
                        displayEmpty
                        style={styles.textFieldSelect}
                      >
                        {this.state.client_list.map((client) => (
                          <MenuItem value={client}>
                            {client.clientName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </React.Fragment>
                  <React.Fragment>
                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ marginLeft: "8px" }}
                      >
                        Location
                      </InputLabel>
                      <Select
                        required
                        value={values.facility}
                        onChange={handleChange}
                        name="facility"
                        displayEmpty
                        style={styles.textFieldSelect}
                      >
                        {this.state.branch_list.map((branch) => (
                          <MenuItem value={branch}>
                            {branch.branchName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </React.Fragment>
                  <React.Fragment>
                    <FormControl>
                      <TextField
                        required
                        label="Location Address "
                        value={values.facilityAddress}
                        type="text"
                        onChange={handleChange}
                        name="facilityAddress"
                        style={styles.textField}
                      />
                    </FormControl>
                  </React.Fragment>

                  <React.Fragment>
                    <KeyboardDatePicker
                      //inputVariant="outlined"
                      format="dd/MM/yyyy"
                      //margin="normal"
                      id="SubmissionDeadline"
                      name="SubmissionDeadline"
                      label="Submission Deadline"
                      value={values.SubmissionDeadline}
                      style={styles.textField}
                      onChange={(date) => nextdateHandleChange(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      required={true}
                    />
                  </React.Fragment>

                  <React.Fragment>
                    <FormControl>
                      <TextField
                        required
                        label="Facility "
                        value={values.newFacility}
                        type="text"
                        onChange={handleChange}
                        name="newFacility"
                        style={styles.textField}
                      />
                    </FormControl>
                  </React.Fragment>
                  <React.Fragment>
                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ marginLeft: "8px" }}
                      >
                        Contact Name
                      </InputLabel>
                      <Select
                        required
                        value={values.contactName}
                        onChange={handleChange}
                        name="contactName"
                        displayEmpty
                        style={styles.textFieldSelect}
                      >
                        {this.state.personnel_list.map((personnel) => (
                          <MenuItem value={personnel}>
                            {personnel.personnelName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </React.Fragment>
                  <React.Fragment>
                    <FormControl>
                      <TextField
                        required
                        label="Contact no"
                        value={values.contactNo}
                        type="number"
                        onChange={handleChange}
                        name="contactNo"
                        style={styles.textField}
                      />
                    </FormControl>
                  </React.Fragment>
                  <React.Fragment>
                    <FormControl>
                      <TextField
                        required
                        label="Email"
                        value={values.email}
                        type="email"
                        onChange={handleChange}
                        name="email"
                        style={styles.textField}
                      />
                    </FormControl>
                  </React.Fragment>
                  <React.Fragment>
                    <FormControl>
                      <TextField
                        required
                        label="Designation"
                        value={values.designation}
                        type="text"
                        onChange={handleChange}
                        name="designation"
                        style={styles.textField}
                      />
                    </FormControl>
                  </React.Fragment>
                  <React.Fragment>
                    <FormControl>
                      <TextField
                        label="Reference Number "
                        value={values.referenceNumber}
                        type="text"
                        onChange={handleChange}
                        name="referenceNumber"
                        style={styles.textField}
                      />
                    </FormControl>
                  </React.Fragment>
                </div>
                <div style={styles.divStyle}>
                  <Typography variant="p" style={styles.typographyvariant}>
                    product and principal Information
                  </Typography>
                </div>
                <div className="divstyleNext">
                  <React.Fragment>
                    <Button
                      variant="contained"
                      size="small"
                      type="button"
                      name="submit"
                      color="primary"
                      style={styles.buttonvariant}
                      onClick={this.handleClickOpen}
                    >
                      + Add New Item
                    </Button>
                    <URSModal
                      handleChange={this.handleChange}
                      values={value}
                      open={this.state.open}
                      handleClose={this.handleClose}
                      handleItemSubmit={this.handleItemSubmit}
                    />
                  </React.Fragment>
                  <React.Fragment>
                    <URSitemtable
                      values={values.item}
                      handleRemoveItem={this.props.handleRemoveItem}
                    />
                  </React.Fragment>
                  <React.Fragment>
                    <FormControl fullWidth style={{ marginTop: "16px" }}>
                      <TextField
                        label="URS Comment"
                        value={values.ursComment}
                        type="text"
                        onChange={handleChange}
                        name="ursComment"
                        fullWidth
                        multiline
                      />
                    </FormControl>
                  </React.Fragment>
                </div>
                <div style={styles.divStyle}>
                  <Typography variant="p" style={styles.typographyvariant}>
                    Collected By
                  </Typography>
                </div>
                <div className="divstyle">
                  <React.Fragment>
                    <FormControl>
                      <TextField
                        required
                        label="Creator Mail"
                        value={values.ursCreator}
                        type="text"
                        disabled
                        name="ursCreator"
                        style={styles.textField}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        required
                        label="Creator Designation"
                        value={values.creatorDesig}
                        type="text"
                        disabled
                        name="creatorDesig"
                        style={styles.textField}
                      />
                    </FormControl>
                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ marginLeft: "8px" }}
                      >
                        Collected By
                      </InputLabel>
                      <Select
                        required
                        value={values.CollectedBy}
                        onChange={handleChange}
                        name="CollectedBy"
                        displayEmpty
                        style={{
                          marginTop: 16,
                          marginBottom: 8,
                          width: 300,
                        }}
                      >
                        {this.state.user_list.map((user) => (
                          <MenuItem value={user}>{user.email}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ marginLeft: "8px" }}
                      >
                        Currency
                      </InputLabel>
                      <Select
                        required
                        value={values.currency}
                        onChange={handleChange}
                        name="currency"
                        displayEmpty
                        style={{
                          marginTop: 16,
                          marginBottom: 8,
                          width: 300,
                        }}
                      >
                        {this.currencies.map((currency) => {
                          return (
                            <MenuItem value={currency}>{currency}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <TextField
                        required
                        label="Currency Rate"
                        value={values.currencyRate}
                        onChange={handleChange}
                        type="number"
                        name="currencyRate"
                        style={styles.textField}
                      />
                    </FormControl>
                  </React.Fragment>
                </div>
                <br />
                {/* button */}
                <div
                  style={{
                    padding: "8px",
                    width: "80%",
                    display: "flex",
                    alignSelf: "center",
                  }}
                >
                  <React.Fragment>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      name="submit"
                      color="secondary"
                      style={styles.button}
                      fullWidth
                      disabled={
                        values.client === "" || values.item.length === 0
                      }
                    >
                      Add URS
                    </Button>
                  </React.Fragment>
                </div>
              </form>
            </Paper>
          )}
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}
const styles = {
  root: {
    // margin: "16px"
    // boxShadow:"none"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  divStyle: {
    width: "100%",
    background: "rgba(224, 224, 224, 0.25)",
    color: "#757575",
    letterSpacing: "2px",
    height: "60px",
    display: "flex",
    alignItems: "center",
  },
  typographyvariant: {
    fontWeight: "700",
    fontSize: "12px",
    marginLeft: "20px",
    textTransform: "uppercase",
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 300,
  },
  textField2: {
    marginLeft: 8,
    marginRight: 8,
    width: 300,
    marginTop: 16,
  },
  formControl: {
    display: "flex",
    margin: "8px",
  },
  textFieldSelect: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 16,
    marginBottom: 8,
    width: 300,
  },
  button: {
    color: "white",
    marginBottom: "24px",
  },
  buttonvariant: {
    color: "white",
    margin: 8,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
};
export default URSForm;
