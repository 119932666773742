import React, { Component } from "react";
import axios from "axios";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import BaseComponent from "./BaseComponent";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import firebase from "firebase";
import "./styles.css";
// import ReactToPrint from "react-to-print";
// import PrintIcon from "@material-ui/icons/Print";
class ImgMediaCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newURSC: this.props.values.newURSC,
      approval: this.props.values.approval,
      sentPC: this.props.values.sentPC,
      offerDone: this.props.values.offerDone,
      finalQuotate: this.props.values.finalQuotate,
      createdAt: this.props.values.createdAt,
      approvalDate: this.props.values.approvalDate,
      sentPCDate: this.props.values.sentPCDate,
      finalDate: this.props.values.finalDate,
      width: this.props.initwidth
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleApprove = () => {
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/urs/${this.props.values._id}/approval`
      )
      .then(result => {
        console.log(result);
        this.props.updateTable();
        this.setState({ approval: true });
      })
      .catch(err => console.log(err));
  };
  handleOffer = () => {
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/urs/${this.props.values._id}/senttoprincipal`
      )
      .then(result => {
        console.log(result);
        this.props.updateTable();
        this.setState({ sentPC: true });
      })
      .catch(err => console.log(err));
  };

  handleFinalize = () => {
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/urs/${this.props.values._id}/finalize`,
        this.state
      )
      .then(result => {
        console.log(result);
        this.props.updateTable();
        this.setState({ offerDone: true });
      })
      .catch(err => console.log(err));
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.values.createdAt) {
      this.setState({ createdAt: nextProps.values.createdAt });
    }
    if (nextProps.values.approvalDate) {
      this.setState({ approvalDate: nextProps.values.approvalDate });
    }
    if (nextProps.values.sentPCDate) {
      this.setState({ sentPCDate: nextProps.values.sentPCDate });
    }
    if (nextProps.values.finalDate) {
      this.setState({ finalDate: nextProps.values.finalDate });
    }
    if (nextProps.values.finalQuotate) {
      this.setState({ finalQuotate: nextProps.values.finalQuotate });
    }
    if (this.props.initwidth !== nextProps.initwidth) {
      this.setState({
        width: nextProps.initwidth
      });
    }
  }

  render() {
    const { handleRemove, handleEditClick } = this.props;
    // console.log(values);v
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Card
          className="card"
          style={styles.divStyle}
          ref={el => (this.componentRef = el)}
        >
          <CardActionArea>
            <CardContent>
              <Typography variant="h5" style={styles.typographyvariant}>
                URS ID: {this.props.values.id}
              </Typography>
              <Typography variant="p" style={styles.typographyvariant}>
                Created On:{" "}
                {this.state.createdAt !== undefined
                  ? new firebase.firestore.Timestamp(
                      this.state.createdAt._seconds,
                      this.state.createdAt._nanoseconds
                    )
                      .toDate()
                      .toString()
                      .substring(0, 15)
                  : "Not Available"}
              </Typography>
              <div style={{ marginTop: 24 }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={styles.typographyvariant}
                >
                  PROGRESS LOG
                </Typography>
              </div>
              <React.Fragment>
                <BaseComponent
                  approved={this.state.newURSC}
                  valuedata={"New URS Created"}
                  date={this.state.createdAt}
                />
                <BaseComponent
                  approved={this.state.approval}
                  valuedata={"URS Approved"}
                  date={this.state.approvalDate}
                />
                <BaseComponent
                  approved={this.state.sentPC}
                  valuedata={"Sent To Principal"}
                  date={this.state.sentPCDate}
                />
                <BaseComponent
                  approved={this.state.offerDone}
                  valuedata={"Offer Recieved"}
                  date={this.state.finalDate}
                />
              </React.Fragment>
              {!this.state.approval && this.props.admin ? (
                <React.Fragment>
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    fullWidth
                    className="buttonssss"
                    onClick={this.handleApprove}
                  >
                    APPROVE URS
                  </Button>
                </React.Fragment>
              ) : (
                ""
              )}
              {!this.state.approval && !this.props.admin ? (
                <React.Fragment>
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    fullWidth
                    className="buttonssss"
                  >
                    Awaiting Response
                  </Button>
                </React.Fragment>
              ) : (
                ""
              )}
              {this.state.approval && !this.state.sentPC ? (
                <React.Fragment>
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    fullWidth
                    className="buttonssss"
                    onClick={this.handleOffer}
                  >
                    QUOTATION REQUESTED
                  </Button>
                </React.Fragment>
              ) : (
                ""
              )}

              {this.state.approval &&
              this.state.sentPC &&
              !this.state.offerDone ? (
                <React.Fragment>
                  <React.Fragment>
                    <FormControl fullWidth>
                      <TextField
                        required
                        label="Offer recieved"
                        type="number"
                        onChange={this.handleChange}
                        name="finalQuotate"
                        style={styles.textField}
                      />
                    </FormControl>
                  </React.Fragment>
                  <React.Fragment>
                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      fullWidth
                      className="buttonssss"
                      onClick={this.handleFinalize}
                    >
                      OFFER RECIEVED
                    </Button>
                  </React.Fragment>
                </React.Fragment>
              ) : (
                ""
              )}
              {this.state.newURSC &&
              this.state.approval &&
              this.state.sentPC &&
              this.state.offerDone ? (
                <React.Fragment>
                  <React.Fragment>
                    <FormControl fullWidth>
                      <TextField
                        required
                        label="offer recieved"
                        type="number"
                        value={this.state.finalQuotate}
                        //onChange={this.handleChange}
                        name="finalQuotate"
                        style={styles.textField}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </FormControl>
                  </React.Fragment>
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    fullWidth
                    className="buttonssss"
                  >
                    Draft Client Offer
                  </Button>
                </React.Fragment>
              ) : (
                ""
              )}
            </CardContent>
          </CardActionArea>
          {this.props.admin || this.props.procurement ? (
            <CardActions style={{ padding: 16 }}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={handleRemove}
                disabled={!this.props.admin && !this.props.procurement}
                style={{
                margin: 8,
                display: this.state.width === "100%" ? "none" : ""
              }}
              >
                delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={handleEditClick}
                disabled={!this.props.admin && !this.props.procurement}
                style={{
                margin: 8,
                display: this.state.width === "100%" ? "none" : ""
              }}
              >
                edit
              </Button>
            </CardActions>
          ) : (
            ""
          )}
        </Card>
      </div>
    );
  }
}

const styles = {
  typographyvariant: {
    fontWeight: "700",
    fontSize: "12px",
    marginLeft: "20px",
    //    textTransform: "uppercase",
    display: "flex",
    justifyContent: "center"
  },
  divStyle: {
    borderTop: "5px solid #161319",
    color: "#757575",
    letterSpacing: "2px",
    display: "flex",
    flexDirection: "column",
    height: "fit-content"
  },
  textField: {
    marginTop: 8,
    marginBottom: 8
  }
};
export default ImgMediaCard;
