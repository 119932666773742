import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import InputLabel from "@material-ui/core/InputLabel";
// import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
//For Radio Buttons
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//Basecomponent
import BaseComponent from "./ViewOfferRightSideBaseComponent";
import ExpectedClosingDateModal from "./ExpectedClosingDateModal";
import OfferLostModal from "./OfferLostModal";
import OfferRecieveModal from "./OfferRecievedModal";
import OfferWonModal from "./OfferWonModal";
import firebase from "firebase";
import OfferAtRisk from "./OfferAtRisk";
import date from "../../layouts/utility/utility";
import axios from "axios";
// import FormData from 'form-data';
// import fbConfig from '../../../config/firebaseConfig'

var storage = firebase.storage();
var storageRef = storage.ref();

export class ViewOfferDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "Offer Acknowledged",
      offerRecive: false,
      closingDate: false,
      currency: "$ USD",
      cost: "",
      date: date,
      offerLost: false,
      reason: "",
      competitorName: "",
      competitorPrice: "",
      comment: "",
      riskComment: "",
      risk: false,
      wonModal: false,
      confirmation: "",
      wonComment: "",
      image: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRevisionSubmit = this.handleRevisionSubmit.bind(this);
    this.handleClosingSubmit = this.handleClosingSubmit.bind(this);
    this.handleLostSubmit = this.handleLostSubmit.bind(this);
    this.handleWonSubmit = this.handleWonSubmit.bind(this);
    this.handleRiskSubmit = this.handleRiskSubmit.bind(this);
  }

  handleSubmit() {
    if (this.state.status === "Offer Price Revision") {
      this.setState({ offerRecive: true });
    } else if (this.state.status === "Expected Closing Date") {
      this.setState({ closingDate: true });
    } else if (this.state.status === "Lost") {
      this.setState({ offerLost: true });
    } else if (this.state.status === "PO/PI/LC") {
      this.setState({ wonModal: true });
    } else if (this.state.status === "At Risk") {
      this.setState({ risk: true });
    } else {
      const newProgress = [
        ...this.props.values.progress,
        { status: this.state.status, date: date }
      ];
      axios
        .put(
          `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/offer/${this.props.values._id}/update`,
          { progress: newProgress }
        )
        .then(res => {
          
          this.props.updateTable();
        })
        .catch(err => 
          console.log(err))
    }
  }

  handleRevisionSubmit() {
    const newProgress = [
      ...this.props.values.progress,
      {
        status: this.state.status,
        date: date,
        currency: this.state.currency,
        cost: this.state.cost
      }
    ];
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/offer/${this.props.values._id}/update`,
        { progress: newProgress }
      )
      .then(res => {
        
        this.setState({ offerRecive: false });
        this.props.updateTable();
      })
      .catch(err => 
        console.log(err))
  }

  handleClosingSubmit() {
    const newProgress = [
      ...this.props.values.progress,
      { status: this.state.status, date: date, newDate: this.state.date }
    ];
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/offer/${this.props.values._id}/update`,
        { progress: newProgress }
      )
      .then(res => {
        
        this.setState({ closingDate: false });
        this.props.updateTable();
      })
      .catch(err => 
        console.log(err))
  }

  handleLostSubmit() {
    const newProgress = [
      ...this.props.values.progress,
      {
        status: this.state.status,
        date: date,
        reason: this.state.reason,
        competitorName: this.state.competitorName,
        competitorPrice: this.state.competitorPrice,
        comment: this.state.comment
      }
    ];
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/offer/${this.props.values._id}/update`,
        { progress: newProgress }
      )
      .then(res => {
        
        this.setState({ offerLost: false });
        this.props.updateTable();
      })
      .catch(err =>
        console.log(err)) 
  }
  handleWonSubmit() {
    // Old Working Below
    if(this.state.image !== null){
      
    var uploadTask = storageRef
      .child(`docs/${new Date().toJSON()}_${this.state.image.name}`)
      .put(this.state.image);
    uploadTask.then(dataReturned => {
      dataReturned.ref.getDownloadURL().then(url => {
        const newProgress = [
          ...this.props.values.progress,
          {
            status: this.state.status,
            date: date,
            confirmation: this.state.confirmation,
            wonComment: this.state.wonComment,
            file: url
          }
        ];
        axios
          .put(
            `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/offer/${this.props.values._id}/update`,
            { progress: newProgress }
          )
          .then(res => {
            
            this.setState({ wonModal: false });
            this.props.updateTable();
          })
          .catch(err => 
            console.log(err))
      });
    });
  }
  else{
    const newProgress = [
      ...this.props.values.progress,
      {
        status: this.state.status,
        date: date,
        confirmation: this.state.confirmation,
        wonComment: this.state.wonComment,
      }
    ];
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/offer/${this.props.values._id}/update`,
        { progress: newProgress }
      )
      .then(res => {
        
        this.setState({ wonModal: false });
        this.props.updateTable();
      })
      .catch(err => 
        console.log(err))
  }
  }

  handleRiskSubmit() {
    const newProgress = [
      ...this.props.values.progress,
      {
        status: this.state.status,
        date: date,
        riskComment: this.state.riskComment
      }
    ];
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/offer/${this.props.values._id}/update`,
        { progress: newProgress }
      )
      .then(res => {
        
        this.setState({ risk: false });
        this.props.updateTable();
      })
      .catch(err => 
        console.log(err))
  }

  handleClose = () => {
    this.setState({
      offerRecive: false,
      closingDate: false,
      offerLost: false,
      wonModal: false,
      risk: false
    });
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () =>
      console.log(this.state)
    );
  }

  handleFile = e => {
    this.setState({
      image: e.target.files[0]
    });
  };
  render() {
    const { values, handleRemove, handleEditClick } = this.props;
    
    
    return (
      <div style={styles.container}>
        <React.Fragment>
          <Paper style={styles.root} square={true}>
            <div>
              <Typography
                variant="h5"
                component="h3"
                style={styles.typographyHeader}
              >
                {values.id}
              </Typography>
              <Typography variant="p" style={styles.typographyvariant}>
                Created On:
                {this.props.values.offerCreatedAt !== undefined
                  ? new firebase.firestore.Timestamp(
                      this.props.values.offerCreatedAt._seconds,
                      this.props.values.offerCreatedAt._nanoseconds
                    )
                      .toDate()
                      .toString()
                      .substring(0, 15)
                  : "Not Available"}
              </Typography>
              <Typography
                variant="p"
                component="p"
                style={{ color: "#BDBDBD" }}
              >
                {this.props.values.condition} Reference USR :
                {this.props.values.ursID !== undefined
                  ? this.props.values.ursID
                  : "No URS provided"}
              </Typography>
            </div>
          </Paper>
          <Paper square={true} style={{ padding: 8 }}>
            {this.props.admin || this.props.procurement ? (
              <div>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={handleRemove}
                  disabled={!this.props.admin}
                >
                  delete
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleEditClick}
                  disabled={!this.props.admin}
                  style={{ marginLeft: 8 }}
                >
                  edit
                </Button>
              </div>
            ) : (
              ""
            )}
          </Paper>
        </React.Fragment>
        <React.Fragment>
          <Paper style={styles.typographyroot} square={true}>
            <Typography style={styles.typograghies}>PROGRESS LOG</Typography>
          </Paper>
        </React.Fragment>
        <React.Fragment>
          <OfferRecieveModal
            open={this.state.offerRecive}
            handleClose={this.handleClose}
            values={{ cost: this.state.cost, currency: this.state.currency }}
            handleChange={this.handleChange}
            handleSubmit={this.handleRevisionSubmit}
          />
        </React.Fragment>
        <React.Fragment>
          <ExpectedClosingDateModal
            open={this.state.closingDate}
            handleClose={this.handleClose}
            values={{ date: this.state.date }}
            handleChange={this.handleChange}
            handleSubmit={this.handleClosingSubmit}
          />
        </React.Fragment>
        <React.Fragment>
          <OfferLostModal
            open={this.state.offerLost}
            handleClose={this.handleClose}
            values={{
              reason: this.state.reason,
              competitorName: this.state.competitorName,
              competitorPrice: this.state.competitorPrice,
              comment: this.state.comment
            }}
            handleChange={this.handleChange}
            handleSubmit={this.handleLostSubmit}
          />
        </React.Fragment>
        <React.Fragment>
          <OfferWonModal
            open={this.state.wonModal}
            handleClose={this.handleClose}
            values={{
              confirmation: this.state.confirmation,
              wonComment: this.state.wonComment
            }}
            handleChange={this.handleChange}
            handleSubmit={this.handleWonSubmit}
            handleFile={this.handleFile}
          />
        </React.Fragment>
        <React.Fragment>
          <OfferAtRisk
            open={this.state.risk}
            handleClose={this.handleClose}
            values={{ riskComment: this.state.riskComment }}
            handleChange={this.handleChange}
            handleSubmit={this.handleRiskSubmit}
          />
        </React.Fragment>
        <React.Fragment>
          <Paper style={styles.secondaryroot} square={true}>
            {values.progress.map(log => (
              <BaseComponent data={log} />
            ))}
          </Paper>
        </React.Fragment>
        <React.Fragment>
          <Paper style={styles.typographyroot} square={true}>
            <Typography style={styles.typograghies}>STATUS UPDATE</Typography>
          </Paper>
        </React.Fragment>
        <React.Fragment>
          <Paper style={styles.secondaryrootNew} square={true}>
            <div style={styles.extras}>
              <React.Fragment>
                <FormControl component="fieldset" style={styles.formControl}>
                  <RadioGroup
                    aria-label="position"
                    name="permission"
                    //label="Select Access Permission"
                    value={this.state.status}
                    onChange={this.handleChange}
                    column
                  >
                    <FormControlLabel
                      value={"Offer Acknowledged"}
                      control={<Radio color="primary" />}
                      label="Offer Acknowledged"
                      labelPlacement="end"
                      name="status"
                    />
                    <FormControlLabel
                      value={"Comparison Sheet"}
                      control={<Radio color="primary" />}
                      label="Comparison Sheet"
                      labelPlacement="end"
                      name="status"
                    />
                    <FormControlLabel
                      value={"Offer Price Revision"}
                      control={<Radio color="primary" />}
                      label="Offer Price Revision"
                      labelPlacement="end"
                      name="status"
                    />
                    <FormControlLabel
                      value={"HO for Finalization"}
                      control={<Radio color="primary" />}
                      label="HO for Finalization"
                      labelPlacement="end"
                      name="status"
                    />
                    <FormControlLabel
                      value={"At Risk"}
                      control={<Radio color="primary" />}
                      label="At Risk"
                      labelPlacement="end"
                      name="status"
                    />
                    <FormControlLabel
                      value={"Expected Closing Date"}
                      control={<Radio color="primary" />}
                      label="Expected Closing Date"
                      labelPlacement="end"
                      name="status"
                    />
                    <FormControlLabel
                      value={"Lost"}
                      control={<Radio color="primary" />}
                      label="Lost"
                      labelPlacement="end"
                      name="status"
                    />
                    <FormControlLabel
                      value={"PO/PI/LC"}
                      control={<Radio color="primary" />}
                      label="PO/PI/LC"
                      labelPlacement="end"
                      name="status"
                    />
                  </RadioGroup>
                </FormControl>
              </React.Fragment>
            </div>
            <div>
              {values.progress[values.progress.length - 1].status === "Lost" ||
              values.progress[values.progress.length - 1].status ===
                "PO/PI/LC" ? (
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  style={styles.button}
                  disabled={true}
                >
                  Offer Closed
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  style={styles.button}
                  onClick={this.handleSubmit}
                >
                  Submit Status Update
                </Button>
              )}
              <Typography
                variant="p"
                component="p"
                style={{ marginTop: "8px", color: "#757575", fontSize: "12px" }}
              >
                The user and time-stamped will be logged each time an update is
                performed on this document.
              </Typography>
            </div>
          </Paper>
        </React.Fragment>
      </div>
    );
  }
}

const styles = {
  root: {
    padding: "16px 16px",
    display: "flex",
    justifyContent: "space-between"
  },
  secondaryroot: {
    padding: "16px 16px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center"
  },
  secondaryrootNew: {
    padding: "16px 16px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  typographyroot: {
    background: "#FAFAFA",
    color: "#757575",
    fontSize: "10px",
    display: "flex",
    padding: "16px 16px",
    fontWeight: "700",
    textTransform: "uppercase"
  },
  container: {
    //display: "flex",
    background: "#375A6D",
    borderRadius: "5px",
    marginTop: "10px"
  },
  typographyHeader: {
    display: "flex",
    alignItems: "center",
    color: "#424242"
  },
  button: {
    marginTop: 16,
    width: "100%",
    color: "white"
  },
  extras: {
    marginTop: 8,
    marginBottom: 8
  },
  label: {
    fontSize: 12
  },
  typograghies: {
    fontWeight: "700",
    fontSize: 12
  },
  typographyvariant: {
    fontWeight: "700",
    fontSize: "12px"
    //    textTransform: "uppercase"
  },
  divStyle: {
    borderTop: "5px solid #161319",
    color: "#757575",
    letterSpacing: "2px",
    display: "flex",
    flexDirection: "column"
  }
};

export default ViewOfferDetails;
