import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
//For Text Fields
import TextField from "@material-ui/core/TextField";
//For Radio Buttons
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
//paper
import Paper from "@material-ui/core/Paper";
//For Buttons
import Button from "@material-ui/core/Button";
//for visibility toggle of password
//for visibility toggle of password
// import IconButton from "@material-ui/core/IconButton";
//typography
// import Typography from "@material-ui/core/Typography";
//image
// import PhotoCamera from "@material-ui/icons/PhotoCamera";
// import Avatar from "@material-ui/core/Avatar";

class UserEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };
  }

  handleClickShowPassword = () => {
    const newState = !this.state.showPassword;
    this.setState({
      showPassword: newState
    });
  };

  render() {
    const { values, handleChange, handleSubmit } = this.props;
    return (
      <MuiThemeProvider>
        <React.Fragment>
          <Paper
            style={styles.root}
            elevation={this.props.elevate}
            className="noMargin"
          >
            <form style={styles.container} onSubmit={handleSubmit}>
              {/* <React.Fragment>
                <Typography variant="h5">Edit User</Typography>
              </React.Fragment> */}
              {/* <div style={{ display: "flex", width: "100%" }}>
                <React.Fragment>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="icon-button-file"
                    type="file"
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      style={{ margin: 8 }}
                      aria-label="upload picture"
                      component="span"
                    >
                      <PhotoCamera style={{ height: "50px", width: "50px" }} />
                    </IconButton>
                  </label>
                </React.Fragment>
              </div> */}
              {/* <React.Fragment>
                <Avatar alt="imgUrl" src={values.imgUrl} />
              </React.Fragment> */}

              <React.Fragment>
                <TextField
                  label="Username"
                  value={values.username}
                  type="text"
                  onChange={handleChange}
                  margin="normal"
                  name="username"
                  style={styles.textField}
                />
                <TextField
                  label="Fullname"
                  value={values.fullname}
                  type="text"
                  onChange={handleChange}
                  margin="normal"
                  name="fullname"
                  style={styles.textField}
                />
                <TextField
                  label="Designation"
                  value={values.designation}
                  type="text"
                  onChange={handleChange}
                  margin="normal"
                  name="designation"
                  style={styles.textField}
                />
                <TextField
                  label="Email"
                  value={values.email}
                  type="email"
                  onChange={handleChange}
                  margin="normal"
                  name="email"
                  style={styles.textField}
                />
                <br />
                <React.Fragment>
                  <FormControl component="fieldset" style={styles.formControl}>
                    <FormLabel component="legend">
                      Select Access Permission
                    </FormLabel>
                    <RadioGroup
                      aria-label="position"
                      name="permission"
                      label="Select Access Permission"
                      value={values.permission}
                      onChange={handleChange}
                      column
                    >
                      <FormControlLabel
                        value={"Management Team"}
                        control={<Radio color="primary" />}
                        label="Management Team"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value={"Procurement Team"}
                        control={<Radio color="primary" />}
                        label="Procurement Team"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value={"Marketing Team"}
                        control={<Radio color="primary" />}
                        label="Marketing Team"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </React.Fragment>
              </React.Fragment>

              <React.Fragment>
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  name="submit"
                  style={values.btnDisabled ? styles.buttonDisabled : styles.button}
                  disabled={values.btnDisabled}
                >
                  {this.props.submit}
                </Button>
              </React.Fragment>
            </form>
          </Paper>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

const styles = {
  button: {
    margin: 8,
    width: 350,
    background: "#8BC34A",
    color: "white"
  },
  buttonDisabled: {
    margin: 8,
    width: 350,
    background: "grey",
    color: "DimGray"
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 350
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    width: "100%"
  },
  formControl: {
    display: "flex",
    flexDirection: "column",
    width: 350
  },
  root: {
    // padding: "24px 16px",
    display: "flex",
    flexWrap: "wrap"
  }
};

export default UserEditForm;
