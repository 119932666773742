import React, { Component } from "react";
import PropTypes from "prop-types";
//withStyles
import { withStyles } from "@material-ui/core";
//pagination and footer
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
//table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//paper
import Paper from "@material-ui/core/Paper";
//icons
import IconButton from "@material-ui/core/IconButton";
// import FirstPageIcon from "@material-ui/icons/ArrowDropUp";
import KeyboardArrowLeft from "@material-ui/icons/ArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/ArrowRight";
// import LastPageIcon from "@material-ui/icons/ArrowDropDown";
//delete icon
import DeleteIcon from "@material-ui/icons/Delete";
//searchbar
// import Searchbar from "../../layouts/commonComponents/TableSearchbar";
//buttons
// import Button from "@material-ui/core/Button";
//loader
// import Loader from "../../layouts/commonComponents/Loader";
//modals
// import Modals from "./PrincipalEditModal";
import { withRouter } from "react-router-dom";

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5
  }
});

class TablePaginationActions extends Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        {/* <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton> */}
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        {/* <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton> */}
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(TablePaginationActions);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    boxShadow: "none"
  },
  table: {
    // minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  textDecore: {
    textDecoration: "none"
  }
});

class CustomPaginationActionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      page: 0,
      rowsPerPage: 10,
      open: false,
      currentModalData: {},
      loader: false
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ rows: nextProps.values });

    console.log(nextProps.values);
  }

  //Refreshs Table
  // updateTable = () => {
  //   this.setState({
  //     open: false
  //   });
  //   Axios.get(this.props.getData)
  //     .then(data => {
  //       this.setState({ rows: [...data.data] });
  //       console.log(this.state);
  //     })
  //     .catch(err => console.log(err));
  // };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  handlePageChange = event => {
    this.props.history.push(this.props.url);
  };
  handleClickOpen = rowData => {
    console.log(rowData);
    this.setState({
      open: true,
      currentModalData: rowData
    });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  removeUser = rowData => {
    console.log(rowData._id);
  };
  handleremoveUser = rowData => {
    console.log(rowData);
  };

  handleRemoveItem = index => {
    this.props.handleRemoveItem(index);
  };

  render() {
    const { classes } = this.props;
    const { rows, rowsPerPage, page } = this.state;
    const { emptyRows } =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    console.log(rows);

    if (
      //this.state.rows.length
      !this.state.loader
    ) {
      return (
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <div
              style={{
                display: "flex",
                padding: "20px",
                justifyContent: "space-between"
              }}
            >
              {/* <Searchbar /> */}
              {/* <Modals
                currentData={this.state.currentModalData}
                open={this.state.open}
                handleClose={this.handleClose}
                updateTable={this.updateTable}
              /> */}
            </div>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Principle Name</TableCell>
                  <TableCell align="left">Product Name</TableCell>
                  <TableCell align="left">Model</TableCell>
                  <TableCell align="left">Comment</TableCell>
                  <TableCell align="left">Quantity</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow hover key={row.modelId}>
                      <TableCell component="th" scope="row">
                        {row.principleName.principalName}
                      </TableCell>
                      <TableCell align="left">
                        {row.productName.productName}
                      </TableCell>
                      <TableCell align="left">{row.model.ModelName}</TableCell>
                      <TableCell align="left">
                        <p style={{ maxWidth: "200px" }}>
                          {row.productComment}
                        </p>
                      </TableCell>
                      <TableCell align="left">{row.quantity}</TableCell>
                      <TableCell align="left">
                        <DeleteIcon
                          onClick={() => this.handleRemoveItem(index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                {{ emptyRows } > 0 && (
                  <TableRow style={{ height: 48 * { emptyRows } }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={6}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <div
              style={{
                display: "flex",
                padding: "20px",
                justifyContent: "space-between"
              }}
            >
              {/* <Searchbar /> */}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <Loader /> */}
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Principle Name</TableCell>
                    <TableCell align="left">Code</TableCell>
                    <TableCell align="left">Product</TableCell>
                    <TableCell align="left">Comment</TableCell>
                    <TableCell align="left">Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell component="th" scope="row">
                    No data yet
                  </TableCell>
                  <TableCell align="left">No data yet</TableCell>
                  <TableCell align="left">No data yet</TableCell>
                  <TableCell align="left">No data yet</TableCell>
                  <TableCell align="left">No data yet</TableCell>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={3}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        native: true
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </div>
        </Paper>
      );
    }
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(CustomPaginationActionsTable));
