import React, { Component } from "react";
import Chart from "react-apexcharts";

class Pie extends Component {
  generateGraphData(arr) {
    var a = [],
      b = [],
      prev;

    arr.sort();
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        a.push(arr[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }

    return [a, b];
  }

  getRate() { }

  convertToTaka(item) {
    if (item.currency === "৳") {
      return item.value;
    }
    else if (item.rate === undefined) {
      if (item.currency === "€") {
        return item.value * 95;
      } else if (item.currency === "$") {
        return item.value * 85;
      } else if (item.currency === "¥") {
        return item.value * 0.8;
      }
    } else {
      return item.value * item.rate;
    }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getValues(graphData, totalValue) {
    let valByIdx = [];
    graphData[0].forEach(name => {
      let val = 0;
      let won = 0;
      let lost = 0;
      totalValue.forEach(item => {
        if (item.name === name) {
          if (Number.isInteger(item.value)) {
            val += item.value;
            if (item.WL === "Won") {
              won += item.value;
            } else if (item.WL === "Lost") {
              lost += item.value;
            }
          } else {
            let floatVal = parseFloat(item.value.replace(/[^0-9.]/g, ""));
            if (!isNaN(floatVal)) {
              let tkVal = this.convertToTaka({
                value: floatVal,
                currency: item.currency,
                rate: item.rate
              });
              val += tkVal;
              if (item.WL === "Won") {
                won += tkVal;
              } else if (item.WL === "Lost") {
                lost += tkVal;
              }
            }
          }
        }
      });
      if (totalValue[0].WL) {
        valByIdx.push(`Won: ৳${this.numberWithCommas(won)},  Lost: ৳ ${this.numberWithCommas(lost)}, Total: ৳ ${this.numberWithCommas(val)}`);
      } else {
        valByIdx.push("৳" + this.numberWithCommas(val));
      }
    });
    return valByIdx;
  }

  generateChart(data, subchart) {
    let graphData = {};
    let totalValue = {};
    let valByIdx = [];
    let showValue = 0;
    if (subchart === "client") {
      // console.log(data);
      graphData = data.data.map(doc => doc[subchart]["clientName"]);
      graphData = this.generateGraphData(graphData);
      if (this.props.feature === "offers") {
        totalValue = data.data.map(doc =>
          Object.assign(
            {},
            { name: doc[subchart]["clientName"] },
            { value: doc["principalCost"] },
            { currency: doc["currency"].substring(0, 1) },
            { rate: doc["currencyRate"] },
            {
              WL:
                doc["progress"][doc["progress"].length - 1]["status"] ===
                  "PO/PI/LC"
                  ? "Won"
                  : doc["progress"][doc["progress"].length - 1]["status"] ===
                    "Lost"
                    ? "Lost"
                    : "None"
            }
          )
        );
        valByIdx = this.getValues(graphData, totalValue);
      } else if (this.props.feature === "services") {
        totalValue = data.data.map(doc =>
          Object.assign(
            {},
            { name: doc[subchart]["clientName"] },
            { value: doc["item"][0]["serviceCharge"] },
            { currency: doc["item"][0]["currency"].substring(0, 1) },
            { rate: doc["item"][0]["currencyRate"] }
          )
        );
        valByIdx = this.getValues(graphData, totalValue);
      } else if (this.props.feature === "bills") {
        totalValue = data.data.map(doc =>
          Object.assign(
            {},
            { name: doc[subchart]["clientName"] },
            { value: doc["totalPayableAmount"] },
            { currency: doc["currency"].substring(0, 1) },
            { rate: doc["currencyRate"] }
          )
        );
        valByIdx = this.getValues(graphData, totalValue);
      }
    } else if (subchart === "principal") {
      graphData = data.data.map(doc => {
        if (doc.item.length > 0) {
          return doc["item"][0]["principleName"]["principalName"];
        } else {
          return null;
        }
      });
      graphData = this.generateGraphData(graphData);
      if (this.props.feature === "offers") {
        totalValue = data.data.map(doc =>
          Object.assign(
            {},
            { name: doc["item"][0]["principleName"]["principalName"] },
            { value: doc["principalCost"] },
            { currency: doc["currency"].substring(0, 1) },
            { rate: doc["currencyRate"] },
            {
              WL:
                doc["progress"][doc["progress"].length - 1]["status"] ===
                  "PO/PI/LC"
                  ? "Won"
                  : doc["progress"][doc["progress"].length - 1]["status"] ===
                    "Lost"
                    ? "Lost"
                    : "None"
            }
          )
        );
        valByIdx = this.getValues(graphData, totalValue);
      } else if (this.props.feature === "services") {
        totalValue = data.data.map(doc =>
          Object.assign(
            {},
            { name: doc["item"][0]["principleName"]["principalName"] },
            { value: doc["item"][0]["serviceCharge"] },
            { currency: doc["item"][0]["currency"].substring(0, 1) },
            { rate: doc["item"][0]["currencyRate"] }
          )
        );
        valByIdx = this.getValues(graphData, totalValue);
      } else if (
        this.props.feature === "bills" ||
        this.props.feature === "ibills"
      ) {
        totalValue = data.data.map(doc =>
          Object.assign(
            {},
            { name: doc["item"][0]["principleName"]["principalName"] },
            { value: doc["totalDue"] },
            { currency: doc["currency"].substring(0, 1) },
            { rate: doc["currencyRate"] }
          )
        );
        valByIdx = this.getValues(graphData, totalValue);
      }
    } else if (subchart === "totalDue") {
      graphData = data.data.map(doc =>
        doc["totalDue"] === 0 ? "Collected" : "Pending"
      );
      valByIdx = [0, 0];
      graphData.forEach((gdata, idx) => {
        if (gdata === "Pending") {
          valByIdx[1] += data.data[idx].totalDue;
        } else {
          valByIdx[0] += data.data[idx].totalPayableAmount;
        }
      });
      graphData = this.generateGraphData(graphData);
    } else if (subchart === "marketing") {
      switch (this.props.feature) {
        case "ecr":
          graphData = data.data.map(doc => doc["visitedBy"]);
          graphData = this.generateGraphData(graphData);
          break;
        case "urs":
          graphData = data.data.map(doc => doc["CollectedBy"]["email"]);
          graphData = this.generateGraphData(graphData);
          break;
        case "offers":
          graphData = data.data.map(doc => doc["sentBy"]["email"]);
          graphData = this.generateGraphData(graphData);
          break;
        case "services":
          graphData = data.data.map(doc => doc["visitedBy"]);
          graphData = this.generateGraphData(graphData);
          break;
      }
    }
    this.setState({
      options: {
        chart: {
          events: {
            dataPointMouseEnter: function (event, chartContext, config) {
              showValue = valByIdx[config.dataPointIndex];
            }
          }
        },
        labels: graphData[0],
        dataLabels: {
          enabled: true,
          formatter: function (...val) {
            return graphData[1][val[1].seriesIndex];
          }
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return showValue !== undefined ? showValue : value;
            }
          }
        },
        theme: {
          monochrome: {
            enabled: false
          }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#9E1322', '#775DD0', '#546E7A', '#2C6B73', '#33b2df', '#d4526e', '#13d8aa', '#A5978B'],
        opacity: '0.5',
        title: {
          text: `${this.props.feature.toUpperCase()} per ${subchart
            .charAt(0)
            .toUpperCase() + subchart.substring(1)}`
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
                height: 100
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
      series: graphData[1],
      canRender: true
    });
  }

  componentDidMount() {
    if (this.props.data) {
      this.generateChart(this.props.data, this.props.subchart);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.data !== this.props.data ||
      nextProps.subchart !== this.props.subchart
    ) {
      if (nextProps.data) {
        this.generateChart(nextProps.data, nextProps.subchart);
      }
    }
  }

  render() {
    return this.state ? (
      <div className="donut">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          width="700"
        />
      </div>
    ) : null;
  }
}

export default Pie;
