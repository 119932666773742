import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import firebase from 'firebase';

// import axios from "axios";
class ImgMediaCard extends Component {
  // componentDidMount() {
  //   axios
  //     .get(
  //       `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/user/${this.state.uid}`
  //     )
  //     .then(result => {
  //       const { permission } = result.data;
  //       console.log(permission);
  //       console.log(result.data);
  //       if (permission === "Management Team") {
  //         this.setState({ isAdmin: false });
  //       } else {
  //         this.setState({ isAdmin: true });
  //       }
  //     });
  // }
  render() {
    const { values, handleRemove, handleEditClick } = this.props;
    console.log(values);
    return (
      <Card className="card" style={styles.divStyle}>
        <CardActionArea>
          <CardContent style={{display:"flex", flexDirection:"column"}}>
            <Typography variant="p" style={styles.typographyvariant}>
              ECR ID: {values.id}
            </Typography>
            <Typography variant="p" style={styles.typographyvariant}>
              Created On: {this.props.values.createdAt !== undefined ? new firebase.firestore.Timestamp(this.props.values.createdAt._seconds,this.props.values.createdAt._nanoseconds).toDate().toString().substring(0,15): 'Not Available'}
            </Typography>
            {/* <Typography variant="body2" color="textSecondary" component="p">
              Lizards are a widespread group of squamate reptiles, with over
              6,000 species, ranging across all continents except Antarctica
            </Typography> */}
          </CardContent>
        </CardActionArea>
        {this.props.admin || this.props.procurement ? (
          <CardActions>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={handleRemove}
              disabled={!this.props.admin}
            >
              delete
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleEditClick}
              disabled={!this.props.admin}
            >
              edit
            </Button>
          </CardActions>
        ) : (
            ""
          )}
      </Card>
    );
  }
}

const styles = {
  typographyvariant: {
    fontWeight: "700",
    fontSize: "12px",
    marginLeft: "20px",
//    textTransform: "uppercase"
  },
  divStyle: {
    borderTop: "5px solid #161319",
    color: "#757575",
    letterSpacing: "2px",
    display: "flex",
    flexDirection: "column"
  }
};
export default ImgMediaCard;
