import React, { Component } from "react";
import ProductView from "../../products/ProductView";
import PrimarySearchAppBar from "../appbarComponent/Appbar";

export class ProductViewModel extends Component {
  render() {
    return (
      <div className="allViewContainersNew">
        <div>
          <PrimarySearchAppBar title="Add New Product" />
        </div>
        <React.Fragment>
          <ProductView />
        </React.Fragment>
      </div>
    );
  }
}

export default ProductViewModel;
