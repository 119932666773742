import React from "react";
//paper container for navbar
import Paper from "@material-ui/core/Paper";
//for implementing styling
import { makeStyles } from "@material-ui/core";
//menu grid for holding navigation tabs
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
//routing
import { NavLink, withRouter } from "react-router-dom";
//colors
import { grey } from "@material-ui/core/colors";
//icons
import SettingsIcon from "@material-ui/icons/Settings";
import BarChartIcon from "@material-ui/icons/BarChart";
// import StayPrimaryPortraitIcon from "@material-ui/icons/StayPrimaryPortrait";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import logo from "../../imgs/Logo.svg"
const navStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    minWidth: "100px",
  },
  paper: {
    marginRight: theme.spacing(0.1),
    boxShadow:
      " 0 10px 40px 0 rgba(0, 0, 0, 0.07), 0 2px 9px 0 rgba(0, 0, 0, 0.06)",
    position: "fixed",
    left: "0",
    top: "0",
    height: "100%",
    minWidth: "100px",
    backgroundImage: "linear-gradient(#2B292E, #37596D)",
    color: "white",
  },
  nav: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "80%",
    pointerEvents: "none",
  },
  tab: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    pointerEvents: "all",
  },
  navlinkcolor: {
    color: grey[200],
    textDecoration: "none",
    opacity:"0.5"
  },
  activenavlinkcolor: {
    color: grey[50],
    borderLeft: " 3px solid white",
    textDecoration: "none",
    opacity:"1"
  },
  img: {
    height: "auto",
    width: "72px",
    backgroundRepeat: "no-repeat",
    userSelect: "none",
    color:"white"
  },
  logopadding: {
    display: "flex",
    height: "10vh",
    justifyContent: "center",
    alignItems: "center",
  },
  NavmenuConatiner: {
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
    pointerEvent: "none",
  },
  textDecoration: {
    userSelect: "none",
    textDecoration: "none",
    margin: theme.spacing(0),
    fontSize: "12px",
    fontWeight: "normal",
  },
}));

function Navbar() {
  const classes = navStyle();
  return (
    <div className="navigationColumn">
      <Paper className={classes.paper} square={true}>
        <div className={classes.logopadding}>
          <NavLink exact to="/sales">
            <div>
              <img
                className={classes.img}
                src={logo}
                alt="Logo.svg"
              />
            </div>
          </NavLink>
        </div>
        <div className={classes.NavmenuConatiner}>
          <MenuList className={classes.nav}>
            <NavLink
              exact
              to="/sales"
              className={classes.navlinkcolor}
              activeClassName={classes.activenavlinkcolor}
            >
              <MenuItem className={classes.tab}>
                {<TrackChangesIcon />}
                <h5 className={classes.textDecoration}>Sales</h5>
              </MenuItem>
            </NavLink>
            {/* <NavLink
              to="/budgeting"
              className={classes.navlinkcolor}
              activeClassName={classes.activenavlinkcolor}
            >
              <MenuItem className={classes.tab}>
                {<StayPrimaryPortraitIcon />}
                <h5 className={classes.textDecoration}>Budgeting</h5>
              </MenuItem>
            </NavLink> */}
            <NavLink
              to="/stats"
              className={classes.navlinkcolor}
              activeClassName={classes.activenavlinkcolor}
            >
              <MenuItem className={classes.tab}>
                {<BarChartIcon />}
                <h5 className={classes.textDecoration}>Reports</h5>
              </MenuItem>
            </NavLink>
            <NavLink
              to="/admin"
              className={classes.navlinkcolor}
              activeClassName={classes.activenavlinkcolor}
            >
              <MenuItem className={classes.tab}>
                {<SettingsIcon />}
                <h5 className={classes.textDecoration}>Admin</h5>
              </MenuItem>
            </NavLink>
          </MenuList>
        </div>
      </Paper>
    </div>
  );
}
export default withRouter(Navbar);
