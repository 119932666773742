import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
// import Grid from "@material-ui/core/Grid";
import { grey } from "@material-ui/core/colors";
// import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
// For Buttons
import Button from "@material-ui/core/Button";
// selects
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";

const styles = {
  root: {
    margin: 0,
    padding: "16px"
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: grey
  },
  bigAvatar: {
    margin: 5,
    width: 70,
    height: 70
  },
  box: {
    display: "flex",
    alignItems: "center"
  },
  textFieldSelect: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 16,
    marginBottom: 8,
    width: 250
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8,
    width: 350
  },
  formControl: {
    display: "flex",
    margin: "8px"
  }
};

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default class CustomizedDialogs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // handleRemove = () => {
  //   axios
  //     .delete(
  //       `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${this.props.currentData._id}`
  //     )
  //     .then(result => {
  //       console.log(result);
  //       this.props.updateTable();
  //     })
  //     .catch(err => console.log(err));
  // };

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.values.principleId !== this.props.values.principleId) {
  //     console.log("here");
  //     axios
  //       .get(
  //         `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/principal/${nextProps.values.principleId}/product`
  //       )
  //       .then(branches => {
  //         this.setState({
  //           product_list: branches.data
  //         });
  //         console.log(branches.data);
  //       })
  //       .catch(err => {
  //         console.log(err);
  //       });
  //   }
  //   if (nextProps.values.productId !== this.props.values.productId) {
  //     console.log("here");
  //     axios
  //       .get(
  //         `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/product/${nextProps.values.productId}/model`
  //       )
  //       .then(branches => {
  //         this.setState({
  //           model_list: branches.data
  //         });
  //         console.log(branches.data);
  //       })
  //       .catch(err => {
  //         console.log(err);
  //       });
  //   }
  // }

  // componentWillMount() {
  //   axios
  //     .get(
  //       "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/principal"
  //     )
  //     .then(data => {
  //       this.setState({ principal_list: [...data.data] });
  //       console.log(data.data);
  //     })
  //     .catch(err => console.log(err));
  // }

  render() {
    // const { values, handleChange, handleItemSubmit } = this.props;
    console.log(this.props);
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Status Updated - Expected Closing Date
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please provide the estimated date of closing:
            </DialogContentText>
            <FormControl fullWidth>
              <TextField
                fullWidth
                required
                label="Select Date"
                value={this.props.values.date}
                type="date"
                onChange={this.props.handleChange}
                name="date"
                style={styles.textField}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.props.handleSubmit} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
