import React, { Component } from "react";
import Chart from "react-apexcharts";

class Line extends Component {
  generateGraphData(arr, sorted = false) {
    var a = [],
      b = [],
      prev;
    if (!sorted) {
      arr.sort();
    }
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== prev) {
        a.push(arr[i]);
        b.push(1);
      } else {
        b[b.length - 1]++;
      }
      prev = arr[i];
    }

    return [a, b];
  }

  convertMonth(month) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    return Number.isInteger(parseInt(month)) ? months[parseInt(month) - 1] : "";
  }
  monthToQuarter(month) {
    return month < 4 ? 1 : month < 7 ? 2 : month < 10 ? 3 : 4;
  }
  fillMonths(graphData) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let count = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (var i = 0; i < months.length; i++) {
      if (graphData[0].includes(months[i])) {
        count[i] = parseInt(graphData[1][graphData[0].indexOf(months[i])]);
      }
    }
    return [months, count];
  }

  fillQuarters(graphData, start, end) {
    let quarters = [];
    let data = [];
    for (var i = start; i <= end; i++) {
      for (var j = 1; j <= 4; j++) {
        let q = i + "-Q" + j;
        quarters.push(q);
        data.push(
          graphData[0].includes(q)
            ? parseInt(graphData[1][graphData[0].indexOf(q)])
            : 0
        );
      }
    }
    return [quarters, data];
  }

  fillYears(graphData, start, end) {
    let years = [];
    let data = [];
    for (var i = start; i <= end; i++) {
      years.push(i);
      data.push(
        graphData[0].includes(i.toString())
          ? parseInt(graphData[1][graphData[0].indexOf(i.toString())])
          : 0
      );
    }
    return [years, data];
  }

  createGraphData(graphData, time, sorted) {
    let year = null;
    switch (time) {
      case "months":
        graphData = this.generateGraphData(graphData, sorted);
        graphData[0] = graphData[0].map(mnth => this.convertMonth(mnth));
        graphData = this.fillMonths(graphData);
        return graphData;
      case "quarters":
        graphData = this.generateGraphData(graphData, sorted);
        year = this.props.year ? this.props.year : new Date().getFullYear();
        graphData =
          this.props.yearEnd && this.props.yearEnd > year
            ? this.fillQuarters(graphData, year, this.props.yearEnd)
            : this.fillQuarters(graphData, year, new Date().getFullYear());
        return graphData;
      case "years":
        graphData = this.generateGraphData(graphData, sorted);
        year = this.props.year ? this.props.year : new Date().getFullYear();
        graphData =
          this.props.yearEnd && this.props.yearEnd > year
            ? this.fillYears(graphData, year, this.props.yearEnd)
            : this.fillYears(graphData, year, new Date().getFullYear());
        return graphData;
      default:
        break;
    }
  }

  generateBarGraphDataforOffers(graphData, time) {
    let created = [];
    let won = [];
    let lost = [];
    graphData = graphData.sort((a, b) => a.time - b.time);
    for (var i = 0; i < graphData.length; i++) {
      created.push(graphData[i].time);
      if (graphData[i].status === "Lost") {
        lost.push(graphData[i].time);
      } else if (graphData[i].status === "PO/PI/LC") {
        won.push(graphData[i].time);
      }
    }
    won = this.createGraphData(won, time, true);
    lost = this.createGraphData(lost, time, true);
    created = this.createGraphData(created, time, true);

    let seriesData = [
      {
        name: "Created",
        data: created[1]
      },
      {
        name: "Won",
        data: won[1]
      },
      {
        name: "Lost",
        data: lost[1]
      }
    ];
    let categoryData = created[0];
    return [seriesData, categoryData];
  }

  generateBarGraphDataforBills(graphData, time) {
    let created = [];
    let pending = [];
    let collected = [];
    graphData = graphData.sort((a, b) => a.time - b.time);
    for (var i = 0; i < graphData.length; i++) {
      created.push(graphData[i].time);
      if (graphData[i].status === "Pending") {
        pending.push(graphData[i].time);
      } else {
        collected.push(graphData[i].time);
      }
    }
    pending = this.createGraphData(pending, time, true);
    collected = this.createGraphData(collected, time, true);
    created = this.createGraphData(created, time, true);

    let seriesData = [
      {
        name: "Created",
        data: created[1]
      },
      {
        name: "Pending",
        data: pending[1]
      },
      {
        name: "Collected",
        data: collected[1]
      }
    ];
    let categoryData = created[0];
    return [seriesData, categoryData];
  }

  generateChart(data) {
    let graphData = {};
    let categoryData = null;
    let seriesData = null;

    if (this.props.feature === "offers") {
      graphData = data.data.map(doc =>
        Object.assign(
          {},
          { time: doc["createdAtString"].split("-")[1] },
          { status: doc["progress"][doc["progress"].length - 1]["status"] }
        )
      );
      [seriesData, categoryData] = this.generateBarGraphDataforOffers(
        graphData,
        "months"
      );
    } else if (this.props.feature === "bills") {
      graphData = data.data.map(doc =>
        Object.assign(
          {},
          { time: doc["createdAtString"].split("-")[1] },
          { status: doc.totalDue === 0 ? "Collected" : "Pending" }
        )
      );
      [seriesData, categoryData] = this.generateBarGraphDataforBills(
        graphData,
        "months"
      );
    } else {
      graphData = this.createGraphData(
        data.data.map(doc => doc["createdAtString"].split("-")[1]),
        "months",
        false
      );
      seriesData = [
        {
          name: "Visits",
          data: graphData[1]
        }
      ];
      categoryData = graphData[0];
    }
    if (categoryData && seriesData) {
      this.setState({
        options: {
          xaxis: {
            categories: categoryData
          }
        },
        series: seriesData,
        canRender: true
      });
    }
  }

  componentDidMount() {
    if (this.props.data) {
      this.generateChart(this.props.data);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      if (nextProps.data) {
        this.generateChart(nextProps.data);
      }
    }
  }

  render() {
    return this.state ? (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              width="700"
            />
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default Line;
