import React, { Component } from "react";
import InputBase from "@material-ui/core/InputBase";
import checked from "../checked.png";
import unchecked from "../pending.png";
import firebase from 'firebase';



// import { db } from '../../../firebase'
//utility
// import utility from "../../layouts/utility/utility";

export class BaseComponent extends Component {
  render() {
    // console.log(`testing date: ${new firebase.firestore.Timestamp(this.props.date._seconds, this.props.date._nanoseconds).toDate()}`)
    return (
      <div style={styles.logs}>
        {this.props.approved ? (
          <img src={checked} alt="tick" />
        ) : (
            <img src={unchecked} alt="tick" />
          )}
        <div style={{ marginLeft: "8px", }}>
          <InputBase readOnly value={this.props.valuedata} name="Client" />
        </div>
        <InputBase
          style={{ color: "#BDBDBD" }}
          readOnly
          // value={utility}
          value={this.props.date === undefined ? '' : new firebase.firestore.Timestamp(this.props.date._seconds, this.props.date._nanoseconds).toDate().toString().substring(0,15)}
          name="logdate"
        />
      </div>
    );
  }
}

const styles = {
  avatar: {
    width: "30px",
    height: "30px",
    marginRight: "8px"
  },
  logs: {
    marginTop: 8,
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between"
  }
};

export default BaseComponent;
