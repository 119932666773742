import React, { Component } from "react";
import UserView from "../../users/UserView";
import PrimarySearchAppBar from "../appbarComponent/Appbar";

export class UserViewModel extends Component {
  render() {
    return (
      <div className="allViewContainersNew">
        <div>
          <PrimarySearchAppBar title="Add New User" />
        </div>
        <React.Fragment>
          <UserView />
        </React.Fragment>
      </div>
    );
  }
}

export default UserViewModel;
