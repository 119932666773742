import React, { Component } from "react";
// import SvgIcon from '@material-ui/core/SvgIcon';

export class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }
  setHover(status) {
    this.setState({
      hover: status,
    });
  }

  render() {
    return (
      <div
        style={this.state.hover ? styles.cardHover : styles.cardContainer}
        onMouseEnter={() => this.setHover(true)}
        onMouseLeave={() => this.setHover(false)}
      >
        <div style={styles.marginSpacing}>
          {/* <SvgIcon
            style={this.state.hover ? styles.cardContainerImgHover : styles.cardContainerImg}
            children={this.props.name}
          /> */}
          <img
            src={this.props.name}
            alt=""
            style={
              this.state.hover
                ? styles.cardContainerImgHover
                : styles.cardContainerImg
            }
          />

          <p>{this.props.desc}</p>
        </div>
      </div>
    );
  }
}

const styles = {
  cardContainer: {
    color: "#4c4c4c",
    display: "flex",
    justifyContent: "center",
    borderBottom: "5px solid #DFDFDF",
    backgroundColor: "#fff",
    height: "12rem",
    width: "10rem",
    margin: "1rem",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "12px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.08)",
    minWidth: "8rem",
    borderColor: "#ffffff",
    transition: "all 0.3s ease-out",
  },
  cardHover: {
    color: "#161319",
    display: "flex",
    justifyContent: "center",
    borderBottom: "5px solid #DFDFDF",
    backgroundColor: "#fff",
    height: "12rem",
    width: "10rem",
    margin: "1rem",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "12px",
    minWidth: "8rem",
    transition: "all 0.3s ease-out",
    boxShadow: "0 35px 20px rgba(42, 39, 44,0.103)",
    borderColor: "#161319",
    transform: "translateY(-2px)",
  },
  cardContainerImg: {
    fontSize: 60,
    fontWeight: "normal",
    color: "#999999",
  },
  cardContainerImgHover: {
    fontSize: 60,
    fontWeight: "normal",
    color: "#777777",
  },
  marginSpacing: {
    margin: "20%",
  },
};

export default Cards;
