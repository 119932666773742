import React, { Component } from "react";
import EditPersonnelForm from "./EditPersonnelForm";
import axios from "axios";
import { withRouter } from "react-router-dom";

export class EditPersonnel extends Component {
  done = false;
  constructor(props) {
    super(props);
    this.state = {
      personnelName: this.props.currentData.personnelName,
      personnelDesignation: this.props.currentData.personnelDesignation,
      personnelEmail: this.props.currentData.personnelEmail,
      personnelContactno: this.props.currentData.personnelContactno
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    console.log(this.state._id);
  };
  handleSubmit(e) {
    e.preventDefault();
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${this.props.client._id}/personnel/${this.props.currentData._id}`,
        this.state
      )
      .then(result => {
        console.log(result);
        this.props.updateTable();
      })
      .catch(err => console.log(err));
  }

  render() {
    const {
      personnelName,
      personnelDesignation,
      personnelEmail,
      personnelContactno
    } = this.state;
    const values = {
      personnelName,
      personnelDesignation,
      personnelEmail,
      personnelContactno
    };

    return (
      <div style={styles.fullwidth}>
        <div>
          <React.Fragment>
            <EditPersonnelForm
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              values={values}
              submit="Submit"
              elevate={0}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}
const styles = {
  fullwidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around"
  }
};

export default withRouter(EditPersonnel);
