import React, { Component } from "react";
//paper
import Paper from "@material-ui/core/Paper";
//For Radio Buttons
import FormControl from "@material-ui/core/FormControl";
//input label
import InputLabel from "@material-ui/core/InputLabel";
// selects
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// textfields
import TextField from "@material-ui/core/TextField";
//typography
import Typography from "@material-ui/core/Typography";
//table
import ViewURSFormItemtable from "./ViewURSFormItemtable";
//axios import
import axios from "axios";
//css
import "./styles.css";
// For Buttons
import Button from "@material-ui/core/Button";
//routing
import { NavLink } from "react-router-dom";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
export class URSInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      client_list: [],
      branch_list: [],
      personnel_list: [],
      user_list: [],
      productName: "",
      productId: "",
      principleName: "",
      principleId: "",
      model: "",
      modelId: "",
      quantity: "",
      productComment: "",
      width: this.props.initwidth,
    };

    // this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    axios
      .get(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client"
      )
      .then((clients) => {
        this.setState({
          client_list: clients.data,
        });
        console.log(clients.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/user"
      )
      .then((clients) => {
        this.setState({
          user_list: clients.data,
        });
        console.log(clients.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.initwidth !== nextProps.initwidth) {
      this.setState({
        width: nextProps.initwidth,
      });
    }
  }

  render() {
    const { values, handleChange, handleSubmit } = this.props;
    return (
      <div className="Containss">
        <ReactToPrint
          /*onBeforeGetContent={() =>
            this.setState({
              width: "100%"
            })
          }
          onAfterPrint={() => {
            this.setState({ width: "" });
          }}*/
          trigger={() => (
            <Button
              variant="contained"
              size="large"
              type="submit"
              name="submit"
              color="primary"
              style={{ color: "white", marginBottom: 8, display: "none" }}
            >
              <PrintIcon />
            </Button>
          )}
          content={() => this.componentRef}
        />
        <Paper
          elevation={1}
          ref={(el) => (this.componentRef = el)}
          style={{
            width: this.state.width,

            padding: this.state.width === "100%" ? 8 : "0px",
          }}
        >
          <NavLink exact to="/urs" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                margin: 8,
                display: this.state.width === "100%" ? "none" : "",
              }}
            >
              Go Back
            </Button>
          </NavLink>

          <form style={styles.container} onSubmit={handleSubmit}>
            <div style={styles.divStyle}>
              <Typography variant="p" style={styles.typographyvariant}>
                Client Information
              </Typography>
            </div>
            <div className="divstyle">
              <React.Fragment>
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: "8px" }}
                  >
                    Client Name
                  </InputLabel>
                  <Select
                    required
                    value={values.client}
                    onChange={handleChange}
                    name="client"
                    displayEmpty
                    style={styles.textFieldSelect}
                  >
                    <MenuItem value={values.client}>
                      {values.client.clientName}
                    </MenuItem>
                  </Select>
                </FormControl>
              </React.Fragment>
              {/* <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Date of Entry"
                    value={values.dateOfSubmission}
                    type="date"
                    onChange={handleChange}
                    name="dateOfSubmission"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </FormControl>
              </React.Fragment> */}
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Submission Deadline"
                    value={values.SubmissionDeadline}
                    type="date"
                    onChange={handleChange}
                    name="SubmissionDeadline"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: "8px" }}
                  >
                    Location
                  </InputLabel>
                  <Select
                    required
                    value={values.facility}
                    onChange={handleChange}
                    name="facility"
                    displayEmpty
                    style={styles.textFieldSelect}
                    inputProps={{ readOnly: true }}
                  >
                    <MenuItem value={values.facility}>
                      {values.facility.branchName}
                    </MenuItem>
                  </Select>
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Location Address "
                    value={values.facilityAddress}
                    type="text"
                    onChange={handleChange}
                    name="facilityAddress"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Facility "
                    value={values.newFacility}
                    type="text"
                    onChange={handleChange}
                    name="newFacility"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: "8px" }}
                  >
                    Contact Name
                  </InputLabel>
                  <Select
                    required
                    value={values.contactName}
                    onChange={handleChange}
                    name="contactName"
                    displayEmpty
                    style={styles.textFieldSelect}
                  >
                    <MenuItem value={values.contactName}>
                      {values.contactName.personnelName}
                    </MenuItem>
                  </Select>
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Contact no"
                    value={values.contactNo}
                    type="number"
                    onChange={handleChange}
                    name="contactNo"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Email"
                    value={values.email}
                    type="email"
                    onChange={handleChange}
                    name="email"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Designation"
                    value={values.designation}
                    type="text"
                    onChange={handleChange}
                    name="designation"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
              <React.Fragment>
                <FormControl>
                  <TextField
                    label="Reference Number "
                    value={values.referenceNumber}
                    type="text"
                    onChange={handleChange}
                    name="referenceNumber"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
            </div>
            <div style={styles.divStyle}>
              <Typography variant="p" style={styles.typographyvariant}>
                product and principal Information
              </Typography>
            </div>
            <div className="divstyleNext">
              <React.Fragment>
                <ViewURSFormItemtable values={values.item} />
              </React.Fragment>
              <React.Fragment>
                <FormControl fullWidth style={{ marginTop: "16px" }}>
                  <TextField
                    label="URS Comment"
                    value={values.ursComment}
                    type="text"
                    onChange={handleChange}
                    name="ursComment"
                    fullWidth
                    multiline
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
            </div>
            <div style={styles.divStyle}>
              <Typography variant="p" style={styles.typographyvariant}>
                Collected By
              </Typography>
            </div>
            <div className="divstyle">
              <React.Fragment>
                <FormControl>
                  <TextField
                    required
                    label="Creator Mail"
                    value={values.ursCreator}
                    type="text"
                    disabled
                    name="ursCreator"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    required
                    label="Creator Designation"
                    value={values.creatorDesig}
                    type="text"
                    disabled
                    name="creatorDesig"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ marginLeft: "8px" }}
                  >
                    Collected By
                  </InputLabel>
                  <Select
                    required
                    value={values.CollectedBy}
                    onChange={handleChange}
                    name="CollectedBy"
                    displayEmpty
                    style={styles.textFieldSelect}
                    inputProps={{ readOnly: true }}
                  >
                    <MenuItem value={values.CollectedBy}>
                      {values.CollectedBy.email}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <TextField
                    required
                    label="currency"
                    value={values.currency}
                    type="text"
                    name="currency"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    required
                    label="Currency Rate"
                    value={values.currencyRate}
                    onChange={handleChange}
                    type="number"
                    name="currencyRate"
                    style={styles.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </React.Fragment>
            </div>
            <br />
          </form>
        </Paper>
      </div>
    );
  }
}
const styles = {
  button: {
    color: "white",
    marginBottom: "24px",
  },
  buttonvariant: {
    color: "white",
    margin: 8,
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 300,
  },
  textFieldSelect: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 16,
    marginBottom: 8,
    width: 300,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  formControl: {
    display: "flex",
    margin: "8px",
  },
  root: {
    // margin: "16px"
    // boxShadow:"none"
  },
  divStyle: {
    width: "100%",
    background: "rgba(224, 224, 224, 0.25)",
    color: "#757575",
    // opacity:"0.25",
    letterSpacing: "2px",
    height: "60px",
    display: "flex",
    alignItems: "center",
  },
  typographyvariant: {
    fontWeight: "700",
    fontSize: "12px",
    marginLeft: "20px",
    textTransform: "uppercase",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
};

export default URSInputs;
