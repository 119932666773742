import React, { Component } from "react";
import BranchTable from "./BranchTable";
// import CreateBranch from "../clients/CreateBranch";

export class BranchView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBranch: false
    };
  }

  branchAdded = () => {
    this.setState({ addBranch: true });
    console.log("Added");
  };

  branchAddDone = () => {
    this.setState({ addBranch: false });
    console.log("Done");
  };
  render() {
    return (
      <div style={styles.root}>
        <div style={styles.tableContainer}>
          <BranchTable
            url="/#"
            addedData={this.state.addBranch}
            done={this.branchAddDone}
            result={this.branchAdded}
            currentData={this.props.currentData}
            getData={
              `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${this.props.currentData._id}/branch`
            }
          />
          {/* <CreateBranch result={this.branchAdded} /> */}
        </div>
      </div>
    );
  }
}

const styles = {
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  tableContainer: {
    minWidth: "100%"
  }
};

export default BranchView;
