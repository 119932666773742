import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';

const theme = createMuiTheme({
    palette:{
        primary:{
            main:"#375A6D",  
        },
        secondary:{
            main:"rgba(0,0,0,0.75)",
        },
        tertiary: {
            main: "rgba(0,0,0,0.75)"
        },
        logout: {
            main: "#E67B7B"
        }
    }
})


ReactDOM.render(
    <MuiThemeProvider theme={theme}>   
    <BrowserRouter>
    <App />
    </BrowserRouter>
    </MuiThemeProvider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();







