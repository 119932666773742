import React, { Component } from "react";
import ModelForm from "./ModelForm";
import axios from "axios";
import { withRouter } from "react-router-dom";

export class CreateModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModelName: "",
      ModelPrice: "", 
     
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    console.log(this.state);
  };
  handleSubmit(e) {
    e.preventDefault();
    axios
      .post(
      `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/product/${this.props.currentData._id}/model`,
        this.state
      )
      .then(results => {
        console.log(results);
        const emptyState = {
            ModelName: "",
            ModelPrice: "", 
        };
        this.setState(emptyState);
        this.props.updateTable();
      })
      .catch(err => console.log(err));
  }

  render() {
    const { ModelName, ModelPrice } = this.state;
    const values = { ModelName, ModelPrice };
    return (
      <div style={styles.fullwidth}>
        <div>
          <React.Fragment>
            <ModelForm
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              values={values}
              submit="Add Model"
              elevate={0}
              updatetable={this.props.updateTableModel}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}
const styles = {
  fullwidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around"
  }
};

export default withRouter(CreateModel);
