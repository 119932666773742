import React, { Component } from "react";
import axios from "axios";
import PrimarySearchAppBar from "../../layouts/appbarComponent/Appbar";
import ECRForm from "./ECRForm";
//router
import { withRouter } from "react-router-dom";
//utility
import utility from "../../layouts/utility/utility";
import "./divStyle.css";
// import Loader from "../../layouts/commonComponents/Loader";
export class AddECR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      client: "",
      clientId: "",
      facility: "",
      facilityAddress: "",
      branchId: "",
      dateOfSubmission: utility,
      contactName: "",
      contactId: "",
      contactNo: "",
      email: "",
      designation: "",
      visitedBy: this.props.email,
      purposeOfVisit: "Routine Visit",
      referrenceDocument: "",
      nextVisitDate: utility,
      visitedWith: "",
      visitStatus: "",
      jointVisitwith: "",
      item: [],
      ursComment: "",
      //new
      newFacility: "",
      btnDisabled: false,
      referenceNumber: "",
      senderLoader: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePush = this.handlePush.bind(this);
  }
  //component will recieve new props on any new change
  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== undefined) {
      this.setState({ visitedBy: nextProps.email });
    }
  }
  handleChange(e) {
    if (e.target.name === "client") {
      this.setState({ client: e.target.value });
      this.setState({ clientId: e.target.value._id });
    } else if (e.target.name === "facility") {
      console.log(e.target.value);
      this.setState({ facility: e.target.value });
      this.setState({ branchId: e.target.value._id });
      this.setState({ facilityAddress: e.target.value.branchAddress });
    } else if (e.target.name === "contactName") {
      console.log(e.target.value);
      this.setState({ contactName: e.target.value });
      this.setState({ contactId: e.target.value._id });
      this.setState({ contactNo: e.target.value.personnelContactno });
      this.setState({ designation: e.target.value.personnelDesignation });
      this.setState({ email: e.target.value.personnelEmail });
    } else if (e.target.name === "principleName") {
      this.setState({ principleName: e.target.value });
      this.setState({ principleId: e.target.value._id });
    } else if (e.target.name === "productName") {
      this.setState({ productName: e.target.value });
      this.setState({ productId: e.target.value._id });
    } else if (e.target.name === "model") {
      this.setState({ model: e.target.value });
      this.setState({ modelId: e.target.value._id });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
    this.setState({ id: this.getID() });
    console.log(this.state);
    // console.log(this.props);
  }
  handlePush = data => {
    const newItem = [...this.state.item, data];
    this.setState({ item: newItem });
    console.log(this.state);
  };

  getID() {
    if (this.state.client !== "") {
      var idx = Math.round(+new Date() + Math.random() * 1000000);
      // console.log(this.state)
      // console.log(this.state.client)
      if (this.state.item.length > 0) {
        return (
          "PCON-ECR-" +
          this.state.client.clientCode +
          "-" +
          this.state.item[0].principleName.principalCode +
          "-" +
          (idx % 10000)
        );
      } else {
        return (
          "PCON-ECR-" + this.state.client.clientCode + "-NA-" + (idx % 10000)
        );
      }
    } else {
      return "";
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      btnDisabled: true
    });
    // console.log(this.state);
    if (this.state.id !== "") {
      // this.state["id"] = id;
      this.setState({
        senderLoader: true
      });
      axios
        .post(
          "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/ecr",
          this.state
        )
        .then(results => {
          this.props.history.push("/ecr");
        })
        .catch(err => {
          console.log(err);
          this.setState({
            btnDisabled: false
          });
        });
    }
  };

  handleRemoveItem = index => {
    console.log("Here");
    if (index > -1) {
      let newItem = this.state.item;
      newItem.splice(index, 1);
      console.log(newItem);
      this.setState({ item: newItem });
    }
  };

  dateHandleChange = date => {
    this.setState({
      dateOfSubmission: `${date.getFullYear()}-${
        String(date.getMonth() + 1).length < 2
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`
      }-${
        String(date.getDate()).length < 2
          ? `0${date.getDate()}`
          : `${date.getDate()}`
      }`
    });
    // console.log(date);
    // console.log(
    //   `${date.getFullYear()}-${
    //     (String(date.getMonth()+1).length) < 2
    //       ? `0${date.getMonth() + 1}`
    //       : `${date.getMonth() + 1}`
    //   }-${date.getDate()}`
    // );
    // console.log(String(date.getMonth()+1).length);
  };

  nextdateHandleChnage = date => {
    this.setState({
      nextVisitDate: `${date.getFullYear()}-${
        String(date.getMonth() + 1).length < 2
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`
      }-${
        String(date.getDate()).length < 2
          ? `0${date.getDate()}`
          : `${date.getDate()}`
      }`
    });
  };

  render() {
    const {
      client,
      clientId,
      facility,
      facilityAddress,
      dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      visitedBy,
      purposeOfVisit,
      referrenceDocument,
      nextVisitDate,
      visitedWith,
      visitStatus,
      jointVisitwith,
      item,
      ursComment,
      newFacility,
      btnDisabled,
      referenceNumber,
      senderLoader
    } = this.state;
    const values = {
      client,
      clientId,
      facility,
      facilityAddress,
      dateOfSubmission,
      contactName,
      contactNo,
      email,
      designation,
      visitedBy,
      purposeOfVisit,
      referrenceDocument,
      nextVisitDate,
      visitedWith,
      visitStatus,
      jointVisitwith,
      item,
      ursComment,
      newFacility,
      btnDisabled,
      referenceNumber,
      senderLoader
    };
    return (
      <div className="fullwidth">
        <React.Fragment>
          <PrimarySearchAppBar title="Add New Effective Call Report" />
        </React.Fragment>
        <React.Fragment>
          {/* {this.state.senderLoader === false ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Loader />
            </div>
          ) : ( */}
          <ECRForm
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            handlePush={this.handlePush}
            values={values}
            handleRemoveItem={this.handleRemoveItem}
            dateHandleChange={this.dateHandleChange}
            nextdateHandleChnage={this.nextdateHandleChnage}
          />
          {/* )} */}
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(AddECR);
