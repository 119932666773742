import React, { Component } from "react";
import PrincipalTable from "./PrincipalTable";
import CreatePrincipal from "./CreatePrincipal";
import "../users/user.css";
export class PrincipalView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addPrincipal: false
    };
  }

  principalAdded = () => {
    this.setState({ addPrincipal: true });
    console.log("Added");
  };

  principalAddDone = () => {
    this.setState({ addPrincipal: false });
    console.log("Done");
  };
  render() {
    return (
      <div style={styles.root}>
        <div style={styles.tableContainer}>
          <PrincipalTable
            nameinbtn={"+ Add New Principal"}
            url="/#"
            addedData={this.state.addPrincipal}
            done={this.principalAddDone}
            getData={
              "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/principal"
            }
          />
        </div>
        <div className="CreateContainer">
          <CreatePrincipal result={this.principalAdded} />
        </div>
      </div>
    );
  }
}

const styles = {
  root: {
    width: "100%",
    display: "flex",
    // justifyContent: "space-between",
    flexWrap: "wrap"
  },
  tableContainer: {
    minWidth: "70%"
  }
  // CreateContainer: {
  //   display: "flex",
  //   justifyContent: "flex-end",
  //   flexWrap: "wrap",
  //   marginTop: "14px"
  // }
};

export default PrincipalView;
