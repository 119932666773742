import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
//login components
import NewloginComponent from "./components/login/NewloginComponent";
import ForgotPasswordComponent from "./components/login/ForgotPasswordComponent";
//navbar
import NavbarNew from "./components/layouts/navbarLayout/NavbarNew";
import Navbar from "./components/layouts/navbarLayout/Navbar";
// import withAuth from './withAuth';
import { auth } from "./firebase";
import "firebase/auth";
//users
import { user } from "./controllers/utilities";
//signin and signout
import { signIn } from "./controllers/login";
//axios
import axios from "axios";
//Dashboard View
import DashView from "./components/layouts/dashviewModel/DashView";

//Add ECR Process
import AddECR from "./components/ecr/addECRForm/AddECR";
//viewecr & edit ECR
import ViewECRFormDetails from "./components/ecr/addECRForm/ViewECRFormDetails";

//Add URS Process
import AddURS from "./components/urs/addURSForm/AddURS";
//view URS Inputs & edit URS Inputs
import ViewURSFormDetails from "./components/urs/addURSForm/ViewURSFormDetails";

//Add offer
import AddOffer from "./components/offers/addOfferForm/AddOffer";
//offer view
import ViewOfferFormDetails from "./components/offers/addOfferForm/ViewOfferFormDetails";

//Add Service Process
import AddService from "./components/service/addServiceForm/AddService";
//viewService & edit Service
import ViewServiceFormDetails from "./components/service/addServiceForm/ViewServiceFormDetails";

//add pending bill
import AddPendingBill from "./components/pendingbills/addPendingBillForm/AddPendingForm";
//view and edit pending bill
import ViewPendingBillFormDetails from "./components/pendingbills/addPendingBillForm/ViewPendingFormDetails";

//add pending bill
import AddPendingBillInt from "./components/pendingbills/addPendingBillIntForm/AddPendingIntForm";
//view and edit pending bill
import ViewPendingBillIntFormDetails from "./components/pendingbills/addPendingBillIntForm/ViewPendingIntFormDetails";

// Report View model
import ReportViewModel from "./components/layouts/viewModelReports/ReportsModelView";

//{{{{{Full Admin View Only}}}}}
//admin view model
import AdminView from "./components/admin/AdminView";

//approval login from admin
import ApprovalLoginComponenet from "./components/login/ApprovalLoginComponent";

//user view Model
import UserViewModel from "./components/layouts/viewModelUsers/UserViewModel";

//user Principal Model
import PrincipalViewModel from "./components/layouts/viewModelPrincipals/PrincipalViewModel";

//Product view Model
import ProductViewModel from "./components/layouts/viewModelProducts/ProductViewModel";

//Client view Model
import ClientViewModel from "./components/layouts/viewModelClients/ClientViewModel";
//Combined Views
import ViewModel from "./components/layouts/viewModel/newViewModel";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastAction: "",
      result: false,
      submit: false,
      loggedIn: false,
      admin: false,
      authCheck: false,
      procurement: false,
    };
    //Disable Console Log
    // console.log = function() {}
    this.managementLogin = this.managementLogin.bind(this);
  }

  setStateforUser(user) {
    let { name, email, phoneNumber, photoURL, refreshToken, uid } = user;
    this.setState(() => {
      return {
        name,
        email,
        loggedIn: true,
        phoneNumber,
        photoURL,
        refreshToken,
        uid,
        errorCode: null,
        errorMessage: null,
        lastAction: "loginSubmit",
      };
    });
    // console.log(this.state);
    axios
      .get(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/user/${this.state.uid}`
      )
      .then((result) => {
        const { permission, designation } = result.data;
        this.setState({ designation: designation });
        this.setState({ authCheck: true });
        // console.log(result.data);
        // console.log(permission);
        if (permission === "Management Team") {
          this.setState({ admin: true });
        } else if (permission === "Procurement Team") {
          this.setState({ procurement: true });
        } else {
          this.setState({ admin: false });
        }
        // console.table(this.state);
      });
    this.setState({
      result: true,
      submit: true,
    });
    // console.log("User Found");
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // console.log(user);
        this.setStateforUser(user);
        // console.log("Logged in");
      } else {
        this.setState({ authCheck: true });
      }
    });
  }
  loginChecker = (email, password) => {
    signIn(email, password)
      .then(() => {
        console.log(user);
        if (user.currentUser != null) {
          this.setStateforUser(user.currentUser);
        }
      })
      .catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;
        this.setState(() => {
          return {
            errorCode: errorCode,
            errorMessage: errorMessage,
            lastAction: "loginSubmit",
          };
        });
        this.setState({
          result: false,
          submit: true,
        });
      });
  };

  managementLogin = () => {
    this.setState({ admin: true, loggedIn: true });
  };
  render() {
    return this.state.authCheck ? (
      <div className="Views">
        <React.Fragment>
          {/* Login */}
          <Switch>
            <Route exact path="/">
              <NewloginComponent
                submit={this.state.submit}
                result={this.state.result}
                loginChecker={this.loginChecker}
              />
            </Route>
            <Route exact path="/email/:id">
              <ApprovalLoginComponenet
                submit={this.state.submit}
                result={this.state.result}
                loginChecker={this.loginChecker}
              />
            </Route>
            <Route exact path="/forgotpass">
              <ForgotPasswordComponent />
            </Route>
            {/* Admin Login */}
            {this.state.loggedIn && this.state.admin ? (
              <React.Fragment>
                <React.Fragment>
                  <Navbar />
                </React.Fragment>
                <React.Fragment>
                  {/* Navigation routes */}
                  <Route exact path="/sales">
                    <DashView
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  <Route exact path="/admin">
                    <AdminView
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  {/* ECR */}
                  <Route exact path="/ecr">
                    <ViewModel feature={"ecr"} />
                  </Route>
                  <Route exact path="/addecr">
                    <AddECR email={this.state.email} name={this.state.name} />
                  </Route>
                  <Route exact path="/viewecr">
                    <ViewECRFormDetails
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  {/* URS */}
                  <Route exact path="/urs">
                    <ViewModel feature={"urs"} />
                  </Route>
                  <Route exact path="/addURS">
                    <AddURS
                      email={this.state.email}
                      designation={this.state.designation}
                    />
                  </Route>
                  <Route exact path="/viewurs">
                    <ViewURSFormDetails
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  {/* Offers */}
                  <Route exact path="/offers">
                    <ViewModel feature={"offers"} />
                  </Route>
                  <Route exact path="/addOffer">
                    <AddOffer email={this.state.email} name={this.state.name} />
                  </Route>
                  <Route exact path="/viewoffers">
                    <ViewOfferFormDetails
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  {/* Services */}
                  <Route exact path="/services">
                    <ViewModel feature={"services"} />
                  </Route>
                  <Route exact path="/addService">
                    <AddService
                      email={this.state.email}
                      designation={this.state.designation}
                    />
                  </Route>
                  <Route exact path="/viewservices">
                    <ViewServiceFormDetails
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  {/* pending bills */}
                  <Route exact path="/pendingbills">
                    {/* <ViewModel feature={"bills"} /> */}
                    <ViewModel feature={"bills"} />
                  </Route>
                  <Route exact path="/addPendingbills">
                    <AddPendingBill
                      email={this.state.email}
                      designation={this.state.designation}
                    />
                  </Route>
                  <Route exact path="/viewpendingbills">
                    <ViewPendingBillFormDetails
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>

                  {/*Int pending bills */}
                  <Route exact path="/pendingbillsint">
                    <ViewModel feature={"ibills"} />
                  </Route>
                  <Route exact path="/addPendingbillsint">
                    <AddPendingBillInt
                      email={this.state.email}
                      designation={this.state.designation}
                    />
                  </Route>
                  <Route exact path="/viewpendingbillsint">
                    <ViewPendingBillIntFormDetails
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>

                  {/* Admin modules */}
                  <Route exact path="/clients">
                    <ClientViewModel />
                  </Route>
                  <Route exact path="/principals">
                    <PrincipalViewModel />
                  </Route>
                  <Route exact path="/products">
                    <ProductViewModel />
                  </Route>
                  <Route exact path="/users">
                    <UserViewModel />
                  </Route>
                  {/* statistics report */}
                  {/* test */}
                  <Route exact path="/test">
                    <ViewModel feature={"urs"} />
                  </Route>
                  <Route exact path="/stats">
                    <ReportViewModel />
                  </Route>
                  {/* statistics report */}
                </React.Fragment>
                {/* Bottom Nav */}
                <React.Fragment>
                  <NavbarNew />
                </React.Fragment>
              </React.Fragment>
            ) : (
              ""
            )}
            {/* Procurement Staff Login */}
            {this.state.loggedIn && this.state.procurement ? (
              <React.Fragment>
                <React.Fragment>
                  <Navbar />
                </React.Fragment>
                <React.Fragment>
                  <Route exact path="/sales">
                    <DashView
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  <Route exact path="/admin">
                    <AdminView
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  {/* ECR */}
                  <Route exact path="/ecr">
                    <ViewModel feature={"ecr"} />
                  </Route>
                  <Route exact path="/addecr">
                    <AddECR email={this.state.email} name={this.state.name} />
                  </Route>
                  <Route exact path="/viewecr">
                    <ViewECRFormDetails admin={this.state.admin} />
                  </Route>
                  {/* URS */}
                  <Route exact path="/urs">
                    <ViewModel feature={"urs"} />
                  </Route>
                  <Route exact path="/addURS">
                    <AddURS
                      email={this.state.email}
                      designation={this.state.designation}
                    />
                  </Route>
                  <Route exact path="/viewurs">
                    <ViewURSFormDetails
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  {/* OFFERS */}
                  <Route exact path="/offers">
                    <ViewModel feature={"offers"} />
                  </Route>
                  <Route exact path="/addOffer">
                    <AddOffer email={this.state.email} />
                  </Route>
                  <Route exact path="/viewoffers">
                    <ViewOfferFormDetails
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  {/* SERVICES */}
                  <Route exact path="/services">
                    <ViewModel feature={"services"} />
                  </Route>
                  <Route exact path="/addService">
                    <AddService
                      email={this.state.email}
                      designation={this.state.designation}
                    />
                  </Route>
                  <Route exact path="/viewservices">
                    <ViewServiceFormDetails
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  {/* PENDING BILLS */}
                  <Route exact path="/pendingbills">
                    <ViewModel feature={"bills"} />
                  </Route>
                  <Route exact path="/addPendingbills">
                    <AddPendingBill
                      email={this.state.email}
                      designation={this.state.designation}
                    />
                  </Route>
                  <Route exact path="/viewpendingbills">
                    <ViewPendingBillFormDetails
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>

                  <Route exact path="/clients">
                    <ClientViewModel />
                  </Route>
                  <Route exact path="/principals">
                    <PrincipalViewModel />
                  </Route>
                  <Route exact path="/products">
                    <ProductViewModel />
                  </Route>
                  <Route exact path="/users">
                    <UserViewModel />
                  </Route>
                  <Route exact path="/stats">
                    <ReportViewModel />
                  </Route>
                </React.Fragment>
                <React.Fragment>
                  <NavbarNew />
                </React.Fragment>
              </React.Fragment>
            ) : (
              ""
            )}
            {/* Marketing Team Members Login */}
            {this.state.loggedIn ? (
              <React.Fragment>
                <React.Fragment>
                  <Navbar />
                </React.Fragment>

                <React.Fragment>
                  <Route exact path="/sales">
                    <DashView
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  <Route exact path="/admin">
                    <AdminView
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>

                  {/* ECR */}
                  <Route exact path="/ecr">
                    <ViewModel feature={"ecr"} />
                  </Route>
                  <Route exact path="/addecr">
                    <AddECR email={this.state.email} />
                  </Route>
                  <Route exact path="/viewecr">
                    <ViewECRFormDetails />
                  </Route>
                  {/* Offers */}
                  <Route exact path="/offers">
                    <ViewModel feature={"offers"} />
                  </Route>
                  <Route exact path="/addOffer">
                    <AddOffer email={this.state.email} />
                  </Route>
                  <Route exact path="/viewoffers">
                    <ViewOfferFormDetails
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>
                  {/* Services */}
                  <Route exact path="/services">
                    <ViewModel feature={"services"} />
                  </Route>
                  <Route exact path="/addService">
                    <AddService
                      email={this.state.email}
                      designation={this.state.designation}
                    />
                  </Route>
                  <Route exact path="/viewservices">
                    <ViewServiceFormDetails
                      admin={this.state.admin}
                      procurement={this.state.procurement}
                    />
                  </Route>

                  <Route exact path="/products">
                    <ProductViewModel />
                  </Route>
                  <Route exact path="/clients">
                    <ClientViewModel />
                  </Route>

                  <React.Fragment>
                    <NavbarNew />
                  </React.Fragment>
                </React.Fragment>
              </React.Fragment>
            ) : (
              <Redirect to="/" />
            )}
          </Switch>
        </React.Fragment>
      </div>
    ) : (
      ""
    );
  }
}

export default withRouter(App);
