import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
// selects
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
//For Radio Buttons
import FormControl from "@material-ui/core/FormControl";
//axios import
import axios from "axios";
export class CreateNewOfferRight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urs_list: [],
      ursID:''
    };

    //    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    axios
      .get(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/urs"
      )
      .then(urs => {
        this.setState({
          urs_list: urs.data
        });
        console.log(urs.data);
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleChange = (e) =>  {

      this.setState({
        ursID: e.target.value
      },() => this.props.handleSelectURS(this.state.ursID));
    }

  

  render() {
    return (
      <div style={styles.container}>
        <React.Fragment>
          <Paper style={styles.secondaryrootNew} square={true}>
            <React.Fragment>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Reference URS Name
                </InputLabel>
                <Select
                  label="Reference URS Name"
                  required
                  value={this.state.ursID}
                  onChange={this.handleChange}
                  name="ursID"
                  displayEmpty
                  style={styles.textFieldSelect}
                >
                  {this.state.urs_list.map(urs => (
                    urs.offerDone ? 
                    <MenuItem value={urs._id}>{urs.id}</MenuItem>
                    :
                    ''
                  ))}
                </Select>
              </FormControl>
            </React.Fragment>
            <div>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={styles.button}
              >
                Create New Offer
              </Button>
              <Typography
                variant="p"
                component="p"
                style={{ marginTop: "8px", color: "#757575", fontSize: "12px" }}
              >
                CREATE NEW OFFER will start tracking a new offer from the moment
                of submission and will allows users to update its status till
                its final closing phase.
              </Typography>
            </div>
          </Paper>
        </React.Fragment>
      </div>
    );
  }
}

const styles = {
  root: {
    padding: "16px 16px",
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between"
  },
  secondaryroot: {
    padding: "16px 16px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center"
  },
  secondaryrootNew: {
    padding: "16px 16px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "8px"
  },
  typographyroot: {
    background: "#FAFAFA",
    color: "#757575",
    fontSize: "10px",
    display: "flex",
    padding: "16px 16px",
    fontWeight: "700",
    textTransform: "uppercase"
  },
  container: {
    //width: "30%", //edit out
    displa: "flex",
    background: "#375A6D",
    borderRadius: "5px",
    height: "fit-content"
    //margin: "40px" //edit out
  },
  typographyHeader: {
    display: "flex",
    alignItems: "center",
    color: "#424242"
  },
  button: {
    marginTop: 16,
    width: "100%",
    color: "white"
  },
  extras: {
    marginTop: 8,
    marginBottom: 8
  },
  label: {
    fontSize: 12
  },
  typograghies: {
    fontWeight: "700",
    fontSize: 12
  }
};

export default CreateNewOfferRight;
