import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import firebase from "firebase";
import Paper from "@material-ui/core/Paper";
import ViewPendingBillBaseComponent from "../pendingFormRightColumn/ViewPendingBillBaseComponent";
// import axios from "axios";
import GetAppIcon from "@material-ui/icons/GetApp";
import BalanceModal from "./Balancemodal";
import axios from "axios";

var storage = firebase.storage();
var storageRef = storage.ref();

class ImgMediaCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      paymentType: "",
      paymentDetails: "",
      currency: "৳ BDT",
      amount: "",
      upload: ""
    };
  }

  handleFile = e => {
    this.setState({
      upload: e.target.files[0]
    });

    console.log(e.target.files[0]);
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      paymentType,
      paymentDetails,
      currency,
      amount,
      upload
    } = this.state;

    if (amount > this.props.values.totalDue) {
      alert("Amount is Greater then the Due");
    } else {
      if (upload !== "") {
        var uploadTask = storageRef
          .child(`bills/${new Date().toJSON()}_${upload.name}`)
          .put(upload);
        uploadTask.then(dataReturned => {
          dataReturned.ref.getDownloadURL().then(url => {
            const val = {
              paymentType,
              paymentDetails,
              currency,
              amount,
              upload: url,
              totalDue: this.props.values.totalDue - this.state.amount
            };
            console.log(
              "Here are the states" + amount + " " + this.props.values.totalDue
            );

            console.log(val);
            axios
              .put(
                `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/billspayment/${this.props.values._id}`,
                val
              )
              .then(result => {
                console.log(result);
                this.setState({
                  open: false,
                  paymentType: "",
                  paymentDetails: "",
                  currency: "৳ BDT",
                  amount: ""
                });
                this.props.updateTable();
              })
              .catch(err => {
                console.log(err);
              });
          });
        });
      } else {
        const val = {
          paymentType,
          paymentDetails,
          currency,
          amount,
          totalDue: this.props.values.totalDue - this.state.amount
        };
        console.log(
          "Here are the states" + amount + " " + this.props.values.totalDue
        );

        console.log(val);
        axios
          .put(
            `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/billspayment/${this.props.values._id}`,
            val
          )
          .then(result => {
            console.log(result);
            this.setState({
              open: false,
              paymentType: "",
              paymentDetails: "",
              currency: "৳ BDT",
              amount: ""
            });
            this.props.updateTable();
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  };

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };
  handleClose = () => {
    this.setState({
      paymentType: "",
      paymentDetails: "",
      currency: "৳ BDT",
      amount: "",
      open: false
    });
  };

  render() {
    const { values, handleRemove, handleEditClick } = this.props;
    console.log(values);

    const { paymentType, paymentDetails, currency, amount } = this.state;
    const val = {
      paymentType,
      paymentDetails,
      currency,
      amount
    };

    return (
      <div>
        <Card style={styles.divStyle} square={true} elevation={1}>
          <CardActionArea>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="p" style={styles.typographyvariant}>
                Bill ID: {values.id}
              </Typography>
              <Typography variant="p" style={styles.typographyvariant}>
                Created On:
                {this.props.values.createdAt !== undefined
                  ? new firebase.firestore.Timestamp(
                      this.props.values.createdAt._seconds,
                      this.props.values.createdAt._nanoseconds
                    )
                      .toDate()
                      .toString()
                      .substring(0, 15)
                  : "Not Available"}
              </Typography>
            </CardContent>
          </CardActionArea>
          {this.props.admin || this.props.procurement ? (
            <CardActions>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={handleRemove}
                disabled={!this.props.admin}
              >
                delete
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleEditClick}
                disabled={!this.props.admin}
              >
                edit
              </Button>
            </CardActions>
          ) : (
            ""
          )}
        </Card>
        <BalanceModal
          values={val}
          open={this.state.open}
          handleClose={this.handleClose}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleFile={this.handleFile}
          // updateTable={this.props.updateTable}
        />
        <React.Fragment>
          <Paper square={true} elevation={1} style={{ padding: "8px" }}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              onClick={this.handleClickOpen}
            >
              ADD NEW PAYMENT
            </Button>
          </Paper>
        </React.Fragment>
        <React.Fragment>
          <Paper
            //style={styles.secondaryroot}
            square={true}
            style={{ paddingTop: 8 }}
          >
            <div style={styles.divStyle}>
              <Typography variant="p" style={styles.typographyvariant}>
                PAYMENT LOG
              </Typography>
            </div>
            <div style={{ padding: "16px" }}>
              {this.props.values.progress.map(log => (
                <ViewPendingBillBaseComponent data={log} />
              ))}
            </div>
          </Paper>
        </React.Fragment>
        <React.Fragment>
          <Paper
            //style={styles.secondaryroot}
            square={true}
            style={{ padding: 16 }}
          >
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href={values.uploadFile}
              //target="_blank"
              style={{
                //textDecoration: "none",
                fontSize: "12px",
                display: "flex",
                alignItems: "center"
              }}
            >
              Initial Bills Document <GetAppIcon />
            </a>
          </Paper>
        </React.Fragment>
      </div>
    );
  }
}

const styles = {
  // typographyvariant: {
  //   fontWeight: "700",
  //   fontSize: "12px",
  //   marginLeft: "20px"
  // },
  // divStyle: {
  //   // borderTop: "5px solid #161319",
  //   color: "#757575",
  //   letterSpacing: "2px",
  //   display: "flex",
  //   flexDirection: "column",
  //   width: "100%",
  //   marginBottom: 0
  //   // borderBottom:"1px solid white"
  // },

  divStyle: {
    width: "100%",
    background: "rgba(224, 224, 224, 0.25)",
    color: "#757575",
    letterSpacing: "2px",
    // height: "60px",
    display: "flex",
    alignItems: "center"
  },
  typographyvariant: {
    fontWeight: "700",
    fontSize: "12px",
    marginLeft: "20px",
    textTransform: "uppercase"
  }
};
export default ImgMediaCard;
