import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
//For Text Fields
import TextField from "@material-ui/core/TextField";
//Form Control
import FormControl from "@material-ui/core/FormControl";
//input label
import InputLabel from '@material-ui/core/InputLabel';
//Select
import { Select } from "@material-ui/core";
//MenuItem
import MenuItem from "@material-ui/core/MenuItem";
//paper
import Paper from "@material-ui/core/Paper";
//For Buttons
import Button from "@material-ui/core/Button";
//axios
import axios from "axios";
//typography
import Typography from "@material-ui/core/Typography";
class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      principal_list: [],
      productName: "",
      principal: {}
    };
  }

  // handleChange(e) {
  //   this.setState({[e.target.name]:e.target.value})
  //   this.props.handleChange()
  // }
  componentDidMount() {
    axios
      .get(
        "https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/principal"
      )
      .then(principals => {
        this.setState({
          principal_list: principals.data
        });
        console.log(principals.data);
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { values, handleChange, handleSubmit } = this.props;
    return (
      <MuiThemeProvider>
        <React.Fragment>
          <Paper style={styles.root} elevation={this.props.elevate}>
            <form style={styles.container} onSubmit={handleSubmit}>
              <React.Fragment>
                <Typography variant="h5">Add New Product</Typography>
              </React.Fragment>
              <React.Fragment>
                <FormControl required style={styles.formControl}>
                  <InputLabel id="simple-select-label">Principal</InputLabel>
                  <Select
                    label="Principal"
                    onChange={handleChange}
                    value={values.principal}
                    name="principal"
                    displayEmpty
                    style={styles.textFieldSelect}
                  >
                    <MenuItem value={""} disabled>
                      Principal
                    </MenuItem>
                    {this.state.principal_list.map(principal => (
                      <MenuItem value={principal}>
                        {principal.principalName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Product Name"
                  value={values.productName}
                  type="text"
                  onChange={handleChange}
                  margin="normal"
                  name="productName"
                  style={styles.textField}
                />
              </React.Fragment>
              <React.Fragment>
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  name="submit"
                  style={values.btnDisabled ? styles.buttonDisabled : styles.button}
                  disabled={values.btnDisabled}
                >
                  {this.props.submit}
                </Button>
              </React.Fragment>
            </form>
          </Paper>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

const styles = {
  button: {
    margin: 8,
    width: 350,
    background: "#8BC34A",
    color: "white"
  },
  buttonDisabled: {
    margin: 8,
    width: 350,
    background: "grey",
    color: "DimGray"
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    width: 350
  },
  textFieldSelect: {
    width: 350
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    width: "100%"
  },
  formControl: {
    marginTop: "16px",
    marginBottom: " 8px",
    width: 350
  },
  root: {
    padding: "24px 16px",
    display: "flex",
    flexWrap: "wrap"
  }
};

export default ProductForm;
