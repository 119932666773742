import React, { Component } from "react";
import ClientEditForm from "./ClientEditForm";
import axios from "axios";
import { withRouter } from "react-router-dom";

export class EditClient extends Component {
  done = false;
  constructor(props) {
    super(props);
    this.state = {
      clientName: this.props.currentData.clientName,
      clientCode: this.props.currentData.clientCode,
      clientCountry: this.props.currentData.clientCountry,
      btnDisabled: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    // console.log(this.state._id);
  };
  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      btnDisabled: true
    });
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${this.props.currentData._id}`,
        this.state
      )
      .then(result => {
        // console.log(result);
        this.props.updateTable();
      })
      .catch(err => {
        // console.log(err);
        this.setState({
          btnDisabled: false
        });
      });
  }

  render() {
    const { clientName, clientCode, clientCountry } = this.state;
    const values = {
      clientName,
      clientCode,
      clientCountry
    };

    return (
      <div style={styles.fullwidth}>
        <div>
          <React.Fragment>
            <ClientEditForm
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              values={values}
              submit="Submit"
              elevate={0}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}
const styles = {
  fullwidth: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around"
  }
};

export default withRouter(EditClient);
