import React, { Component } from "react";
import PrimarySearchAppBar from "../../layouts/appbarComponent/Appbar";
import axios from "axios";
import { withRouter } from "react-router-dom";
import ViewURSInputs from "./ViewURSFormDetailsInputs";
import EditURSInputs from "./EditURSFormDetailsInputs";
import "./styles.css";
import ImgMediaCard from "./DeleteViewURSCard";

import Button from "@material-ui/core/Button";
import ReactToPrint from "react-to-print";
import Print from "rc-print";
import PrintIcon from "@material-ui/icons/Print";
export class ViewURSFormDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.location.state,
      data: false,
      edit: false,
      initwidth: "",
      initmargin: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePush = this.handlePush.bind(this);
    this.updateTable = this.updateTable.bind(this);
    console.log(this.state);
  }

  updateTable() {
    axios
      .get(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/urs/${this.state._id}`
      )
      .then((res) => {
        this.setState(res.data);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  }

  handleChange(e) {
    if (e.target.name === "client") {
      this.setState({ client: e.target.value });
      this.setState({ clientId: e.target.value._id });
    } else if (e.target.name === "facility") {
      console.log(e.target.value);
      this.setState({ facility: e.target.value });
      this.setState({ branchId: e.target.value._id });
      this.setState({ facilityAddress: e.target.value.branchAddress });
    } else if (e.target.name === "contactName") {
      console.log(e.target.value);
      this.setState({ contactName: e.target.value });
      this.setState({ contactId: e.target.value._id });
      this.setState({ contactNo: e.target.value.personnelContactno });
      this.setState({ designation: e.target.value.personnelDesignation });
      this.setState({ email: e.target.value.personnelEmail });
    } else if (e.target.name === "principleName") {
      this.setState({ principleName: e.target.value });
      this.setState({ principleId: e.target.value._id });
    } else if (e.target.name === "productName") {
      this.setState({ productName: e.target.value });
      this.setState({ productId: e.target.value._id });
    } else if (e.target.name === "model") {
      this.setState({ model: e.target.value });
      this.setState({ modelId: e.target.value._id });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    console.log(this.state);
    // console.log(this.props);
  }
  handlePush = (data) => {
    const newItem = [...this.state.item, data];
    this.setState({ item: newItem });
    console.log(this.state);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      btnDisabled: true,
    });
    axios
      .put(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/urs/${this.state._id}`,
        this.state
      )
      .then((results) => {
        console.log(results);
        this.setState({ edit: false, btnDisabled: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          btnDisabled: false,
        });
      });
  };

  componentWillMount() {
    console.log("Here");
    if (this.props.location.state === undefined) {
      this.data = true;
    }
  }
  handleEditClick = () => {
    this.setState({ edit: true });
  };

  handleBackClick = () => {
    this.setState({ edit: false });
  };

  handleRemove = () => {
    axios
      .delete(
        `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/urs/${this.props.location.state._id}`
      )
      .then((result) => {
        this.props.history.push("/urs");
      })
      .catch((err) => console.log(err));
  };

  handleRemoveItem = (index) => {
    console.log("Here");
    if (index > -1) {
      let newItem = this.state.item;
      newItem.splice(index, 1);
      console.log(newItem);
      this.setState({ item: newItem });
    }
  };

  nextdateHandleChange = (date) => {
    this.setState({
      SubmissionDeadline: `${date.getFullYear()}-${
        String(date.getMonth() + 1).length < 2
          ? `0${date.getMonth() + 1}`
          : `${date.getMonth() + 1}`
      }-${
        String(date.getDate()).length < 2
          ? `0${date.getDate()}`
          : `${date.getDate()}`
      }`,
    });
  };

  render() {
    const {
      _id,
      client,
      clientId,
      facility,
      facilityAddress,
      //dateOfSubmission,
      approvalDate,
      SubmissionDeadline,
      createdAt,
      contactName,
      contactNo,
      email,
      designation,
      ursCreator,
      item,
      ursComment,
      newURSC,
      approval,
      offerDone,
      sentPC,
      sentPCDate,
      creatorDesig,
      finalQuotate,
      finalDate,
      newFacility,
      btnDisabled,
      CollectedBy,
      referenceNumber,
      id,
      currency,
      currencyRate,
    } = this.state;

    const values = {
      _id,
      client,
      clientId,
      facility,
      facilityAddress,
      approvalDate,
      //dateOfSubmission,
      SubmissionDeadline,
      createdAt,
      contactName,
      contactNo,
      email,
      designation,
      ursCreator,
      item,
      ursComment,
      creatorDesig,
      newURSC,
      approval,
      offerDone,
      sentPC,
      sentPCDate,
      finalQuotate,
      finalDate,
      newFacility,
      btnDisabled,
      CollectedBy,
      referenceNumber,
      id,
      currency,
      currencyRate,
    };
    if (this.state.edit) {
      return (
        <div className="fullwidth">
          <React.Fragment>
            <PrimarySearchAppBar title="Edit User Requirement Specification" />
          </React.Fragment>
          <React.Fragment>
            <div className="ViewURS">
              <EditURSInputs
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                handlePush={this.handlePush}
                values={values}
                handleRemoveItem={this.handleRemoveItem}
                handleBackClick={this.handleBackClick}
                nextdateHandleChange={this.nextdateHandleChange}
              />
            </div>
          </React.Fragment>
        </div>
      );
    }
    return (
      <div className="fullwidth">
        <React.Fragment>
          <PrimarySearchAppBar title="User Requirement Specification View" />
        </React.Fragment>
        <React.Fragment>
          <div>
            <ReactToPrint
              onBeforeGetContent={() =>
                this.setState({
                  initwidth: "100%",
                  initmargin: "16px",
                })
              }
              onAfterPrint={() => {
                this.setState({ initwidth: "", initmargin: "" });
              }}
              trigger={() => {
                console.log(this.state.initwidth);
                return (
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    name="submit"
                    color="primary"
                    style={{ color: "white", marginBottom: 8 }}
                  >
                    <PrintIcon />
                  </Button>
                );
              }}
              content={() => this.componentRef}
            />
          </div>
          <Print
            ref="test"
            title=""
            insertHead={true}
            isIframe={true}
            otherStyle="@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
          >
            <div
              className="ViewURS"
              ref={(el) => (this.componentRef = el)}
            >
              <ViewURSInputs values={values} initwidth={this.state.initwidth} />
              <ImgMediaCard
                values={values}
                handleEditClick={this.handleEditClick}
                handleRemove={this.handleRemove}
                admin={this.props.admin}
                procurement={this.props.procurement}
                updateTable={this.updateTable}
                initwidth={this.state.initwidth}
              />
            </div>
          </Print>
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(ViewURSFormDetails);
