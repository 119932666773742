import { auth } from "../firebase";
import 'firebase/auth';





export const signIn = (email, password) => auth.signInWithEmailAndPassword(email, password);

export const resetEmail = (emailAddress) => auth.sendPasswordResetEmail(emailAddress);

export const signOut = () => auth.signOut();