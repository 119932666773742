
const _firebaseConfig = {
    apiKey: "AIzaSyD7q0u4z7QncmHo2wJllrRqmqHLq8M2rR0",
    authDomain: "scaleforceelo.firebaseapp.com",
    databaseURL: "https://scaleforceelo.firebaseio.com",
    projectId: "scaleforceelo",
    storageBucket: "scaleforceelo.appspot.com",
    messagingSenderId: "1038559020138",
    appId: "1:1038559020138:web:e5a41f19ea58125f87f613",
    measurementId: "G-30096601ZP"

};

export default _firebaseConfig;