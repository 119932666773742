// import React, { Component } from "react";
// import Newlogin from "./ApprovalLogin";
// // import { signIn } from "../../controllers/login";
// import { user } from "../../controllers/utilities";
// import CustomizedSnackbars from "../snackbars/CustomizedSnackbars";
// import { withRouter, Redirect } from "react-router-dom";
// import { signIn } from "../../controllers/login";
// import axios from "axios";

// export class ApprovalLoginComponent extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       email: "",
//       password: "",
//       lastAction: "",
//       result: false,
//       submit: false,
//       data:{},
//       loggedIn: false,
//       content:false
//     };
//   }

//   checkUser = () => {
//     if (user.currentUser != null) {
//       const { email } = user.currentUser;
//       console.log(email);
//     } else {
//       console.log("noUser to show");
//     }
//   };
//   componentDidMount(){
//     const { id } = this.props.match.params
//     console.log(id);
//     axios.get(`https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/urs/${id}`)
//     .then((res)=> this.setState({data:res.data,content:true}))
//     .catch((err)=> this.setState({content: false}));
//   }

//   componentWillReceiveProps(nextProps){
//     this.setState({
//       submit:nextProps.submit,
//       result:nextProps.result
//     })
//   }

//   // logout = () => {
//   //     signOut().then(() => {
//   //         this.setState(() => {
//   //             return {
//   //                 errorCode: null,
//   //                 errorMessage: null,
//   //                 loggedIn: false,
//   //                 lastAction: 'logOut'
//   //             }
//   //         })
//   //     })
//   //         .catch((error) => {
//   //             let errorCode = error.code;
//   //             let errorMessage = error.message;
//   //             this.setState(() => {
//   //                 return {
//   //                     errorCode: errorCode,
//   //                     errorMessage: errorMessage,
//   //                     lastAction: 'logOut'
//   //                 }
//   //             })
//   //         }).then(() => console.log(this.state, user.currentUser))
//   // }

//   //handle changes of inputs
//   handleChange = e => {
//     this.setState({ submit: false });
//     const { name, value } = e.target;
//     this.setState({ [name]: value });
//   };

//   //handle Submit of form
//   handleSubmit = e => {
//     e.preventDefault();
//     // signIn(this.state.email, this.state.password)
//     //   .then(() => {
//     //     if (user.currentUser != null) {
//     //       let {
//     //         name,
//     //         email,
//     //         phoneNumber,
//     //         photoURL,
//     //         refreshToken,
//     //         uid
//     //       } = user.currentUser;
//     //       this.setState(() => {
//     //         return {
//     //           name,
//     //           email,
//     //           loggedIn: true,
//     //           phoneNumber,
//     //           photoURL,
//     //           refreshToken,
//     //           uid,
//     //           errorCode: null,
//     //           errorMessage: null,
//     //           lastAction: "loginSubmit"
//     //         };
//     //       });
//     //       axios
//     //       .get(
//     //         `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/user/${this.state.uid}`
//     //       )
//     //       .then(result => {
//     //         const { permission, designation } = result.data;
//     //         this.setState({ designation: designation });
//     //         console.log(permission);
//     //         if (permission === "Management Team") {
//     //           this.props.managementLogin()
//     //           this.setState({  result:true, submit: true });
//     //         } else if (permission === "Procurement Team") {
//     //          console.log("Procurement");
//     //         } else {
//     //           console.log("Marketing");
//     //         }
//     //       });
         
//     //     }
//     //   })
//     //   .catch(error => {
//     //     let errorCode = error.code;
//     //     let errorMessage = error.message;
//     //     this.setState(() => {
//     //       return {
//     //         errorCode: errorCode,
//     //         errorMessage: errorMessage,
//     //         lastAction: "loginSubmit"
//     //       };
//     //     });
//     //     this.setState({ result: false, loggedIn: true });
//     //   })
//     //   .then(() => console.log(this.state));

//     this.props.loginChecker(this.state.email,this.state.password);
//     this.props.history.push({
//       pathname: '/viewurs',
//       state: this.state.data
//     })
//   };

//   render() {
//     const { email, password } = this.state;
//     const { admin } = this.props;
//     const values = { email, password };
//     this.checkUser();
   

//     return (
//       <>
//         <div style={styles.fullwidth}>
//         {this.state.content ? 
//          ""
        
//         : 
//             <CustomizedSnackbars
//               type="error"
//               message="Content is no longer available"
//               show={this.state.submit}
//             />
          
//           }
//           {this.state.result ? (
//             <div>
//             <CustomizedSnackbars
//               type="success"
//               message="Logged In"
//               show={this.state.submit}
//             />
//             {this.state.loggedIn ?     
//           <Redirect
//           to={{
//             pathname: "/viewurs",
//             state: this.state.data
//           }}
//         />
//         :
//         ""}
        
//            </div>
//           ) : (
//             <CustomizedSnackbars
//               type="error"
//               message="Log in Falied"
//               show={this.state.submit}
//             />
//           )}

//           <Newlogin
//             handleChange={this.handleChange}
//             handleSubmit={this.handleSubmit}
//             forgotPassword={this.passwordReset}
//             values={values}
//           />
//         </div>
//       </>
//     );
//   }
// }
// const styles = {
//     fullwidth:{
//         width:'100%',
//     }
// };

// export default withRouter(ApprovalLoginComponent);
import React, { Component } from "react";
import ApprovalLogin from "./ApprovalLogin";
// import { signIn } from "../../controllers/login";
import { user } from "../../controllers/utilities";
import CustomizedSnackbars from "../snackbars/CustomizedSnackbars";
import { withRouter, Redirect } from "react-router-dom";
import axios from 'axios';

export class ApprovalLoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      lastAction: "",
      result: false,
      submit: false,
      content:true,
      data:{}
    };
  }

  checkUser = () => {
    if (user.currentUser != null) {
      const { email } = user.currentUser;
      console.log(email);
    } else {
      console.log("noUser to show");
    }
  };

    componentDidMount(){
    const { id } = this.props.match.params
    console.log(id);
    axios.get(`https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/urs/${id}`)
    .then((res)=>{console.log(res.data); this.setState({data:res.data,content:true})})
    .catch((err)=> this.setState({content: false}));
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      submit:nextProps.submit,
      result:nextProps.result
    })
  }

  // logout = () => {
  //     signOut().then(() => {
  //         this.setState(() => {
  //             return {
  //                 errorCode: null,
  //                 errorMessage: null,
  //                 loggedIn: false,
  //                 lastAction: 'logOut'
  //             }
  //         })
  //     })
  //         .catch((error) => {
  //             let errorCode = error.code;
  //             let errorMessage = error.message;
  //             this.setState(() => {
  //                 return {
  //                     errorCode: errorCode,
  //                     errorMessage: errorMessage,
  //                     lastAction: 'logOut'
  //                 }
  //             })
  //         }).then(() => console.log(this.state, user.currentUser))
  // }

  //handle changes of inputs
  handleChange = e => {
    this.setState({ submit: false });
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  //handle Submit of form
  handleSubmit = e => {
    e.preventDefault();
    // signIn(this.state.email, this.state.password)
    //   .then(() => {
    //     if (user.currentUser != null) {
    //       let {
    //         name,
    //         email,
    //         phoneNumber,
    //         photoURL,
    //         refreshToken,
    //         uid
    //       } = user.currentUser;
    //       this.setState(() => {
    //         return {
    //           name,
    //           email,
    //           loggedIn: true,
    //           phoneNumber,
    //           photoURL,
    //           refreshToken,
    //           uid,
    //           errorCode: null,
    //           errorMessage: null,
    //           lastAction: "loginSubmit"
    //         };
    //       });
    //       this.setState({ result: true, submit: true });
    //       console.log('User Found')
    //     }
    //   })
    //   .catch(error => {
    //     let errorCode = error.code;
    //     let errorMessage = error.message;
    //     this.setState(() => {
    //       return {
    //         errorCode: errorCode,
    //         errorMessage: errorMessage,
    //         lastAction: "loginSubmit"
    //       };
    //     });
    //     this.setState({ result: false, submit: true });
    //   })
    //   .then(() => console.log(this.state));

    this.props.loginChecker(this.state.email,this.state.password);
    // this.props.history.push({
    //   pathname: '/viewurs',
    //   state: this.state.data
    // })
  };

  render() {
    const { email, password } = this.state;
    const values = { email, password };
    this.checkUser();

    if(this.state.result){
      return(
        <Redirect
        to={{
          pathname: `/viewurs`,
          state: this.state.data
        }}
      />
      )
    }

    return (
      <>
        <div style={styles.fullwidth}>
          {this.state.result ? (
            <div>
            <CustomizedSnackbars
              type="success"
              message="Logged In"
              show={this.state.submit}
            />

           </div>
          ) : (
            <CustomizedSnackbars
              type="error"
              message="Log in Falied"
              show={this.state.submit}
            />
          )}

          <ApprovalLogin
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            forgotPassword={this.passwordReset}
            values={values}
          />
        </div>
      </>
    );
  }
}
const styles = {
    fullwidth:{
        width:'100%',
    }
};

export default withRouter(ApprovalLoginComponent);
