import React, { Component } from "react";
import AdminDashboard from "../../components/layouts/admindashboardLayout/AdminDashboard";

export class AdminView extends Component {
  render() {
    return (
      <div className="allViewContainers">
        <React.Fragment>
          <AdminDashboard 
             admin={this.props.admin}
             procurement={this.props.procurement}

          />
        </React.Fragment>
      </div>
    );
  }
}

export default AdminView;
