import React, { Component } from "react";
import { NavLink } from "react-router-dom";
//line divider
import Divider from "@material-ui/core/Divider";
//card
import Cards from "../dashboardCards/Cards";
//icons

import client from "../../imgs/Clients.svg";
import product from "../../imgs/Products.svg";
import user from "../../imgs/Users.svg";
import principal from "../../imgs/Principals.svg";

//Appbar
import PrimarySearchAppBar from "../appbarComponent/Appbar";

export class AdminDashboard extends Component {
  render() {
    return (
      <div style={styles.container}>
        <React.Fragment>
          <PrimarySearchAppBar title="Admin" />
        </React.Fragment>
        <div style={styles.innerComponent}>
          <div style={styles.dashboardCards}>
            <div className="titleTextAlinement">
              <p>Click on any module to execute your preferred action.</p>
              <Divider />
            </div>
            <div className="Cardrows">
              {this.props.admin || this.props.procurement ? (
                <React.Fragment>
                  <NavLink to="clients" style={styles.linkStyle}>
                    <Cards name={client} desc="Clients" />
                  </NavLink>
                  <NavLink to="principals" style={styles.linkStyle}>
                    <Cards name={principal} desc="Principals" />
                  </NavLink>
                  <NavLink to="products" style={styles.linkStyle}>
                    <Cards name={product} desc="Products" />
                  </NavLink>
                  <NavLink to="users" style={styles.linkStyle}>
                    <Cards name={user} desc="Users" />
                  </NavLink>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <NavLink to="clients" style={styles.linkStyle}>
                    <Cards name={client} desc="Clients" />
                  </NavLink>
                  <NavLink to="products" style={styles.linkStyle}>
                    <Cards name={product} desc="Products" />
                  </NavLink>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const styles = {
  linkStyle: {
    textDecoration: "none",
    color: "black",
  },
  containerComponent: {
    display: "flex",
    flexDirection: "column",
  },
  dashboardCards: {
    fontSize: "16px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  innerComponent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
};

export default AdminDashboard;
