import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { grey } from "@material-ui/core/colors";
// import axios from "axios";
//branch
import CreatePersonnel from "./CreatePersonnel";

const styles = {
  root: {
    margin: 0,
    padding: "16px"
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: grey
  },
  bigAvatar: {
    margin: 5,
    width: 70,
    height: 70
  },
  box: {
    display: "flex",
    alignItems: "center"
  },
  CreateModal:{
    minHeight: "470px",
    // minWidth: "550px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export default class CustomizedDialogs extends Component {
  // handleRemove = () => {
  //   axios
  //     .delete(
  //       `https://asia-east2-scaleforceelo.cloudfunctions.net/widgets/client/${this.props.currentData._id}/personnel/{}`
  //     )
  //     .then(result => {
  //       console.log(result);
  //       this.props.updateTable();
  //     })
  //     .catch(err => console.log(err));
  // };

  render() {
    // const { name } = this.props.currentData || "";
    console.log(this.props);
    return (
      <div>
        <Dialog
          maxWidth="sm"
          onClose={this.props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.props.handleClose}
          >
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={9}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid style={styles.box}>
                  <IconButton
                    aria-label="delete"
                    style={{
                      margin: "8px"
                    }}
                    onClick={this.handleRemove}
                  ></IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={styles.CreateModal}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CreatePersonnel updateTable={this.props.updateTable}  currentData={this.props.currentData}/>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
